import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

import Config from "../config/config";

export default class ApiServices {
  constructor(uri, credential = 'include') {
    const restLink= new HttpLink({ 
      credentials: credential,
      uri: Config.baseUrl + uri
    });

    this.apolloClass = new ApolloClient({
      link: restLink,
      cache: new InMemoryCache(),
    });
  }
}