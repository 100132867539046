import React, { useState, useEffect } from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Config from "../../config/config";
import ParceiroService from "../../services/ParceiroService";
import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

const parceiroService = new ParceiroService();

function BranchItem({ match, history }) {
  const { id } = match.params;

  const [branch, setBranch] = useState({});
  const [branchName, setBranchName] = useState('');
  const [alertInfo, setAlertInfo] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [is_loading_button, setIsLoadingButton] = useState(false);

  useEffect(() => {
    if (id) {
      async function getBranch() {
        try {
          setLoading(true);

          const response = await parceiroService.getBranch({ id });

          setBranch(response.data.branch);
          setBranchName(response.data.branch.value);
        } catch(err) {
          toast.error("Ocorreu um erro ao carregar as informações.");
          setError('Error :(');
        } finally {
          setLoading(false);
        }
      }

      getBranch();
    }
  }, [id]);

  async function handleSubmit(event) {
    setIsLoadingButton(true);
    event.preventDefault();

    if (!branchName.trim()) {
      toast.error('O nome do Ramo de atuação não pode ser vazio.')
      return
    }

    try {
      if (id) {
        await parceiroService.updateBranch({ id, name: branchName });
      }
  
      if (!id) {
        await parceiroService.createBranch({ name: branchName });
      }

      setAlertInfo("Salvo com sucesso!");
  
      setTimeout(() => {
        setAlertInfo('');
        window.location.href = Config.baseUrl + "#/Branch";
        window.scrollTo(0, 0);
      },2000);
      
    } catch (err) {
      toast.error("Ocorreu um erro ao salvar o ramo de atuação.");
      setIsLoadingButton(false);
    }
  }

  return(
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <Link
                to="/Branch"
                className="btn btn-info btn-fill"
              >
                VOLTAR
              </Link>
            </div>

            <Card
              title="Ramos de Atuação"
              name="Ramos de Atuação"
              content={
                <>
                  <div>
                    {error && 
                      <span>{error}</span>
                    }
                  </div>

                  <div>
                    {loading ? (
                      <span>Carregando...</span>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <input 
                          type="text"
                          placeholder="Nome"
                          maxLength="120"
                          className="form-control"
                          required
                          onChange={e => setBranchName(e.target.value)}
                          defaultValue={ id ? branch.value : "" }
                        />

                        <Row>
                          <Col md={10}>
                            <AlertInfo alertInfo={alertInfo} />
                          </Col>
                          <Col md={2}>
                            <Button
                              bsStyle="info"
                              pullRight
                              fill
                              type="submit"
                              disabled={is_loading_button}
                            >
                              Salvar
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </div>
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default BranchItem;