import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import axios from 'axios'
import { toast } from 'react-toastify'

import modeloParceiro from '../../../assets/modelos-import/modelo-importar-usuarios-parceiro.csv'
import modeloCorretor from '../../../assets/modelos-import/modelo-importar-usuarios-corretor.csv'

import Config from '../../../config/config.js'
import { capitalizeString } from '../../../variables/utils.js'

import { Card } from '../../../components/Card/Card.jsx'
import Button from '../../../components/CustomButton/CustomButton.jsx'
import Spinner from '../../../components/Spinner'

import './styles.css'

const CreateBatch = () => {
  const [file, setFile] = useState(null)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [response, setResponse] = useState(null)

  const handleFileChange = event =>
    setFile(event.target.files[0])

  const handleSubmit = (event, espelhamento) => {
    event.preventDefault()
    setIsSubmitLoading(true)
    setResponse(null)

    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      axios
        .post(`${Config.baseUrl}/api/v1/${espelhamento}/import-users-from-dashboard`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(res => {
          setIsSubmitLoading(false)
          setResponse(res.data)
        })
        .catch(err => {
          toast.error(err.response ? err.response.data : 'Ocorreu um erro ao subir usuário.')
          setIsSubmitLoading(false)
        })
    }
  }

  const renderForm = espelhamento =>
    <div>
      <Card
        title={`Modelo - ${capitalizeString(espelhamento)}`}
        content={
          <div>
            Preencha os dados dos usuários de acordo com a tabela modelo:<br/>
            O formato do arquivo deve ser .csv separado por vírgulas ou ponto e vírgula("," ou ";").

            <br/><br/>

            <Button
              type='submit'
              href={
                espelhamento === 'parceiro'
                  ? modeloParceiro
                  : modeloCorretor
              }
              bsStyle='success'
              fill
            >
              Baixar modelo
            </Button>
          </div>
        }
      />

      <Card
        title={`Importar usuários por aquivo - ${capitalizeString(espelhamento)}`}
        content={
          <form onSubmit={event => handleSubmit(event, espelhamento)}>
            <input
              type='file'
              name='file'
              className='user-inputfile'
              onChange={handleFileChange}
              required
            />

            {
              isSubmitLoading
                ? <Spinner />
                : <Button type='submit' bsStyle='info' fill>Upload</Button>
            }
          </form>
        }
      />
    </div>

  const renderResult = () =>
    <div>
      <p>{response.users_created} usuários criados.</p>

      {
        !!response.users_error.length &&
        <div>
          <p>Erro ao inserir {response.users_error.length} usuários:</p>

          {response.users_error.map(user =>
            <div>
              <p>
                {Object.entries(user).map(([key, value]) =>
                  <React.Fragment>
                    <span className={key === 'error' && 'result-error'}>
                      <strong>{key}: </strong>
                      {value}
                    </span>
                    <br />
                  </React.Fragment>
                )}
              </p>

              <hr />
            </div>
          )}
        </div>
      }
    </div>

  return (
    <div className='user-createbatch-container'>
      <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
        <Tab eventKey={1} title="Parceiro">
          {renderForm('parceiro')}
        </Tab>
        <Tab eventKey={2} title="Corretor">
          {renderForm('corretor')}
        </Tab>
      </Tabs>

      {
        response &&
        <Card
          title='Resultado'
          content={renderResult()}
        />
      }
    </div>
  )
}

export default CreateBatch
