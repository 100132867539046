import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import HttpLinkServices from "../../services/HttpLinkServices";
import Button from "../../components/CustomButton/CustomButton.jsx";
import "react-confirm-alert/src/react-confirm-alert.css";
import Config from "../../config/config"; //
import Paginator from "../../components/Paginator";

const course_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl(param, param_1 = null, param_2 = null) {
  let url = "/#/" + param;
  if (param_1 !== null) {
    url += "/" + param_1;
  }
  if (param_2 !== null) {
    url += "/" + param_2;
  }
  return url.replace(/\/\//g, "/"); // Remover barras duplicadas
}

const GET_CURSE = gql`
  query getCourses(
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    courses_collections(
      type: "online"
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      courses {
        id
        title
        scorm
      }
    }
  }
`;

const COUNT_COURSES = gql`
  query countCourses($search: String!) {
    courses_count(search: $search, type: "online") {
      count
    }
  }
`;

const DELETE_CURSE = gql`
  mutation deleteCourse($id: Int!) {
    deleteCourse(id: $id) {
      id
    }
  }
`;

const IDTRAILS_BY_COURSE = gql`
  query trailsByItem($item_id: Int, $type_content: String!, $list: Int) {
    trailsByItem(item_id: $item_id, type_content: $type_content, list: $list) {
      trailitems{
        id
      }
      __typename
    }
  }
`;

export default function CourseList() {
  const limit = 20;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("u.id");
  const [order, setOrder] = useState("DESC");
  const [totalUsers, setTotalUsers] = useState(0);

  const selectHandler = (event) => {
    if (event.target.value != "scorm") {
      window.location.href = event.target.value;
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }
  function countCourses() {
    course_client
      .query({
        query: COUNT_COURSES,
        variables: {
          search: search,
        },
      })
      .then((result) => setTotalUsers(result.data.courses_count.count));
  }

  useEffect(() => {
    countCourses();
  }, [search]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <NavLink
                to={"/Course-new-scorm/"}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
                Novo curso (Com Scorm)
              </NavLink>
              <NavLink
                to={"/Course-new-video/"}
                className="btn btn-success btn-fill"
                activeClassName=""
              >
                Novo Curso (Sem Scorm)
              </NavLink>
              <a
                      className="btn btn-info"
                      href={Config.baseUrl + "/api/v1/lms/report_courses"}
                    >
                      Baixar relatório
                    </a>
                    <a
                      className="btn btn-info"
                      href={Config.baseUrl + "/api/v1/lms/relatorio_cursos"}
                    >
                      Baixar Relatório dos Cursos
                    </a>
            </div>

            <Card
              title={"Cursos ativos"}
              course="Gerenciar cursos ativos"
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th colSpan={9}>
                          <form onSubmit={handleSubmit}>
                            <Row>
                              <Col md={6} lg={3}>
                                <input
                                  type="text"
                                  name="search"
                                  className="form-control"
                                />
                              </Col>
                              <Col md={2}>
                                <Button bsStyle="info" fill type="submit">
                                  Buscar
                                </Button>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} lg={1}>
                                <select name="orderby" className="form-control">
                                  <option value="u.id">ID</option>
                                  <option value="title">Título</option>
                                </select>
                              </Col>
                              <Col md={6} lg={2}>
                                <select name="order" className="form-control">
                                  <option value="DESC">Decrescente</option>
                                  <option value="ASC" selected={order == "ASC"}>
                                    Crescente
                                  </option>
                                </select>
                              </Col>
                              <Col md={2}>
                                <Button bsStyle="info" fill type="submit">
                                  Ordenar
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        </th>
                      </tr>
                    </thead>

                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th style={{ textAlign: "right" }}>Ações</th>
                      </tr>
                    </thead>

                    <tbody>
                      <Query
                        client={course_client}
                        query={GET_CURSE}
                        variables={{
                          search,
                          limit,
                          page,
                          order,
                          orderby,
                        }}
                        fetchPolicy="no-cache"
                      >
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <tr>
                                <td>Carregando...</td>
                              </tr>
                            );
                          if (error)
                            return (
                              <tr>
                                <td>Erro :(</td>
                              </tr>
                            );

                          if (!data.courses_collections) {
                            return (
                              <tr>
                                <td>(Vazio)</td>
                              </tr>
                            );
                          }

                          return data.courses_collections.courses.map(
                            (item) => (
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td className="buttons-list">
                                  <a title="Baixar SCORM" href={item.scorm ? (Config.baseUrl + "/api/v1/lms/baixar_scorm?course_id=" + item.id) : undefined}>
                                    <i className={`pe-7s-cloud-download ${item.scorm ? '' : 'inactive-icon'}`}></i>
                                  </a>
                                  <a title="Alterar SCORM" href={item.scorm ? getUrl("Course-edit-scorm", item.id) : undefined}>
                                    <i className={`pe-7s-refresh-cloud ${item.scorm ? '' : 'inactive-icon'}`}></i>
                                  </a>
                                  <a title="Relatório Programa em Andamento" href={getUrl("Curso-relatorio", item.id)}>
                                    <i className="pe-7s-file"></i>
                                  </a>
                                  <>
                                  <Query
                                    client={course_client} // Utiliza o lms_client para a consulta
                                    variables={{
                                      item_id: item.id,
                                      type_content: "course",
                                      list: 1,
                                    }}
                                    query={IDTRAILS_BY_COURSE}
                                    fetchPolicy="no-cache"
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return (
                                          <a title="Trilhas Vinculadas">
                                            <i className="pe-7s-albums inactive-icon"></i>
                                          </a>
                                        );
                                      if (error)
                                        return (
                                          <a title="Trilhas Vinculadas">
                                            <i className="pe-7s-albums inactive-icon"></i>
                                          </a>
                                        );

                                      const trailItems =
                                        data &&
                                        data.trailsByItem &&
                                        data.trailsByItem.trailitems;

                                      if (
                                        !trailItems ||
                                        trailItems.length === 0
                                      ) {
                                        return (
                                          <a title="Trilhas Vinculadas">
                                            <i className="pe-7s-albums inactive-icon"></i>
                                          </a>
                                        );
                                      }

                                      return (
                                        <a title="Trilhas Vinculadas" href={getUrl(
                                          "course-composition",
                                          "",
                                          item.id
                                        )}>
                                          <i className="pe-7s-albums"></i>
                                        </a>
                                      );
                                    }}
                                  </Query>
                                  </>
                                  <a title="Editar curso" href={getUrl("Course-edit", item.id)}>
                                    <i className="pe-7s-note"></i>
                                  </a>
                                  <a title="Segmentar curso" href={getUrl(
                                      "segmentation",
                                      "Course",
                                      item.id
                                    )}>
                                    <i className="pe-7s-network"></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          );
                        }}
                      </Query>
                    </tbody>
                  </Table>
                </>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalUsers / limit)}
              onChangePage={(page) => setPage(page)}
              siblingCount={2}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
