import React from "react";
import "./style.css";

export default function Spinner() {

  return (
    <div className="sk-chase">
      <div className="sk-chase-dot"/>
      <div className="sk-chase-dot"/>
      <div className="sk-chase-dot"/>
      <div className="sk-chase-dot"/>
      <div className="sk-chase-dot"/>
      <div className="sk-chase-dot"/>
    </div>
  )

}