import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import HttpLinkServices from "../../services/HttpLinkServices";
import Button from "../../components/CustomButton/CustomButton.jsx";
import swal from "sweetalert";
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const proof_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

const GET_PROOFS = gql`query getProofs($type_id: Int!){
    proof_collections(type_id: $type_id){
      proofs{
        id,title
      }
    }
  }
`;

const DELETE_PROOF = gql`
  mutation deleteProof($id: Int!){
    deleteProof(id:$id) {
      id
    }
  }
`;

class ProofList extends Component {


  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={'/Proof-new/'+this.props.match.params.type_id}
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  Nova Avaliação
                </NavLink>
              </div>
              
              <Card
                title={"Avaliações ativas"}
                proof="Gerenciar avaliações ativos"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th style={{ textAlign: "right" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          variables={{type_id: this.props.match.params.type_id}}
                          client={proof_client}
                          query={GET_PROOFS}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.proof_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.proof_collections.proofs.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td className="buttons-list">
                                  <a title="Conteúdos Vinculados" href={getUrl(
                                    "Proof-composition",
                                    item.id
                                  )}>
                                    <i className="pe-7s-albums"></i>
                                  </a>
                                  <a title="Perguntas" href={getUrl('Question/'+this.props.match.params.type_id,item.id)}>
                                    <i className="pe-7s-note2"></i>
                                  </a>
                                  <a title="Editar avaliação" href={getUrl('Proof-edit',this.props.match.params.type_id,item.id)}>
                                    <i className="pe-7s-note"></i>
                                  </a>
                                  <a title="Excluir avaliação" onClick={() => {
                                    swal({
                                      title: `Atenção!`,
                                      text: `Tem certeza que deseja excluir a avaliação "${item.title}"?\n\nNão será possível recuperar o registro após a exclusão`,
                                      icon: "warning",
                                      buttons: ["Cancelar", "Excluir"],
                                      className: "custom-swal",
                                      dangerMode: true,
                                    }).then((confirm) => {
                                      if (confirm) {
                                        proof_client
                                          .mutate({
                                            mutation: DELETE_PROOF,
                                            variables: {
                                              id: item.id,
                                            },
                                          })
                                          .then((response) => {
                                            if (response.data.errors) {
                                              swal(
                                                "Não foi possível excluir o item por existirem registros filhos",
                                                {
                                                  icon: "error",
                                                }
                                              );
                                            } else {
                                              swal(
                                                "Exclusão concluída com sucesso!",
                                                {
                                                  icon: "success",
                                                }
                                              ).then(() => {
                                                window.location.reload();
                                              });
                                            }
                                          })
                                          .catch(() => {
                                            swal("Não foi possível excluir o item por existirem registros filhos", {
                                              icon: "error",
                                            });
                                          });
                                      }
                                    });
                                  }}>
                                    <i className="pe-7s-trash danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ProofList;
