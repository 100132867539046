import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import { StatsCard } from "../../components/StatsCard/StatsCard.jsx";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

const account_client = new ApolloClient({
 link: HttpLinkServices.account,
 cache: new InMemoryCache(),
});

class Home extends Component {

  render() {

    return (
      <div className="content">
        <Grid fluid>
        <Row>
          <Col lg={12} sm={12}>
            
          <Query
              client={account_client}
                query={gql`{
                  get_authenticated_user{
                    id
                    name
                    uid
                  }
                }
                `}
            >
              {({ loading, error, data }) => {
                if (loading) return <div>Olá</div>;
                if (error) return <div>Erro :(</div>;
                  
                return  (
                  <div>
                    <h2>Olá, {data.get_authenticated_user.name}.</h2>
                    <p>Você está online com a matrícula {data.get_authenticated_user.uid}</p>
                  </div>
                );
              }}
            </Query>
                  
            </Col>
            
          </Row>
          
        </Grid>
      </div>
    );
  }
}

export default Home;
