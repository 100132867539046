import ApiServices from "./ApiServices";
import gql from "graphql-tag";

export default class User extends ApiServices {
  constructor(credential = 'include') {
    super('/api/v1/account/', credential);
  }

  async updateUser(variables) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation updateUser(
          $id: Int!
          $name: String!
          $mail: String!
          $uid: String!
          $profile_id: Int!
          $is_blocked: Int!
          $blocked_description: String!
        ) {
          updateUser(
            id: $id
            input: {
              name: $name
              mail: $mail
              uid: $uid
              profile_id: $profile_id
              is_blocked: $is_blocked
              blocked_description: $blocked_description
            }
          ) {
            id
          }
        }
      `,
      variables
    })
  }
}