import React, { useState, useEffect} from 'react'
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";

import { Row, Col } from 'react-bootstrap'
import './styles.css'

import HttpLinkServices from '../../../services/HttpLinkServices'
import Config from '../../../config/config'

import StatsCard from '../../../components/StatsCard/StatsCard'
import Spinner from '../../../components/Spinner'

const TERMO_COMPROMISSO_COUNT = gql`
  query {
    termoCompromissoCount {
      parceiros
      corretores
      colaboradores
    }
  }
`

const TermoCompromisso = () => {
  const [userCount, setUserCount] = useState({})

  const userCategories = [
    'Colaboradores',
    'Corretores',
    'Parceiros',
  ]
  
  useEffect(() => {
    const account_client = new ApolloClient({
      link: HttpLinkServices.account,
      cache: new InMemoryCache(),
    })

    account_client
      .query({ query: TERMO_COMPROMISSO_COUNT })
      .then(response => setUserCount({
        colaboradores: response.data.termoCompromissoCount.colaboradores,
        corretores: response.data.termoCompromissoCount.corretores,
        parceiros: response.data.termoCompromissoCount.parceiros,
      }))
  }, [])

  return (
    <div>
      <h3>Termo de Compromisso e Responsabilidade</h3>

      <Row>
        {userCategories.map(category =>
          <Col
            className='spinner-styler'
            lg={4}
            sm={4}
          >
            <StatsCard
              statsText={category}
              statsValue={userCount[category.toLowerCase()] || <Spinner />}
              statsIconText="Atualizado agora"
            />
          </Col>
        )}
      </Row>

      <a 
        className="btn btn-info"
        href={`${Config.baseUrl}/api/v1/account/relatorio_termo_compromisso`}
      >
        Gerar relatório
      </a>
    </div>
  )
}

export default TermoCompromisso
