import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Button from "../../components/CustomButton/CustomButton.jsx";
import swal from "sweetalert";
import 'react-confirm-alert/src/react-confirm-alert.css'; //
import HttpLinkServices from "../../services/HttpLinkServices";

const cms_client = new ApolloClient({
  link: HttpLinkServices.cms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_DAM = gql`
  query getCms($module_name: String!){
    contents_collections(limit:200,order:"Desc",module_name:$module_name) {
      contents{
        id, title
      }
    }
  }
`;


const DELETE_DAM = gql`
  mutation deleteCms($id: Int!){
    deleteContent(id:$id) {
      id
    }
  }
`;


class CmsList extends Component {


  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={'/Cms-new/'+this.props.match.params.module_name}
                    className="btn btn-success btn-fill"
                    activeClassName=""
                  >
                  Novo 
                </NavLink>  
              </div>
              <Card
                title={(this.props.match.params.module_name == "Testimony" ? "Destaques" : this.props.match.params.module_name )}
                category={`Gerenciar ${(this.props.match.params.module_name == "Testimony" ? "destaques" : this.props.match.params.module_name )}`}
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th style={{ textAlign: "right" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={cms_client}
                          variables={{
                            module_name:this.props.match.params.module_name
                          }}
                          query={GET_DAM}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.contents_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.contents_collections.contents.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td className="buttons-list">
                                  <a title="Editar destaque" href={getUrl('cms-edit',this.props.match.params.module_name,item.id)}>
                                    <i className="pe-7s-note"></i>
                                  </a>
                                  <a title="Segmentar destaque" href={getUrl('segmentation', this.props.match.params.module_name, item.id)}>
                                    <i className="pe-7s-network"></i>
                                  </a>
                                  <a title="Excluir destaque"onClick={() => {
                                    swal({
                                      title: `Atenção!`,
                                      text: `Tem certeza que deseja excluir o destaque "${item.title}"?\n\nNão será possível recuperar o registro após a exclusão`,
                                      icon: "warning",
                                      buttons: ["Cancelar", "Excluir"],
                                      className: "custom-swal",
                                      dangerMode: true,
                                    }).then((confirm) => {
                                      if (confirm) {
                                        cms_client.mutate({
                                          mutation: DELETE_DAM,
                                          variables: {
                                            id: item.id,
                                          },
                                        }).then(() => {
                                          swal("Exclusão concluída com sucesso!", {
                                            icon: "success",
                                          }).then(() => {
                                            window.location.reload();
                                          });
                                        });
                                      }
                                    });
                                  }}>
                                    <i className="pe-7s-trash danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CmsList;
