import ApiServices from "./ApiServices";
import gql from "graphql-tag";

export default class Parceiro extends ApiServices {
  constructor(credential = 'include') {
    super('/api/v1/parceiro/', credential);
  }

  async createOrUpdateRegistration(variables) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation updateCadastro($id: Int!, $name: String!, $email: String!, $cpf: String!, $company: String!, $role: String!, $phone: String!, $branch: Int!, $aprovado: Boolean, $cnpj_cpf_imobiliaria: String, $cod_imobiliaria: String) {
          createOrUpdateCadastro(id: $id, input: {name: $name, email: $email, cpf: $cpf, company: $company, role: $role, phone: $phone, branch: $branch, aprovado: $aprovado, cnpj_cpf_imobiliaria: $cnpj_cpf_imobiliaria, cod_imobiliaria: $cod_imobiliaria}) {
            id
          }
        }
      `,
      variables
    })
  }

  async getBranchs(variables = {}, fetchPolicy = "") {
    return await this.apolloClass.query({
      query: gql`
        query getBranchs(
          $search: String!,
          $limit: Int!,
          $page: Int!,
          $order: String!,
          $orderby: String!
        ) {
          branch_collections(
            search: $search,
            page: $page,
            limit: $limit,
            order: $order,
            orderby: $orderby
          ) {
          branch {
            id,
            value
          }
        }
      }`,
      variables,
      fetchPolicy,
    });
  }

  async getBranch(variables = {}) {
    return await this.apolloClass.query({
      query: gql`
        query getBranch(
          $id: Int!,
        ) {
          branch(
            id: $id,
          ) {
            id,
            value
          }
        }
      `,
      variables,
    });
  }

  async createBranch(variables = {}) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation createOrUpdateBranch($name: String!) {
          createOrUpdateBranch(input: { value: $name }) {
            id,
            value
          }
        }
      `,
      variables,
    });
  }

  async updateBranch(variables = {}) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation createOrUpdateBranch($id: Int!, $name: String!) {
          createOrUpdateBranch(id: $id, input: { value: $name }) {
            id,
            value
          }
        }
      `,
      variables,
    });
  }

  async deleteBranch(id) {
    return await this.apolloClass.mutate({
      mutation: gql`
        mutation deleteBranch($id: Int!) {
          deleteBranch(id: $id) {
              id
          }
        }
      `,
      variables: {
        id,
      }
    })
  }

  async getBranchsCount(variables = {}) {
    return await this.apolloClass.query({
      query: gql`
        query getBranchsCount(
          $search: String!,
        ) {
          branch_count(
            search: $search,
          ) {
          count
        }
      }`,
      variables,
    });
  }
}