import React, { Component } from "react";
import cover from "../../assets/img/cover.jpg";

export class Message extends Component {


  render() {

    return (
      <div className={this.props.is_admin ? "chat_message_admin" : "chat_message_user"}>
        <div className={this.props.response_from ? "chat_message chat_response" : "chat_message"}>
          {this.props.response_from ?
          <div className="chat_answered_question">
            <b>{this.props.response_from.user_name}</b>
            <p>{this.props.response_from.message}</p>
          </div>
          :
          <div></div>
          }
          <div className="chat_answer">
            <div className="chat_thumb"><img src={cover} alt="" /></div>
            <b>{this.props.user_name}</b>
            <p>{this.props.message}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Message;
