import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { toast } from 'react-toastify'
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import LmsService from "../../services/LmsService";
import Config from "../../config/config";
import { UpdateInfo } from "../../components/UpdateInfo/index.jsx";

const lmsService = new LmsService();

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateClassroom(
    $id: Int!
    $title: String!
    $course_id: Int!
    $address: String!
    $externalPage: String!
    $limit_registrations: Int!
    $datetime_start: String!
    $datetime_end: String!
    $datetime_registration_start: String!
    $datetime_registration_end: String!
    $active: Int!
    $date_publish: String!
    $date_unpublish: String!
    $userowner_id: Int!
    $instrutor: Int!
    $is_public: Int!
    $is_specific: Int!
  ) {
    createOrUpdateClassroom(
      id: $id
      input: {
        title: $title
        course_id: $course_id
        address: $address
        externalPage: $externalPage
        limit_registrations: $limit_registrations
        datetime_start: $datetime_start
        datetime_end: $datetime_end
        datetime_registration_start: $datetime_registration_start
        datetime_registration_end: $datetime_registration_end
        active: $active
        date_publish: $date_publish
        date_unpublish: $date_unpublish
        userowner_id: $userowner_id
        instrutor: $instrutor
        is_public: $is_public
        is_specific: $is_specific
      }
    ) {
      id
    }
  }
`;

const GET_CLASSROOM = gql`
  query getClassroom($classroom_id: Int!) {
    classroom(id: $classroom_id) {
      id
      title
      address
      externalPage
      limit_registrations
      datetime_start_format
      datetime_end_format
      datetime_registration_start_format
      datetime_registration_end_format
      active
      date_publish_format
      date_unpublish_format
      userowner_id
      is_public
      is_specific
      course {
        value: id
        label: title
      }
      instrutor {
        value: id
        label: nome
      }
    }
  }
`;

const GET_USER = gql`
  query getUser($id: Int!) {
    user(id: $id) {
      name
    }
  }
`;

const GET_USER_AUTH = gql`
  {
    get_authenticated_user {
      id
      name
    }
  }
`;

class ClassroomItem extends Component {
  classroom_id;
  classroom_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      is_loading: false,
      course_id_error: "",
      address_error: "",
      limit_registrations_error: "",
      datetime_start_error: "",
      datetime_end_error: "",
      datetime_registration_start_error: "",
      datetime_registration_end_error: "",
      date_unpublish_error: "",
      instrutores_error: "",
      alertInfo: "",
      checkboxValue_active: false,
      datePublish: new Date(new Date().setHours(0,0,0,0)),
      dateUnpublish: "",
      dateStart: null,
      dateEnd: null,
      dateRegistrationStart: null,
      dateRegistrationEnd: null,
      checkboxValue_is_public: true,
      checkboxValue_is_specific: false,
      instrutores: [],
    };

    /*
    As variáveis abaixo, começam com start, que para definir o valor
    Inicial dos campos, e elas existem para definir esse valor inicial
    apenas quando a página carrega, pois depois são alteradas para "false"
    */
    this.start_publish_date = true;
    this.start_unpublish_date = true;

    this.start_datetime_start = true;
    this.start_datetime_end = true;

    this.start_datetime_registration_start = true;
    this.start_datetime_registration_end = true;

    this.start_active = true;
    this.start_is_public = true;
    this.start_is_specific = true;
  }

  componentDidMount() {
    lmsService.getInstrutoresList().then(({ data }) =>
      this.setState({
        instrutores: data.instrutor_collection.instrutores.map((instrutor) => ({
          ...instrutor,
          label: instrutor.nome,
          value: instrutor.id,
        })),
      })
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      alertInfo: "Salvo com sucesso!",
    });

    setTimeout(() => {
      this.setState({
        alertInfo: "",
      });
      window.location.href = Config.baseUrl + "#/Classroom";
      window.scrollTo(0, 0);
    }, 2000);
  };

  onChange_datetimeStart = (datetimeStart) => this.setState({ datetimeStart });

  onChange_datetimeEnd = (date) => {
    const { datetimeStart } = this.state;

    if(!datetimeStart && date){
      toast.error("Informe a data de início do curso", {
        autoClose: 5000
      });
      return;
    }
  
    if (date < datetimeStart && date) {
      toast.error("A data de fim do curso não pode ser anterior a data de início", {
        autoClose: 5000
      });
      return;
    }

    this.setState({ datetimeEnd: date });
  }

  onChange_datetimeRegistrationEnd = (date) => {
    const { datetimeRegistrationStart } = this.state;
  
    if(!datetimeRegistrationStart && date){
      toast.error("Informe a data de início das inscrições", {
        autoClose: 5000
      });
      return;
    }

    if (date < datetimeRegistrationStart && date) {
      toast.error("Término das inscrições não pode ser anterior a data de Início das inscrições", {
        autoClose: 5000
      });
      return;
    } 
    this.setState({ datetimeRegistrationEnd: date });
  }
  
  onChange_datetimeRegistrationStart = (datetimeRegistrationStart) =>
    this.setState({ datetimeRegistrationStart });

  onChange_datePublish = (date) => {
    const { dateUnpublish } = this.state;

    if(!date && dateUnpublish){
      this.setState({ dateUnpublish: null });
    }

    this.setState({ datePublish: date });
  }
  
  onChange_dateUnpublish = (date) => {
    const { datePublish } = this.state;

    if(!datePublish && date){
      toast.error('Informe a data de publicação', {
        autoClose: 5000
      });
      this.setState({ dateUnpublish: null });
      return;
    }
    
    if (date < datePublish && date) {
      toast.error('A data para despublicar não pode ser anterior a data de publicação', {
        autoClose: 5000
      });
      this.setState({ dateUnpublish: null });
      return;
    }
    
    this.setState({ dateUnpublish: date });
  }

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active,
    }));
  };

  handleCheckboxChange_is_public = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_is_public: !this.state.checkboxValue_is_public,
      checkboxValue_is_specific: this.state.checkboxValue_is_public,
    }));
  };

  handleCheckboxChange_is_specific = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_is_specific: !this.state.checkboxValue_is_specific,
      checkboxValue_is_public: this.state.checkboxValue_is_specific,
    }));
  };

  handleFormSubmit = async (e, createOrUpdateClassroom) => {
    e.preventDefault();

    if (!this.formValidation(e)) return;

    try {
      this.setState({ is_loading: true });

      await createOrUpdateClassroom({
        variables: {
          id: this.classroom_id,
          course_id: parseInt(e.target.elements.course_id.value),
          title: "-",
          address: e.target.elements.address.value,
          externalPage: e.target.elements.externalPage.value,
          limit_registrations: parseInt(
            e.target.elements.limit_registrations.value
          ),
          datetime_start: e.target.elements.datetime_start.value,
          datetime_end: e.target.elements.datetime_end.value,
          datetime_registration_start:
            e.target.elements.datetime_registration_start.value,
          datetime_registration_end:
            e.target.elements.datetime_registration_end.value,
          active: e.target.elements.active.checked ? 1 : 0,
          date_publish: e.target.elements.date_publish.value,
          date_unpublish: e.target.elements.date_unpublish.value,
          userowner_id: parseInt(e.target.elements.userowner_id.value),
          instrutor: e.target.elements.instrutores.value,
          is_public: e.target.elements.is_public.checked ? 1 : 0,
          is_specific: e.target.elements.is_specific.checked ? 1 : 0,
        },
      });

      this.handleSubmit(e);
    } catch (e) {
      this.setState({ is_loading: false });
      alert("Houve um erro ao enviar os dados para o servidor");
    }
  };

  formValidation = (e) => {
    this.setState({
      course_id_error: "",
      address_error: "",
      limit_registrations_error: "",
      datetime_start_error: "",
      datetime_end_error: "",
      datetime_registration_start_error: "",
      datetime_registration_end_error: "",
      date_publish_error: "",
      date_unpublish_error: "",
      instrutores_error: "",
    });

    if (!e.target.elements.course_id.value) {
      const courseIdInput = e.target.elements.course_id;
      courseIdInput.focus();
      this.setState({ course_id_error: "Selecione um curso" });
    }

    if (!e.target.elements.address.value) {
      const addressInput = e.target.elements.address;
      addressInput.focus();
      this.setState({ address_error: "Informe o endereço" });
    }

    if (!e.target.elements.limit_registrations.value) {
      const limitRegistrationsInput = e.target.elements.limit_registrations;
      limitRegistrationsInput.focus();
      this.setState({
        limit_registrations_error: "Informe o número máximo de pessoas",
      });
    }

    if (!e.target.elements.datetime_start.value) {
      const dateTimeStartInput = e.target.elements.datetime_start;
      dateTimeStartInput.focus();
      this.setState({ datetime_start_error: "Informe a data de início" });
    }

    if (!e.target.elements.datetime_end.value) {
      const dateTimeEndInput = e.target.elements.datetime_end;
      dateTimeEndInput.focus();
      this.setState({ datetime_end_error: "Informe a data final" });
    }

    if (!e.target.elements.datetime_registration_start.value) {
      const dateTimeRegistrationStart =
        e.target.elements.datetime_registration_start;
      dateTimeRegistrationStart.focus();
      this.setState({
        datetime_registration_start_error: "Informe a data de início",
      });
    }

    if (!e.target.elements.datetime_registration_end.value) {
      const dateTimeRegistrationEnd =
        e.target.elements.datetime_registration_end;
      dateTimeRegistrationEnd.focus();
      this.setState({
        datetime_registration_end_error: "Informe a data final",
      });
    }

    if (!e.target.elements.date_publish.value) {
      const datePublishInput = e.target.elements.date_publish;
      datePublishInput.focus();
      this.setState({
        date_publish_error: "Informe a data de publicação",
      });
    }

    if (!e.target.elements.date_unpublish.value) {
      const dateUnpublishInput = e.target.elements.date_unpublish;
      dateUnpublishInput.focus();
      this.setState({
        date_unpublish_error: "Informe a data para despublicar",
      });
    }

    if (!e.target.elements.instrutores.value) {
      const instrutoresInput = e.target.elements.instrutores;
      instrutoresInput.focus();
      this.setState({ instrutores_error: "Informe o instrutor" });
    }

    if (
      !e.target.elements.course_id.value ||
      !e.target.elements.address.value ||
      !e.target.elements.limit_registrations.value ||
      !e.target.elements.datetime_start.value ||
      !e.target.elements.datetime_end.value ||
      !e.target.elements.datetime_registration_start.value ||
      !e.target.elements.datetime_registration_end.value ||
      !e.target.elements.date_unpublish.value ||
      !e.target.elements.instrutores.value
    )
      return false;

    return true;
  };

  render() {
    this.classroom_id = this.props.match.params.id
      ? this.props.match.params.id
      : 0;
    this.parent_id = this.props.match.params.parent_id
      ? this.props.match.params.parent_id
      : 0;
    this.back_button = this.props.match.params.parent_id
      ? "/SubClassroom/" + this.props.match.params.parent_id
      : "/Classroom";
    const { instrutores } = this.state;
    return (
      <Query
        client={lms_client}
        query={GET_CLASSROOM}
        variables={{ classroom_id: this.classroom_id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.classroom_edit = data.classroom;

          if (
            this.start_active &&
            this.classroom_edit &&
            this.classroom_edit.active
          ) {
            this.start_active = false;
            this.setState({
              checkboxValue_active:
                this.classroom_edit.active === 1 ? true : false,
            });
          }

          if (this.start_is_public && this.classroom_edit) {
            this.start_is_public = false;
            this.setState({
              checkboxValue_is_public:
                this.classroom_edit.is_public === 1 ? true : false,
            });
          }

          if (
            this.start_is_specific &&
            this.classroom_edit &&
            this.classroom_edit.is_specific
          ) {
            this.start_is_specific = false;
            this.setState({
              checkboxValue_is_specific:
                this.classroom_edit.is_specific === 1 ? true : false,
            });
          }

          if (
            this.start_publish_date &&
            this.classroom_edit &&
            this.classroom_edit.date_publish_format
          ) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.classroom_edit.date_publish_format),
            });
          }
          if (
            this.start_unpublish_date &&
            this.classroom_edit &&
            this.classroom_edit.date_unpublish_format
          ) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(
                this.classroom_edit.date_unpublish_format
              ),
            });
          }

          if (
            this.start_datetime_start &&
            this.classroom_edit &&
            this.classroom_edit.datetime_start_format
          ) {
            this.start_datetime_start = false;
            this.setState({
              datetimeStart: new Date(
                this.classroom_edit.datetime_start_format
              ),
            });
          }
          if (
            this.start_datetime_end &&
            this.classroom_edit &&
            this.classroom_edit.datetime_end_format
          ) {
            this.start_datetime_end = false;
            this.setState({
              datetimeEnd: new Date(this.classroom_edit.datetime_end_format),
            });
          }

          if (
            this.start_datetime_registration_start &&
            this.classroom_edit &&
            this.classroom_edit.datetime_registration_start_format
          ) {
            this.start_datetime_registration_start = false;
            this.setState({
              datetimeRegistrationStart: new Date(
                this.classroom_edit.datetime_registration_start_format
              ),
            });
          }
          if (
            this.start_datetime_registration_end &&
            this.classroom_edit &&
            this.classroom_edit.datetime_registration_end_format
          ) {
            this.start_datetime_registration_end = false;
            this.setState({
              datetimeRegistrationEnd: new Date(
                this.classroom_edit.datetime_registration_end_format
              ),
            });
          }
          return (
            <Mutation mutation={ADD_TODO} client={lms_client}>
              {(createOrUpdateClassroom, { data }) => (
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div className="btn-top-container">
                          <NavLink
                            to={this.back_button}
                            className="btn btn-info btn-fill"
                            activeClassName=""
                          >
                            VOLTAR
                          </NavLink>

                          <NotificationSystem
                            ref="notificationSystem"
                            style={style}
                          />
                        </div>

                        <Card
                          title="Turma"
                          content={
                            <form
                              onSubmit={(e) =>
                                this.handleFormSubmit(
                                  e,
                                  createOrUpdateClassroom
                                )
                              }
                            >
                              <div>
                                Criador:
                                {this.classroom_edit &&
                                this.classroom_edit.userowner_id ? (
                                  <Query
                                    client={account_client}
                                    variables={{
                                      id: this.classroom_edit.userowner_id,
                                    }}
                                    query={GET_USER}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return (
                                          <tr>
                                            <td>Carregando...</td>
                                          </tr>
                                        );
                                      if (error)
                                        return (
                                          <tr>
                                            <td>Erro :(</td>
                                          </tr>
                                        );

                                      return <div>{data.user.name}</div>;
                                    }}
                                  </Query>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                              <hr />
                              {this.classroom_edit && (
                                <div>ID da Turma: {this.classroom_edit.id}</div>
                              )}
                              <Query
                                client={account_client}
                                query={GET_USER_AUTH}
                              >
                                {({ loading, error, data }) => {
                                  if (loading) return <div>Carregando...</div>;
                                  if (error) return <div>Erro :(</div>;

                                  return (
                                    <div>
                                      <input
                                        id="userowner_id"
                                        name="userowner_id"
                                        type="hidden"
                                        value={
                                          this.classroom_edit
                                            ? this.classroom_edit.userowner_id
                                            : data.get_authenticated_user.id
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              </Query>
                              <hr />

                              <FormGroup>
                                <ControlLabel>Curso presencial</ControlLabel>
                                <div>
                                  <Query
                                    client={lms_client}
                                    query={gql`
                                      {
                                        courses_collections(
                                          type: "presential"
                                        ) {
                                          courses {
                                            value: id
                                            label: title
                                          }
                                        }
                                      }
                                    `}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                      return (
                                        <>
                                          <Select
                                            name="course_id"
                                            options={
                                              data.courses_collections
                                                ? data.courses_collections
                                                    .courses
                                                : []
                                            }
                                            className="basic-select"
                                            classNamePrefix="select"
                                            defaultValue={
                                              this.classroom_edit &&
                                              this.classroom_edit
                                                ? this.classroom_edit.course
                                                : ""
                                            }
                                          />
                                          {this.state.course_id_error && (
                                            <p className="inputError">
                                              {this.state.course_id_error}
                                            </p>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Query>
                                </div>
                              </FormGroup>

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "address",
                                    label: "Endereço",
                                    maxLength: 255,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Endereço",
                                    defaultValue: this.classroom_edit
                                      ? this.classroom_edit.address
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.address_error && (
                                <p className="inputError">
                                  {this.state.address_error}
                                </p>
                              )}
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "externalPage",
                                    label: "Link da sala virtual",
                                    type: "url",
                                    bsClass: "form-control",
                                    oninvalid:
                                      "this.setCustomValidity('Inserir uma URL válida')",
                                    placeholder:
                                      "Ex: https://www.portoseguroeducacao.com.br/",
                                    defaultValue: this.classroom_edit
                                      ? this.classroom_edit.externalPage
                                      : "",
                                  },
                                ]}
                              />

                              <label>Instrutor</label>
                              <Select
                                options={instrutores}
                                className="basic-select"
                                placeholder="Instrutor"
                                classNamePrefix="select"
                                name="instrutores"
                                defaultValue={
                                  this.classroom_edit
                                    ? this.classroom_edit.instrutor
                                    : {}
                                }
                              />
                              {this.state.instrutores_error && (
                                <p className="inputError">
                                  {this.state.instrutores_error}
                                </p>
                              )}
                              <br />

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "limit_registrations",
                                    label: "Número máximo de pessoas",
                                    type: "number",
                                    maxLength: 3,
                                    bsClass: "form-control",
                                    placeholder: "Número máximo de pessoas",
                                    defaultValue: this.classroom_edit
                                      ? this.classroom_edit.limit_registrations
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.limit_registrations_error && (
                                <p className="inputError">
                                  {this.state.limit_registrations_error}
                                </p>
                              )}

                              <hr />
                              <FormGroup>
                                <ControlLabel>Início do curso</ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="datetime_start"
                                    onChange={this.onChange_datetimeStart}
                                    value={this.state.datetimeStart}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>
                              {this.state.datetime_start_error && (
                                <p className="inputError">
                                  {this.state.datetime_start_error}
                                </p>
                              )}

                              <FormGroup>
                                <ControlLabel>Fim do curso</ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="datetime_end"
                                    onChange={this.onChange_datetimeEnd}
                                    value={this.state.datetimeEnd}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>
                              {this.state.datetime_end_error && (
                                <p className="inputError">
                                  {this.state.datetime_end_error}
                                </p>
                              )}

                              <hr />
                              <FormGroup>
                                <ControlLabel>
                                  Início das inscrições
                                </ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="datetime_registration_start"
                                    onChange={
                                      this.onChange_datetimeRegistrationStart
                                    }
                                    value={this.state.datetimeRegistrationStart}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>
                              {this.state.datetime_registration_start_error && (
                                <p className="inputError">
                                  {this.state.datetime_registration_start_error}
                                </p>
                              )}

                              <FormGroup>
                                <ControlLabel>
                                  Término das inscrições
                                </ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="datetime_registration_end"
                                    onChange={
                                      this.onChange_datetimeRegistrationEnd
                                    }
                                    value={this.state.datetimeRegistrationEnd}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>
                              {this.state.datetime_registration_end_error && (
                                <p className="inputError">
                                  {this.state.datetime_registration_end_error}
                                </p>
                              )}

                              <hr />
                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="active"
                                    type="checkbox"
                                    checked={this.state.checkboxValue_active}
                                    onChange={this.handleCheckboxChange}
                                  />{" "}
                                  Ativo
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="is_public"
                                    type="checkbox"
                                    checked={this.state.checkboxValue_is_public}
                                    onChange={
                                      this.handleCheckboxChange_is_public
                                    }
                                  />{" "}
                                  Pública
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="is_specific"
                                    type="checkbox"
                                    checked={
                                      this.state.checkboxValue_is_specific
                                    }
                                    onChange={
                                      this.handleCheckboxChange_is_specific
                                    }
                                  />{" "}
                                  Específica
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>Data de publicação</ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_publish"
                                    onChange={this.onChange_datePublish}
                                    value={this.state.datePublish}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  Data para despublicar
                                </ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_unpublish"
                                    onChange={this.onChange_dateUnpublish}
                                    value={this.state.dateUnpublish}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>
                              {this.state.date_unpublish_error && (
                                <p className="inputError">
                                  {this.state.date_unpublish_error}
                                </p>
                              )}

                              <Row>
                                <Col md={10}>
                                  <AlertInfo
                                    ref="alertSave"
                                    alertInfo={this.state.alertInfo}
                                  />
                                </Col>
                                <Col md={2}>
                                  <Button
                                    bsStyle="info"
                                    pullRight
                                    fill
                                    type="submit"
                                    disabled={this.state.is_loading}
                                  >
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          }
                        />
                      </Col>
                    </Row>
                  </Grid>
                  {(window.location.hash).includes('edit')
                    ? <UpdateInfo content={this.classroom_edit}/>
                    : <></>
                  }
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default ClassroomItem;
