import React, { useEffect, useState } from "react";
import { NavItem, Nav } from "react-bootstrap";
import { FaSignOutAlt } from 'react-icons/fa';

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});


export default function HeaderLinks() {

  const [logoutUrl, setLogoutUrl] = useState(null);
  const [userFirstName, setUserFirstName] = useState('');

  useEffect(() => {
    account_client
      .query({
        query: gql`{
        account_auth{
          logout_url
        }
      }
      `
      })
      .then((result) => setLogoutUrl(result.data.account_auth.logout_url));

    account_client
      .query({
        query: gql`{
             get_authenticated_user {
                  id
                  first_name
             }
          }
          `
      })
      .then((result) => setUserFirstName(result.data.get_authenticated_user.first_name));

  }, []);

  return (
    <div>
      <Nav>
        <NavItem eventKey={1} href="/#/dashboard">
          <i className="fa fa-dashboard" />
          <p className="hidden-lg hidden-md">Dashboard</p>
        </NavItem>
      </Nav>
      <Nav pullRight>

        <NavItem eventKey={1}>
          <span>Olá, {userFirstName}</span>
        </NavItem>
        <NavItem eventKey={2} href={logoutUrl}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FaSignOutAlt size={18} />
            <p style={{ marginBottom: 0, marginLeft: 5 }}>Sair</p>
          </div>
        </NavItem>

      </Nav>
    </div>
  );
}

