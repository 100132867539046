import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import HttpLinkServices from "../../services/HttpLinkServices";
import Button from "../../components/CustomButton/CustomButton.jsx";
import swal from "sweetalert";
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const course_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

const GET_COURSE = gql`
  {
    courses_collections(type:"presential"){
      courses{
        id,title
      }
    }
  }
`;

const DELETE_COURSE = gql`
  mutation deleteCourse($id: Int!){
    deleteCourse(id:$id) {
      id
    }
  }
`;

class CoursePresentialList extends Component {


  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={'/Presential-course-new/'}
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  Novo Curso Presencial
                </NavLink>
              </div>

              <Card
                title={"Cursos Presenciais"}
                course="Gerenciar cursos presenciais"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th style={{ textAlign: "right" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={course_client}
                          query={GET_COURSE}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.courses_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                              return data.courses_collections.courses.map((item) => (
                              
                              <tr key={item.id}>
                                <td className="tablelist-id">{item.id}</td>
                                <td className="tablelist-desc">{item.title}</td>
                                <td className="buttons-list">
                                  <a title="Editar curso" href={getUrl('Presential-course-edit',item.id)}>
                                    <i className="pe-7s-note"></i>
                                  </a>
                                  <a title="Segmentar curso" href={getUrl('segmentation', "Course",item.id)}>
                                    <i className="pe-7s-network"></i>
                                  </a>
                                  <a title="Excluir curso" onClick={() => {
                                    swal({
                                      title: `Atenção!`,
                                      text: `Tem certeza que deseja excluir o curso "${item.title}"?\n\nNão será possível recuperar o registro após a exclusão`,
                                      icon: "warning",
                                      buttons: ["Cancelar", "Excluir"],
                                      className: "custom-swal",
                                      dangerMode: true,
                                    }).then((confirm) => {
                                      if (confirm) {
                                        course_client.mutate({
                                          mutation: DELETE_COURSE,
                                          variables: {
                                            id: item.id,
                                          },
                                        }).then(() => {
                                          swal("Exclusão concluída com sucesso!", {
                                            icon: "success",
                                          }).then(() => {
                                            window.location.reload();
                                          });
                                        });
                                      }
                                    });
                                  }}>
                                    <i className="pe-7s-trash danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CoursePresentialList;
