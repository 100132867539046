import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import { FormGroup, Radio, ControlLabel, Col, Row } from "react-bootstrap";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import axios from "axios";
import { AlertInfo } from "../../../components/Alert/AlertInfo.jsx";
import Config from "../../../config/config.js";
import HttpLinkServices from "../../../services/HttpLinkServices";
import { inputMask } from "../../../variables/utils";
import { FormInputs } from "../../../components/FormInputs/FormInputs.jsx";
import { Card } from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import Spinner from "../../../components/Spinner";
import { validationFields } from "../../../utils/validationFields";

import "./styles.css";

const SUSEP_GRUPOS_OPTIONS = [
  {
    label: "SUSEP",
    value: 340431,
  },
  {
    label: "SUSEP PLENA",
    value: 340432,
  },
];

const PAPEL_OPTIONS = [
  {
    label: "Funcionário",
    value: 1,
  },
  {
    label: "Preposto",
    value: 3,
  },
  {
    label: "Sócio",
    value: 2,
  },
];

const GET_ACCOUNT_FIELD_VALUES = gql`
  query getAccountFieldValues($account_field_id: Int!) {
    get_account_field_values(account_field_id: $account_field_id) {
      accountfieldvalues {
        value: id
        label: value
      }
    }
  }
`;

const CreateOne = () => {
  const [espelhamento, setEspelhamento] = useState(null);
  const [espelhamentoOptions, setEspelhamentoOptions] = useState([]);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState('');

  const createUser = (data) => {

    axios
      .post(
        `${Config.baseUrl}/api/v1/${espelhamento}/create-users-from-dashboard`,
        data,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      )
      .then((res) => {

        if(res.data.users_error.length){

          toast.error(res.data.users_error[0].error);
          setIsSubmitLoading(false);

        } else {

          setAlertInfo('Usuário criado com sucesso.');

          setTimeout(() => {
            setAlertInfo('');
            window.location.href = Config.baseUrl + "#/User";
            window.scrollTo(0, 0);
          },3000);

        }

      })
      .catch(() => {
        toast.error("Ocorreu um erro ao subir usuário.");
        setIsSubmitLoading(false);
      });
  };

  const handleSubmit = (event) => {
    setIsSubmitLoading(true);
    event.preventDefault();

    const data = {
      name: event.target.elements.name.value,
      cpf: event.target.elements.cpf.value.replace(/-|\./g, ""),
      email: event.target.elements.email.value,
      ramo: espelhamento === "parceiro" && event.target.elements.ramo.value,
      papel: espelhamento === "corretor" && event.target.elements.papel.value,
      susep_externalId:
        espelhamento === "corretor" &&
        event.target.elements.susep_externalId.value,
      susep_grupo:
        espelhamento === "corretor" && event.target.elements.susep_grupo.value,
      telefone: event.target.elements.telefone.value,
    };

    try {
      validationFields(data);
      createUser(data);
    } catch (error) {
      toast.error(error.message);
      setIsSubmitLoading(false);
    }
  };

  const handleEspelhamentoChange = (event) =>
    setEspelhamento(event.target.value);

  // renderiza componentes variáveis de acordo com o tipo de usuário selecionado
  const renderEspelhamentoOptions = (espelhamento) => {
    switch (espelhamento) {
      case "parceiro":
        return (
          <FormGroup>
            <ControlLabel>Ramo de atuação<span style={{ color: '#dd0303', fontSize: 'smaller' }}>  ✱</span></ControlLabel>

            {isOptionsLoading ? (
              <div className="spinner-options">
                <Spinner />
              </div>
            ) : (
              <Select name="ramo" options={espelhamentoOptions} />
            )}
          </FormGroup>
        );

      case "corretor":
        return (
          <div>
            <FormGroup>
              <ControlLabel>Papel<span style={{ color: '#dd0303', fontSize: 'smaller' }}>  ✱</span></ControlLabel>
              <Select name="papel" options={PAPEL_OPTIONS} />
            </FormGroup>

            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  name: "susep_externalId",
                  label: "Código Susep",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Código Susep do corretor",
                  isRequired: true,
                  maxLength: 10
                },
              ]}
            />

            <FormGroup>
              <ControlLabel>Grupo Susep<span style={{ color: '#dd0303', fontSize: 'smaller' }}>  ✱</span></ControlLabel>
              <Select name="susep_grupo" options={SUSEP_GRUPOS_OPTIONS} />
            </FormGroup>
          </div>
        );

      default:
        return null;
    }
  };

  // busca os ramos de atuação existentes caso 'parceiro'
  useEffect(() => {
    if (espelhamento === "parceiro") {
      setIsOptionsLoading(true);

      const account_client = new ApolloClient({
        link: HttpLinkServices.account,
        cache: new InMemoryCache(),
      });

      account_client
        .query({
          query: GET_ACCOUNT_FIELD_VALUES,
          variables: { account_field_id: 19 },
        })
        .then((res) => {
          setEspelhamentoOptions(
            res.data.get_account_field_values.accountfieldvalues
          );
          setIsOptionsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsOptionsLoading(false);
        });
    }
  }, [espelhamento]);

  return (
    <div className="user-createone-container">
      <div className="btn-top-container">
        <NavLink to={"/User"} className="btn btn-info btn-fill">
          VOLTAR
        </NavLink>
      </div>

      <Card
        title="Criar usuário"
        content={
          <form onSubmit={handleSubmit}>
            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  name: "name",
                  label: "Nome",
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Nome do usuário",
                  isRequired: true,
                  maxLength: 80
                },
              ]}
            />

            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  id: "cpf",
                  name: "cpf",
                  label: "CPF",
                  type: "text",
                  pattern: ".{14}",
                  maxLength: 14,
                  bsClass: "form-control",
                  placeholder: "CPF do usuário",
                  isRequired: true,
                  onKeyUp: (event) => inputMask(event, "000.000.000-00"),
                },
              ]}
            />

            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  name: "email",
                  label: "E-mail",
                  type: "email",
                  bsClass: "form-control",
                  placeholder: "E-mail do usuário",
                  isRequired: true,
                  maxLength: 80
                },
              ]}
            />

            <FormInputs
              ncols={["col-md-12"]}
              proprieties={[
                {
                  name: "telefone",
                  label: "Telefone",
                  maxLength: 15,
                  type: "text",
                  bsClass: "form-control",
                  placeholder: "Telefone",
                  isRequired: true,
                  defaultValue: "",
                  onKeyUp: (event) => inputMask(event, "(00) 00000-0000"),
                },
              ]}
            />

            <FormGroup onChange={handleEspelhamentoChange}>
              <Radio name="espelhamento" value="corretor" inline required>
                Corretor
              </Radio>
              <Radio name="espelhamento" value="parceiro" inline required>
                Parceiro
              </Radio>
            </FormGroup>

            {renderEspelhamentoOptions(espelhamento)}

            <FormGroup ncols={["col-md-12"]}>
              <Row>
                <Col md={10}>
                  <AlertInfo alertInfo={alertInfo}/>
                </Col>
                <Col md={2}>
                <Button type="submit" bsStyle="info" fill disabled={isSubmitLoading}>
                  Salvar
                </Button>
                </Col>
              </Row>
            </FormGroup>

          </form>
        }
      />
    </div>
  );
};

export default CreateOne;