import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
} from "react-bootstrap";

import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";
import Config from "../../config/config";

class DamUpload extends Component {
  render() {

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <a
                    href='javascript:history.go(-1)'
                    className="btn btn-info btn-fill"
                  >
                  VOLTAR
                </a>
                
                <NotificationSystem ref="notificationSystem" style={style} />
              </div>

              <Card
                title='Upload Arquivo'
                content={
                  <form 
                  action={Config.baseUrl + "/api/v1/dam/upload"} method="post" enctype='multipart/form-data'
                  >
                  <input type="hidden" name="returnUrl" value={"/#/Dam/"+this.props.match.params.type + (this.props.match.params.external_parent_id ? "/"+this.props.match.params.external_parent_id : "")} />
                  <input type="hidden" name="module_name" value={this.props.match.params.type} />
                  <input type="hidden" name="external_parent_id" value={this.props.match.params.external_parent_id ? this.props.match.params.external_parent_id : ""} />
                  
                  {
                    typeof this.props.match.params.id !== 'undefined' &&
                    <input type="hidden" name="asset_id" value={this.props.match.params.id} />
                  }

                  <input
                  type="file"
                  name="file"
                  required
                  />
                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DamUpload;
