import React, { Component } from "react";

export class UpdateInfo extends Component {
  render() {
    const convertDate = (miliSeconds) => {
      const date = `${new Date(miliSeconds).toLocaleDateString()} às ${new Date(miliSeconds).toLocaleTimeString()}`;
      return date;
    };

    return (
      <div className="logInfo">
        <div>
          {this.props.content.created_at ? `Criado em ${convertDate(this.props.content.created_at)}` : ""} {this.props.content.created_by ? `por ${this.props.content.created_by}` : ""}
        </div>
        <div>
          {this.props.content.updated_at ? `Atualizado em ${convertDate(this.props.content.updated_at)}` : ""} {this.props.content.updated_by ? `por ${this.props.content.updated_by}` : ""}
        </div>
      </div>
    );
  }
}
