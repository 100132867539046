import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateAnswer(
    $id: Int!
    $title: String!
    $question_id: Int!
    $is_correct: Int!
  ) {
    createOrUpdateAnswer(
      id: $id
      input: {
        title: $title
        question_id: $question_id
        is_correct: $is_correct
      }
    ) {
      id
    }
  }
`;

const GET_QUESTION = gql`
  query getAnswer($answer_id: Int!) {
    answer(id: $answer_id) {
      title
      is_correct
    }
  }
`;

class AnswerItem extends Component {
  answer_id;
  answer_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      isCorrect: false,
      description: "",
    };

    this.start_is_correct = true;
  }

  componentDidMount() {
    this.fetchAnswer();
  }

  fetchAnswer = () => {
    lms_client
      .query({
        query: GET_QUESTION,
        variables: { answer_id: this.answer_id },
      })
      .then((response) => {
        const answer = response.data.answer;
        if (answer && this.start_is_correct) {
          this.start_is_correct = false;
          this.setState({
            isCorrect: answer.is_correct === 1,
            description: answer.title || "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      alertInfo: "Salvo com sucesso!",
    });

    setTimeout(() => {
      this.setState({
        alertInfo: "",
      });
    }, 3000);
  };

  handleToggleIsCorrect = () => {
    this.setState((prevState) => ({
      isCorrect: !prevState.isCorrect,
    }));
  };

  render() {
    this.answer_id = this.props.match.params.id ? this.props.match.params.id : 0;
    this.question_id = this.props.match.params.parent_id ? this.props.match.params.question_id : "";

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                  to={`/Answer/${this.props.match.params.type_id}/${this.props.match.params.proof_id}/${this.props.match.params.question_id}`}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                  VOLTAR
                </NavLink>
                <NotificationSystem ref="notificationSystem" style={style} />
              </div>

              <Card
                title="Editar resposta"
                content={
                  <Query client={lms_client} query={GET_QUESTION} variables={{ answer_id: this.answer_id }}>
                    {({ loading, error, data }) => {
                      if (loading) return <div>Carregando...</div>;
                      if (error) return <div>Erro :(</div>;

                      this.answer_edit = data.answer;

                      return (
                        <Mutation mutation={ADD_TODO} client={lms_client}>
                          {(createOrUpdateAnswer, { data }) => (
                            <form
                              onSubmit={(e) => {
                                this.handleSubmit(e);
                                e.preventDefault();
                                createOrUpdateAnswer({
                                  variables: {
                                    id: this.answer_id,
                                    question_id: parseInt(this.props.match.params.question_id),
                                    title: e.target.elements.title.value,
                                    is_correct: this.state.isCorrect ? 1 : 0,
                                  },
                                });
                              }}
                            >
                              <Row>
                                <div className="input-answer">
                                  <Col md={10}>
                                    <FormGroup>
                                      <ControlLabel>Descrição da resposta</ControlLabel>
                                      <textarea
                                        className="form-control"
                                        rows="2"
                                        name="title"
                                        placeholder="Descrição da resposta"
                                        value={this.state.description}
                                        onChange={(e) => this.setState({ description: e.target.value })}
                                        maxLength={1024}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={2}>
                                    <FormGroup>
                                      <ControlLabel>
                                        <Button
                                          bsStyle={this.state.isCorrect ? "success" : "default"}
                                          fill
                                          className="btn-fill"
                                          onClick={this.handleToggleIsCorrect}
                                        >
                                          {this.state.isCorrect ? "Resposta correta" : "Resposta incorreta"}
                                        </Button>
                                      </ControlLabel>
                                    </FormGroup>
                                  </Col>
                                </div>
                              </Row>
                              <Row>
                                <Col md={10}>
                                  <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                                </Col>
                                <Col md={2} className="text-right">
                                  <Button bsStyle="info" pullRight fill type="submit" className="btn-fill">
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          )}
                        </Mutation>
                      );
                    }}
                  </Query>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AnswerItem;
