import React, { Component } from "react";
import { FormGroup, ControlLabel, Grid, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";
import CorretorService from "../../services/CorretorService.js";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import { UpdateInfo } from "../../components/UpdateInfo/index.jsx";

const corretor_client = new ApolloClient({
  link: HttpLinkServices.corretor,
  cache: new InMemoryCache(),
});

const GET_CADASTRO = gql`
  query getCadastro($cadastro_id: Int!) {
    cadastro(id: $cadastro_id) {
      name
      email
      cpf
      susep
      grupo
      celular
      role
      created_at 
      created_by 
      updated_at 
      updated_by
    }
  }
`;

const PAPEL_OPTIONS = [
  {
    label: "Funcionário",
    value: "Funcionário",
  },
  {
    label: "Sócio",
    value: "Sócio",
  },
  {
    label: "Preposto",
    value: "Preposto",
  },
];

const GRUPO_SUSEP_OPTIONS = [
  {
    label: "SUSEP",
    value: 340431,
  },
  {
    label: "SUSEP PLENA",
    value: 340432,
  },
];

const corretorService = new CorretorService();

class CadastroItem extends Component {
  cadastro_id;
  cadastro_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      grupoSusep: null,
    };

    this.validationFields = this.validationFields.bind(this);
  }

  capitalize(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  async componentDidMount() {
    try {
      const { data } = await corretor_client.query({
        query: GET_CADASTRO,
        variables: { cadastro_id: this.props.match.params.id },
      });

      this.cadastro_edit = data.cadastro;

      const grupoSusepValue = this.cadastro_edit ? this.cadastro_edit.grupo : null;
      const grupoSusepOption = GRUPO_SUSEP_OPTIONS.find(
        (option) => option.value === grupoSusepValue
      );

      this.setState({ grupoSusep: grupoSusepOption });
    } catch (error) {
      console.error("Erro ao carregar cadastro:", error);
    }
  }

  validationFields(data) {
    for (let key in data) {
      if (data[key] === "") {
        let keyName = key === "name" ? "nome" : key;
        throw new Error(`O campo ${this.capitalize(keyName)} é obrigatório!`);
      }
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    console.log(event.target.elements.papel.value);

    const data = {
      id: this.props.match.params.id,
      name: event.target.elements.name.value,
      email: event.target.elements.email.value,
      cpf: event.target.elements.cpf.value,
      susep: event.target.elements.susep.value,
      telefone: event.target.elements.telefone.value,
      papel: event.target.elements.papel.value,
      grupo: event.target.elements.grupoSusep.value
    };

    try {
      this.validationFields(data);

      await corretorService.createOrUpdateRegistration(data);

      this.setState({
        alertInfo: "Salvo com sucesso!",
      });

      setTimeout(() => {
        this.setState({
          alertInfo: "",
        });
      }, 3000);
    } catch (error) {
      if (!error.message.includes("GraphQL")) {
        toast.error(error.message);
      } else {
        error.graphQLErrors.map((err) => toast.error(err.message));
      }
    }
  };

  render() {
    const { grupoSusep } = this.state;

    return (
      <Query
        client={corretor_client}
        query={GET_CADASTRO}
        variables={{ cadastro_id: this.props.match.params.id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.cadastro_edit = data.cadastro;

          return (
            <div className="content">
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    <div className="btn-top-container">
                      <NavLink
                        to="/Corretor"
                        className="btn btn-info btn-fill"
                        activeClassName=""
                      >
                        VOLTAR
                      </NavLink>
                      <NotificationSystem
                        ref="notificationSystem"
                        style={style}
                      />
                    </div>

                    <Card
                      name="Usuário"
                      content={
                        <form
                          onSubmit={(e) => {
                            this.handleSubmit(e);
                          }}
                        >
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "name",
                                label: "Nome",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Nome",
                                maxLength: 80,
                                isRequired: true,
                                defaultValue: this.cadastro_edit
                                  ? this.cadastro_edit.name
                                  : "",
                              },
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "email",
                                label: "E-mail",
                                type: "email",
                                bsClass: "form-control",
                                placeholder: "E-mail",
                                maxLength: 80,
                                isRequired: true,
                                defaultValue: this.cadastro_edit
                                  ? this.cadastro_edit.email
                                  : "",
                              },
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "cpf",
                                label: "CPF",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "CPF",
                                maxLength: 14,
                                isRequired: true,
                                defaultValue: this.cadastro_edit
                                  ? this.cadastro_edit.cpf
                                  : "",
                              },
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "telefone",
                                id: "telefoneInput",
                                label: "Telefone",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Telefone",
                                maxLength: 15,
                                isRequired: true,
                                defaultValue: this.cadastro_edit
                                  ? this.cadastro_edit.celular
                                  : "",
                              },
                            ]}
                          />
                          <FormGroup>
                            <ControlLabel>Papel<span style={{ color: '#dd0303', fontSize: 'smaller' }}>  ✱</span></ControlLabel>
                            <Select
                              name="papel"
                              options={PAPEL_OPTIONS}
                              defaultValue={this.cadastro_edit ? { label: this.cadastro_edit.role, value: this.cadastro_edit.role } : null}
                            />
                          </FormGroup>
                          <FormGroup>
                            <ControlLabel>Grupo Susep<span style={{ color: '#dd0303', fontSize: 'smaller' }}>  ✱</span></ControlLabel>
                            <Select
                              name="grupoSusep"
                              options={GRUPO_SUSEP_OPTIONS}
                              value={grupoSusep} 
                              required
                              onChange={(selectedOption) => {
                                this.setState({ grupoSusep: selectedOption });
                              }}
                            />
                          </FormGroup>
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "susep",
                                label: "Susep",
                                maxLength: 10,
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Susep",
                                isRequired: true,
                                defaultValue: this.cadastro_edit
                                  ? this.cadastro_edit.susep
                                  : "",
                              },
                            ]}
                          />
                          <Row>
                            <Col md={10}>
                              <AlertInfo
                                ref="alertSave"
                                alertInfo={this.state.alertInfo}
                              />
                            </Col>
                            <Col md={2}>
                              <Button
                                bsStyle="info"
                                pullRight
                                fill
                                type="submit"
                              >
                                Salvar
                              </Button>
                            </Col>
                          </Row>
                          <div className="clearfix" />
                        </form>
                      }
                    />
                  </Col>
                </Row>
                {(window.location.hash).includes('edit')
                  ? <UpdateInfo content={this.cadastro_edit}/>
                  : <></>
                }
              </Grid>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default CadastroItem;
