import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateAnswer(
    $id: Int!
    $title: String!
    $question_id: Int!
    $is_correct: Int!
  ) {
    createOrUpdateAnswer(
      id: $id
      input: {
        title: $title
        question_id: $question_id
        is_correct: $is_correct
      }
    ) {
      id
    }
  }
`;

const GET_QUESTION = gql`
  query getAnswer($answer_id: Int!) {
    answer(id: $answer_id) {
      title
      is_correct
    }
  }
`;

class AnswerNew extends Component {
  answer_id;
  answer_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      additionalInputs: [
        { id: 0, title: "", is_correct: false },
      ],
      isSaving: false, 
    };

    this.start_is_correct = true;
    this.toastId = null;
  }

  handleSubmit = async (event, createOrUpdateAnswer) => {
    event.preventDefault();
  
    const { additionalInputs } = this.state;
  
    const isAnyInputEmpty = additionalInputs.some(
      (input) => input.title === ""
    );
  
    if (isAnyInputEmpty) {
      if (this.toastId) {
        toast.dismiss(this.toastId);
      }
      this.toastId = toast.error("Por favor, preencha todos os campos.");
      return;
    }
  
    this.setState({
      isSaving: true,
      alertInfo: "",
    });
  
    try {
      for (let i = 0; i < additionalInputs.length; i++) {
        const input = additionalInputs[i];
  
        await createOrUpdateAnswer({
          variables: {
            id: 0,
            question_id: parseInt(this.props.match.params.question_id),
            title: input.title,
            is_correct: input.is_correct ? 1 : 0,
          },
        });
      }
  
      this.setState({
        isSaving: false,
        alertInfo: "Salvo com sucesso!",
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isSaving: false,
        alertInfo: "Erro ao salvar os inputs.",
      });
    }
  };
  

  handleShowAdditionalInput = () => {
    const { additionalInputs } = this.state;
    const newInputId = additionalInputs.length > 0 ? additionalInputs[additionalInputs.length - 1].id + 1 : 0;
    this.setState((prevState) => ({
      additionalInputs: [
        ...prevState.additionalInputs,
        { id: newInputId, title: "", is_correct: false },
      ],
    }));
  };  

  handleAdditionalInputChange = (id, field, e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const additionalInputs = prevState.additionalInputs.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            [field]: value,
          };
        }
        return input;
      });
      return { additionalInputs };
    });
  };

  handleAdditionalButtonToggle = (id) => {
    this.setState((prevState) => {
      const additionalInputs = prevState.additionalInputs.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            is_correct: !input.is_correct,
          };
        }
        return input;
      });
      return { additionalInputs };
    });
  };

  handleRemoveAdditionalInput = (id) => {
    this.setState((prevState) => {
      const additionalInputs = prevState.additionalInputs.filter(
        (input) => input.id !== id
      );
      return { additionalInputs };
    });
  };

  render() {
    this.answer_id = this.props.match.params.id
      ? this.props.match.params.id
      : 0;
    this.question_id = this.props.match.params.parent_id
      ? this.props.match.params.question_id
      : "";

    return (
      <Query
        client={lms_client}
        query={GET_QUESTION}
        variables={{ answer_id: this.answer_id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.answer_edit = data.answer;

          if (
            this.start_is_correct &&
            this.answer_edit &&
            this.answer_edit.is_correct
          ) {
            this.start_is_correct = false;
            this.setState({
              additionalInputs: [
                ...this.state.additionalInputs,
                {
                  id: 1,
                  title: this.answer_edit.title,
                  is_correct: true,
                },
              ],
            });
          }

          return (
            <Mutation mutation={ADD_TODO} client={lms_client}>
              {(createOrUpdateAnswer, { data }) => (
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div className="btn-top-container">
                          <NavLink
                            to={`/Answer/${this.props.match.params.type_id}/${this.props.match.params.proof_id}/${this.props.match.params.question_id}`}
                            className="btn btn-info btn-fill"
                            activeClassName=""
                          >
                            VOLTAR
                          </NavLink>
                        </div>

                        <Card
                          title="Nova resposta"
                          content={
                            <form
                              onSubmit={(e) =>
                                this.handleSubmit(e, createOrUpdateAnswer)
                              }
                            >
                              {this.state.additionalInputs.map((input) => (
                                <div key={input.id}>
                                  <Row>
                                    <div className="input-answer">
                                      <Col md={9}>
                                        <FormGroup>
                                          <ControlLabel>
                                            Descrição da resposta
                                          </ControlLabel>
                                          <textarea
                                            className="form-control"
                                            placeholder="Descrição da resposta"
                                            value={input.title}
                                            onChange={(e) =>
                                              this.handleAdditionalInputChange(
                                                input.id,
                                                "title",
                                                e
                                              )
                                            }
                                            rows={2}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md={2}>
                                        <FormGroup>
                                          <ControlLabel>
                                            <Button
                                              bsStyle={
                                                input.is_correct
                                                  ? "success"
                                                  : "default"
                                              }
                                              fill
                                              onClick={() =>
                                                this.handleAdditionalButtonToggle(
                                                  input.id
                                                )
                                              }
                                            >
                                              {input.is_correct
                                                ? "Resposta correta"
                                                : "Resposta incorreta"}
                                            </Button>
                                          </ControlLabel>
                                        </FormGroup>
                                      </Col>
                                      <Col md={1}>
                                        <FormGroup>
                                          <ControlLabel>
                                            <Button
                                              bsStyle="danger"
                                              fill
                                              onClick={() =>
                                                this.handleRemoveAdditionalInput(
                                                  input.id
                                                )
                                              }
                                            >
                                              X
                                            </Button>
                                          </ControlLabel>
                                        </FormGroup>
                                      </Col>
                                    </div>
                                  </Row>
                                  {input.id !==
                                    this.state.additionalInputs.length && (
                                    <hr />
                                  )}
                                </div>
                              ))}
                              <Button
                                  bsStyle="info"
                                  onClick={this.handleShowAdditionalInput}
                                  style={{ marginBottom: "10px" }}
                                >
                                  Nova resposta
                                </Button>
                              <Row>
                                <Col md={10}>
                                  <AlertInfo
                                    ref="alertSave"
                                    alertInfo={this.state.alertInfo}
                                  />
                                </Col>
                                <Col md={2}>
                                  <Button
                                    bsStyle="info"
                                    pullRight
                                    fill
                                    type="submit"
                                    disabled={this.state.isSaving}
                                  >
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          }
                        />
                      </Col>
                    </Row>
                  </Grid>
                  <NotificationSystem
                    ref={(ref) => (this.notificationSystem = ref)}
                    style={style}
                  />
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default AnswerNew;
