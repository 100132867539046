import * as SambaReports from "../variables/SambaVideos";

const getPortoDataFromSambaVideos = async () => {
  /**
   * @const JSONtoCSV: Aqui será guardada todos os vídeos referentes ao PortoEduc.
   */
  const JSONtoCSV = [];

  /**
   * @var request: Quando o length do array for vazio, a variável
   * será modificada para false, realizando o break da request.
   */
  let request = true;

  /**
   * @var page: Conforme as requests são realizadas, a página
   * é modificada.
   */
  let page = 0;

  /**
   * Antes de iniciar o bloco, é iniciada uma request
   * na API do SambaVídeos, buscando todas as Categorias
   * e Subcategorias, e elas são organizadas em um objeto
   * para acesso.
   */

  await SambaReports.requestCategories();
  
  /**
   * Enquanto a variável de requisição for true, ele executa o bloco.
   */

  while (request) {
    /**
     * @const data: É realizado um destructuring da prop de dentro da request.
     */

    const { data } = await SambaReports.requestSambaVideos(50, page);

    /**
     * Se não houver mais dados, é modificado o comportamento da variável,
     * saindo do loop.
     */

    if (!data.length) {
      request = false;
      /**
       * Após popular o array, ele será retornado com todos os dados.
       */
      return JSONtoCSV;
    }

    /**
     * Se ainda existir dados da requisição,
     * É modificada a paginação e inserido os dados dentro do array.
     */
    if (data.length) {
      page += 1;

      /**
       * Para cada mídia no array da request,
       * é realizado um tratamento para buscar as
       * informações referentes a cada um. Elas são
       * tratadas e enviadas a um array.
       */
      data.forEach(async ({ id, title, files, categoryId, status, postdate }) => {
        const { reportCategoryString } = SambaReports;
        const videoInfo = {
          title,
          category: categoryId ? reportCategoryString(categoryId) : 'Não possui',
          status,
          postdate: new Date(postdate).toISOString().slice(0,10),
          timePerSecond: `${files[0].fileInfo.duration / 1000}`,
          mediaId: id,
        };

        JSONtoCSV.push(videoInfo);
      });
    }
  }
};

export default getPortoDataFromSambaVideos;
