import Axios from "axios";
import { APISVdata } from "../variables/SambaVideos";

const token = `access_token=${APISVdata.tokenSV}`;
const pid = `&pid=${APISVdata.pid}`;

export const requestVideosSearch = async (param) => {
  const uri = `${APISVdata.url}${token}${pid}&published=true&search=${param}`;

  return Axios.get(uri);
};
