import React, { useState } from "react";
import getPortoDataFromSambaVideos from "../../services/ReportSambaVideos";
import { CSVLink } from "react-csv";
import { JSONHeaders } from "../../variables/SambaVideos";
import ReactLoading from "react-loading";

const SambaVideosReport = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [JSONData, setJSONData] = useState([]);
  const [sendCSV, setSendCSV] = useState(false);

  const generateReport = async () => {
    setOnRequest(true);
    const value = await getPortoDataFromSambaVideos();
    setJSONData(value);
    setSendCSV(true);
    setOnRequest(false);
  };

  return (
    <>
      {!onRequest && !JSONData.length && (
        <button className="btn btn-info" onClick={() => generateReport()}>
          Gerar Relatório
        </button>
      )}
      {onRequest && <ReactLoading type="cylon" color="#1DC7EA" />}
      {sendCSV && (
        <CSVLink
          className="btn btn-info"
          data={JSONData}
          headers={JSONHeaders}
          separator={";"}
          filename={"SambaVideosReport.csv"}
        >
          Baixar Relatório
        </CSVLink>
      )}
    </>
  );
};

export default SambaVideosReport;
