import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Select from "react-select";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";
import HttpLinkServices from "../../services/HttpLinkServices";
import ParceiroService from "../../services/ParceiroService";
import { validationFields } from "../../utils/validationFields.js";
import { toast } from "react-toastify";

import { UpdateInfo } from "../../components/UpdateInfo/index.jsx";

const parceiro_client = new ApolloClient({
  link: HttpLinkServices.parceiro,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation updateCadastro(
    $id: Int!
    $name: String!
    $email: String!
    $cpf: String!
    $company: String!
    $role: String!
    $phone: String!
    $branch: Int!
    $aprovado: Boolean
    $cnpj_cpf_imobiliaria: String
    $cod_imobiliaria: String
  ) {
    createOrUpdateCadastro(
      id: $id
      input: {
        name: $name
        email: $email
        cpf: $cpf
        company: $company
        role: $role
        phone: $phone
        branch: $branch
        aprovado: $aprovado
        cnpj_cpf_imobiliaria: $cnpj_cpf_imobiliaria
        cod_imobiliaria: $cod_imobiliaria
      }
    ) {
      id
      status
    }
  }
`;

const GET_CADASTRO = gql`
  query getCadastro($cadastro_id: Int!) {
    cadastro(id: $cadastro_id) {
      name
      cpf
      email
      company
      role
      phone
      created_at 
      created_by 
      updated_at 
      updated_by
      branch {
        id
        value
      }
      status
      cnpj_cpf_imobiliaria
      cod_imobiliaria
    }
  }
`;

const VERIFICA_BASE_IMOBILIARIA = gql`
  query verificaBaseImobiliaria($identificador: String!) {
    verifica_base_imobiliaria(identificador: $identificador)
  }
`;

// Ramo da imobiliária
const accountFieldValueImobiliaria = 306849;

const parceiroService = new ParceiroService();

class CadastroItem extends Component {
  cadastro_id;
  cadastro_edit;
  parent_id;
  constructor() {
    super();
    this.state = {
      errorType: 0,
      alertInfo: "",
      alertColor: "info",
      branchId: "",
      infoParceiro: {},
    };
  }

  handleSubmit = async (event, data) => {
    event.preventDefault();
    const numDocImob = data.variables.cnpj_cpf_imobiliaria;
    const codImobiliaria = data.variables.cod_imobiliaria;
    const ramo = data.variables.branch;

    if (ramo == accountFieldValueImobiliaria) {
      if (!numDocImob && !codImobiliaria) {
        return this.setState({
          alertInfo:
            "Ao menos um dos 2 campos precisa ser preenchido. (CNPJ/CPF ou Código da imobiliária).",
          alertColor: "orange",
        });
      }

      if (numDocImob && numDocImob.length < 11) {
        return this.setState({
          alertInfo: "Preencha o campo com um CPF ou CNPJ válido",
          alertColor: "orange",
        });
      }

      await parceiro_client
        .query({
          query: VERIFICA_BASE_IMOBILIARIA,
          variables: { identificador: numDocImob || codImobiliaria },
        })
        .then((result) => {
          // Retorna null somente quando a base não estiver disponível no ftp da Porto
          if (result.data.verifica_base_imobiliaria === null) {
            return this.setState({ errorType: 1 });
          }
          this.setState({
            infoParceiro: result.data.verifica_base_imobiliaria,
          });
        })
        .catch(() => {
          // Retorno caso haja algum erro comum na nossa validação
          return this.setState({ errorType: 2 });
        });
    }

    switch (this.state.errorType) {
      case 1:
        this.setState({
          alertInfo: "Houve um erro na validação da base Imobiliária.",
          alertColor: "red",
        });
        break;
      case 2:
        this.setState({
          alertInfo:
            "Houve um erro interno. Por favor, tente novamente mais tarde",
          alertColor: "red",
        });
        break;
      default:
        if (ramo == accountFieldValueImobiliaria && !this.state.infoParceiro) {
          this.setState({
            alertInfo:
              "Cadastro não encontrado. Por favor entre em contato com seu parceiro.",
            alertColor: "red",
          });
        } else {
          try {
            validationFields({
              name: data.variables.name,
              email: data.variables.email,
              cpf: data.variables.cpf,
              company: data.variables.company,
              role: data.variables.role,
              phone: data.variables.phone,
            });

            await parceiroService.createOrUpdateRegistration(data.variables);

            this.setState({
              alertInfo: "Salvo com sucesso!",
              alertColor: "",
            });
          } catch (error) {
            if (!error.message.includes("GraphQL")) {
              toast.error(error.message);
            } else {
              error.graphQLErrors.map((err) => toast.error(err.message));
            }
          }
        }
        break;
    }

    setTimeout(() => {
      this.setState({
        alertInfo: "",
      });
    }, 5000);
  };

  render() {
    return (
      <Query
        client={parceiro_client}
        query={GET_CADASTRO}
        variables={{ cadastro_id: this.props.match.params.id }}
        fetchPolicy={"no-cache"}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.cadastro_edit = data.cadastro;
          this.cadastro_edit.status =
            this.cadastro_edit.status === true
              ? { value: true, label: "Aprovado" }
              : this.cadastro_edit.status === false
              ? { value: false, label: "Reprovado" }
              : { value: "", label: "Não definido" };
          return (
            <Mutation mutation={ADD_TODO} client={parceiro_client}>
              {(createOrUpdateCadastro, { data }) => (
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div className="btn-top-container">
                          <NavLink
                            to="/Parceiro"
                            className="btn btn-info btn-fill"
                            activeClassName=""
                          >
                            VOLTAR
                          </NavLink>

                          <NotificationSystem
                            ref="notificationSystem"
                            style={style}
                          />
                        </div>

                        <Card
                          name="Usuário"
                          content={
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();

                                const data = {
                                  variables: {
                                    id: this.props.match.params.id,
                                    name: e.target.elements.name.value,
                                    email: e.target.elements.email.value,
                                    cpf: e.target.elements.cpf.value,
                                    company: e.target.elements.company.value,
                                    role: e.target.elements.role.value,
                                    phone: e.target.elements.phone.value,
                                    branch: e.target.elements.branch.value,
                                    aprovado: e.target.elements.status
                                      ? JSON.parse(
                                          e.target.elements.status.value
                                        )
                                      : null,
                                    cnpj_cpf_imobiliaria: e.target.elements
                                      .numDocImob
                                      ? e.target.elements.numDocImob.value.replace(
                                          /[^0-9]/g,
                                          ""
                                        )
                                      : null,
                                    cod_imobiliaria: e.target.elements
                                      .cod_imobiliaria
                                      ? e.target.elements.cod_imobiliaria.value
                                      : null,
                                  },
                                };

                                this.handleSubmit(e, data);
                              }}
                            >
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "name",
                                    label: "Nome",
                                    maxLength: 80,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Nome",
                                    isRequired: true,
                                    defaultValue: this.cadastro_edit
                                      ? this.cadastro_edit.name
                                      : "",
                                  },
                                ]}
                              />
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "email",
                                    label: "E-mail",
                                    type: "email",
                                    maxLength: 80,
                                    bsClass: "form-control",
                                    placeholder: "E-mail",
                                    isRequired: true,
                                    defaultValue: this.cadastro_edit
                                      ? this.cadastro_edit.email
                                      : "",
                                  },
                                ]}
                              />
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "cpf",
                                    label: "CPF",
                                    type: "text",
                                    maxLength: 14,
                                    bsClass: "form-control",
                                    placeholder: "CPF",
                                    isRequired: true,
                                    defaultValue: this.cadastro_edit
                                      ? this.cadastro_edit.cpf
                                      : "",
                                  },
                                ]}
                              />
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "phone",
                                    label: "Telefone",
                                    maxLength: 14,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Telefone",
                                    isRequired: true,
                                    defaultValue: this.cadastro_edit
                                      ? this.cadastro_edit.phone
                                      : "",
                                  },
                                ]}
                              />

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "company",
                                    label: "Empresa",
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Empresa",
                                    maxLength: 40,
                                    isRequired: true,
                                    defaultValue: this.cadastro_edit
                                      ? this.cadastro_edit.company
                                      : null,
                                  },
                                ]}
                              />

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "role",
                                    label: "Cargo:",
                                    type: "text",
                                    maxLength: 40,
                                    bsClass: "form-control",
                                    placeholder: "Cargo:",
                                    isRequired: true,
                                    defaultValue: this.cadastro_edit
                                      ? this.cadastro_edit.role
                                      : "",
                                  },
                                ]}
                              />

                              <Query
                                client={account_client}
                                variables={{
                                  account_field_id: 19,
                                }}
                                query={gql`
                                  query getAccountFieldValues(
                                    $account_field_id: Int!
                                  ) {
                                    get_account_field_values(
                                      account_field_id: $account_field_id
                                    ) {
                                      accountfieldvalues {
                                        value: id
                                        label: value
                                      }
                                    }
                                  }
                                `}
                              >
                                {({ loading, error, data }) => {
                                  if (loading)
                                    return (
                                      <tr>
                                        <td>Carregando...</td>
                                      </tr>
                                    );
                                  if (error)
                                    return (
                                      <tr>
                                        <td>Erro :(</td>
                                      </tr>
                                    );
                                  const account_field_values =
                                    data.get_account_field_values
                                      .accountfieldvalues;
                                  const firstValue = account_field_values.find(
                                    (value) => {
                                      return this.cadastro_edit.branch
                                        ? this.cadastro_edit.branch.id ===
                                            value.value
                                        : this.cadastro_edit.branch;
                                    }
                                  );

                                  // remove campos vazios das options
                                  const filtered_acf_values =
                                    account_field_values.filter(
                                      (value) => value.label !== ""
                                    );

                                  return (
                                    <>
                                      <label className="control-label">
                                        Ramo de Atuação:
                                      </label>
                                      <Select
                                        defaultValue={
                                          firstValue ? firstValue : ""
                                        }
                                        name="branch"
                                        onChange={(value) =>
                                          this.setState({
                                            branchId: value.value,
                                          })
                                        }
                                        options={filtered_acf_values}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                      />
                                      {(this.state.branchId === 298188 ||
                                        (this.state.branchId === "" &&
                                          firstValue &&
                                          firstValue.value === 298188)) && (
                                        <>
                                          <label
                                            style={{ marginTop: 15 }}
                                            className="control-label"
                                          >
                                            Status:
                                          </label>
                                          <Select
                                            defaultValue={
                                              this.cadastro_edit.status
                                            }
                                            name="status"
                                            options={[
                                              {
                                                value: true,
                                                label: "Aprovado",
                                              },
                                              {
                                                value: false,
                                                label: "Reprovado",
                                              },
                                              {
                                                value: "",
                                                label: "Não definido",
                                              },
                                            ]}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                          />
                                        </>
                                      )}
                                      {
                                        // se ramo imobiliaria
                                        (this.state.branchId ===
                                          accountFieldValueImobiliaria ||
                                          (this.state.branchId === "" &&
                                            firstValue &&
                                            firstValue.value ===
                                              accountFieldValueImobiliaria)) && (
                                          <>
                                            <FormInputs
                                              ncols={["col-lg-12"]}
                                              style={{ marginTop: 15 }}
                                              proprieties={[
                                                {
                                                  name: "numDocImob",
                                                  label: "CNPJ ou CPF",
                                                  type: "text",
                                                  maxLength: 14,
                                                  bsClass: "form-control mt-2",
                                                  placeholder: "CNPJ ou CPF",
                                                  ref: "numDocImob",
                                                  defaultValue: this
                                                    .cadastro_edit
                                                    ? this.cadastro_edit
                                                        .cnpj_cpf_imobiliaria
                                                    : "",
                                                },
                                              ]}
                                            />
                                            <FormInputs
                                              required={
                                                this.cadastro_edit
                                                  .cnpj_cpf_imobiliaria
                                                  ? "required"
                                                  : ""
                                              }
                                              ncols={["col-lg-12"]}
                                              proprieties={[
                                                {
                                                  name: "cod_imobiliaria",
                                                  label:
                                                    "Código da imobiliária",
                                                  type: "number",
                                                  bsClass: "form-control",
                                                  maxLength: 20,
                                                  placeholder:
                                                    "Código da imobiliária",
                                                  defaultValue: this
                                                    .cadastro_edit
                                                    ? this.cadastro_edit
                                                        .cod_imobiliaria
                                                    : "",
                                                },
                                              ]}
                                            />
                                          </>
                                        )
                                      }
                                    </>
                                  );
                                }}
                              </Query>
                              <Row>
                                <Col md={10}>
                                  <AlertInfo
                                    style={{
                                      backgroundColor: this.state.alertColor,
                                    }}
                                    ref="alertSave"
                                    alertInfo={this.state.alertInfo}
                                  />
                                </Col>
                                <Col md={2}>
                                  <Button
                                    style={{ marginTop: 20 }}
                                    bsStyle="info"
                                    pullRight
                                    fill
                                    type="submit"
                                  >
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          }
                        />
                      </Col>
                    </Row>
                    {(window.location.hash).includes('edit')
                      ? <UpdateInfo content={this.cadastro_edit}/>
                      : <></>
                    }
                  </Grid>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default CadastroItem;