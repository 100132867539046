  // considera como válidos os caracteres presentes no regex
  // pattern é uma string do tipo '(00) 0000-0000' ou '000.000.000-00'
  export const inputMask = (event, pattern) => {
    const getNumericDigits = (input) => {
      return input.replace(/\D/g, "");
    };
  
    const numericDigits = getNumericDigits(event.target.value);
    const numericChars = numericDigits.split("");
  
    let masked = pattern
      .split("")
      .map((char) => {
        if (/\d/.test(char)) {
          return numericChars.length ? numericChars.shift() : "";
        }
        return char;
      })
      .join("");
  
    if (numericDigits.length > 8) {
      masked = masked.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
  
    event.target.value = masked;
  };
  
  

  // primeira maiuscula (teste => Teste)
  export const capitalizeString = string =>
    string.charAt(0).toUpperCase() + string.slice(1)