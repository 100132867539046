import React, { useState, useEffect } from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import LmsService from "../../services/LmsService";
import { inputMask } from '../../variables/utils';
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Config from "../../config/config";

const lmsService = new LmsService();

function InstrutorItem({ match, history }) {
  const { id } = match.params;
  const [alertInfo, setAlertInfo] = useState('');
  const [instrutor, setInstrutor] = useState({});
  const [instrutorName, setInstrutorName] = useState('');
  const [instrutorCPF, setInstrutorCPF] = useState('');
  const [localizacaoInstrutor, setLocalizacaoInstrutor] = useState('');
  const [is_loading_button, setIsLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const options = [
    {
      value: "",
      label: "Selecione"
    },
    {
      value: "1",
      label: "Interno"
    },
    {
      value: "0",
      label: "Externo"
    }
  ];

  useEffect(() => {
    if (id) {
      async function getInstrutor() {
        try {
          setLoading(true);

          const response = await lmsService.getInstrutor({ id });

          const localizacao = response.data.instrutor.is_interno ? "1" : "0";
          setInstrutor(response.data.instrutor);
          setInstrutorName(response.data.instrutor.nome);
          setInstrutorCPF(response.data.instrutor.cpf);
          setLocalizacaoInstrutor(localizacao);
        } catch(err) {
          toast.error("Ocorreu um erro ao carregar as informações.");
          setError('Erro :(');
        } finally {
          setLoading(false);
        }
      }

      getInstrutor();
    }
  }, [id]);

  async function handleSubmit(event) {
    setIsLoadingButton(true);
    event.preventDefault();
    const localizacao = localizacaoInstrutor == "1";

    if (!instrutorName.trim()) {
      toast.error('O nome do Instrutor não pode ser vazio.')
      return
    }

    try {
      if (id) {
        await lmsService.updateInstrutor({ id, nome: instrutorName, cpf: instrutorCPF, is_interno: localizacao });
      }

      if (!id) {
        await lmsService.createInstrutor({ nome: instrutorName, cpf: instrutorCPF, is_interno: localizacao });
      }

      setAlertInfo("Salvo com sucesso!");
  
      setTimeout(() => {
        setAlertInfo('');
        window.location.href = Config.baseUrl + "#/Instrutor";
        window.scrollTo(0, 0);
      },2000);

    } catch (err) {
      toast.error("Ocorreu um erro ao salvar o instrutor.");
    }
  }
  function selectChange(e) {
    setLocalizacaoInstrutor(e)
  }

  return(
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <Link
                to="/Instrutor"
                className="btn btn-info btn-fill"
              >
                VOLTAR
              </Link>
            </div>

            <Card
              title="Instrutor"
              name="Instrutor"
              content={
                <>
                  <div>
                    {error &&
                      <span>{error}</span>
                    }
                  </div>

                  <div>
                    {loading ? (
                      <span>Carregando...</span>
                    ) : (
                      <form onSubmit={handleSubmit}>
                        <label>Nome do Instrutor</label>
                        <input
                          type="text"
                          placeholder="Nome"
                          className="form-control"
                          maxlength="80"
                          required
                          onChange={e => setInstrutorName(e.target.value)}
                          defaultValue={ id ? instrutorName : "" }
                        />
                        <br />
                        <label>CPF do Instrutor</label>
                        <input
                          type="text"
                          placeholder="CPF"
                          className="form-control"
                          maxlength="14"
                          required
                          onChange={e => setInstrutorCPF(e.target.value)}
                          defaultValue={ id ? instrutorCPF : "" }
                          onKeyUp = {e => inputMask(e, '000.000.000-00')}
                        />
                        <br />
                        <label>Localização do Instrutor</label>
                        <select
                          className="form-control"
                          onChange={e => selectChange(e.target.value)}
                          value={ localizacaoInstrutor || "" }
                        >
                          {options.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                        <Row>
                          <Col md={10}>
                            <AlertInfo alertInfo={alertInfo} />
                          </Col>
                          <Col md={2}>
                            <Button
                              bsStyle="info"
                              pullRight
                              fill
                              type="submit"
                              disabled={is_loading_button}
                            >
                              Salvar
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </div>
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default InstrutorItem;
