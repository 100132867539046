import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { Query } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import HttpLinkServices from "../../services/HttpLinkServices";
import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";
import Config from "../../config/config";
import { NavLink } from "react-router-dom";


const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam, // Usar o cliente dam_client para esta consulta
  cache: new InMemoryCache(),
});

const ASSETS_BY_CATEGORY = gql`
  query categoryHasAsset($category_id: Int!) {
    categoryHasAssetById(category_id: $category_id) {
      category_has_asset {
        asset {
          id
          title
        }
      }
    }
  }
`;

const TRAILS_BY_CATEGORY = gql`
  query CategoryHasTrail($category_id: Int!) {
    categoryHasTrailById(category_id: $category_id) {
      category_has_trail {
        trail {
          id
          title
        }
      }
    }
  }
`;

const COURSES_BY_CATEGORY = gql`
  query CategoryHasCourse($category_id: Int!) {
    categoryHasCourseById(category_id: $category_id) {
      category_has_course {
        course {
          id
          title
        }
      }
    }
  }
`;

class CategoryComposition extends Component {
  constructor() {
    super();
    this.state = {
      asset: [],
      trail: [],
      course: [],
    };
  }

  componentDidMount() {
    const categoryId = this.props.match.params.id; // Obter categoryId a partir das props

    this.fetchData(ASSETS_BY_CATEGORY, categoryId, "asset", dam_client); // Usar dam_client para a consulta ASSETS_BY_CATEGORY
    this.fetchData(TRAILS_BY_CATEGORY, categoryId, "trail", lms_client); // Usar lms_client para as outras consultas
    this.fetchData(COURSES_BY_CATEGORY, categoryId, "course", lms_client);
  }

  fetchData = (query, categoryId, stateKey, client) => {
    client
      .query({
        query,
        variables: { category_id: parseInt(categoryId) },
      })
      .then((response) => {
        const dataKey = Object.keys(response.data)[0];
        const items = response.data[dataKey][`category_has_${stateKey}`].map((item) => ({
          id: item[stateKey].id,
          title: item[stateKey].title,
        }));
        this.setState((prevState) => ({
          [stateKey]: [...prevState[stateKey], ...items],
        }));
      })
      .catch((error) => {
        // Handle errors
        console.error(error);
      });
  };
  

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={'/Category'}
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  VOLTAR
                </NavLink>
                <a
                  className="btn btn-success"
                  href={Config.baseUrl + "/api/v1/dam/reportVinculosCategorias?category=" + this.props.match.params.id}
                >
                  Baixar Relatório de Vínculos
                </a>
                <NotificationSystem ref="notificationSystem" style={style} />
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12}>
            <Card
              title="Conteúdos Digitais vinculados"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div className="sortable">
                  {this.state.asset.length === 0 ? (
                    <p class="category">
                      Não há Conteúdos Digitais vinculados à categoria
                    </p>
                  ) : (
                    <ul>
                      {this.state.asset.map((item, index) => (
                        <li
                          key={`asset-${index}`}
                          style={{ cursor: "default" }}
                        >
                          #{item.id} - {item.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              }
            />
            <Card
              title="Cursos vinculados"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div className="sortable">
                  {this.state.course.length === 0 ? (
                    <p class="category">Não há Cursos vinculados à categoria</p>
                  ) : (
                    <ul>
                      {this.state.course.map((item, index) => (
                        <li
                          key={`course-${index}`}
                          style={{ cursor: "default" }}
                        >
                          #{item.id} - {item.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              }
            />
            <Card
              title="Trilhas vinculadas"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div className="sortable">
                  {this.state.trail.length === 0 ? (
                    <p class="category">
                      Não há Trilhas vinculadas à categoria
                    </p>
                  ) : (
                    <ul>
                      {this.state.trail.map((item, index) => (
                        <li
                          key={`trail-${index}`}
                          style={{ cursor: "default" }}
                        >
                          #{item.id} - {item.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              }
            />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CategoryComposition;
