import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import axios from "axios";
import Config from "../../config/config";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Paginator from "../../components/Paginator";

function traduzirTipo(tipo) {
  switch (tipo) {
    case 'curso':
      return 'Curso';
    case 'trilha':
      return 'Trilha';
    default:
      return tipo;
  }
}

function getEditPage(itemType) {
  switch (itemType) {
    case 'trilha':
      return 'Trail-edit';
    case 'curso':
      return 'Course-edit';
    default:
      return '';
  }
}

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/"+$param;
  if ($param_1 != null) {
    $url += "/"+$param_1;
  }
  if ($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

class ProofComposition extends Component {
  state = {
    contentData: [],
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  };
  
  async componentDidMount() {
    try {
      const { id } = this.props.match.params;
      const { currentPage, limit } = this.state;
      const offset = (currentPage - 1) * limit;
      const endpoint = Config.baseUrl + `/api/v1/lms/linked-proofs?proof_id=${id}&offset=${offset}&limit=${limit}`;
      const response = await axios.get(endpoint);
      this.setState({
        contentData: response.data.data,
        totalPages: Math.ceil(response.data.total / limit),
        proofId: id,
      });
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }
  
  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.componentDidMount();
      }
    );
  };  
  
  renderPagination() {
    const { currentPage, totalPages } = this.state;
  
    return (
      <Paginator
        currentPage={currentPage}
        pages={totalPages}
        onChangePage={this.handlePageChange}
      />
    );
  }
  
  render() {
    const { contentData, proofId } = this.state;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
            <div className="btn-top-container">
              <a
                onClick={() => this.props.history.goBack()}
                className="btn btn-info btn-fill"
              >
                VOLTAR
              </a>
              <a
                className="btn btn-info"
                href={Config.baseUrl + `/api/v1/lms/linked-proofs?proof_id=${proofId}&report=true`}
              >
                Baixar Relatório
              </a>
              </div>

              <Card
                title={"Conteúdos Vinculados"}
                profile=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Tipo</th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                    {contentData && contentData.length > 0 ? (
                      contentData.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.title}</td>
                          <td>{traduzirTipo(item.type)}</td> {}
                          <td className="buttons-list">
                            <a title="Editar" href={getUrl(getEditPage(item.type), item.id)}>
                              <i className="pe-7s-note"></i>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">Nenhum dado disponível</td>
                      </tr>
                    )}

                    </tbody>
                  </Table>
                  {this.renderPagination()} {}
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ProofComposition;