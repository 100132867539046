import React, { useEffect, useState } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

import Button from "../../components/CustomButton/CustomButton.jsx";
import swal from "sweetalert";
import HttpLinkServices from "../../services/HttpLinkServices";
import Config from "../../config/config";
import Paginator from "../../components/Paginator";

const chat_client = new ApolloClient({
  link: HttpLinkServices.chat,
  cache: new InMemoryCache(),
});

const GET_CHATS = gql`
  query getChats(
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    chats_collections(
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      chats {
        id
        title
        datetime_start_format_br
        datetime_end_format_br
      }
    }
  }
`;
const COUNT_CHAT = gql`
  query chatsListCount($search: String!) {
    chats_collections_count(search: $search) {
      count
    }
  }
`;

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}

function getSiteUrl($param, $id) {
  return "https://portoseguro.sambalms.com.br/#/" + $param + "/" + $id;
}

const DELETE_CHAT = gql`
  mutation deletechat($id: Int!) {
    deleteChat(id: $id) {
      id
    }
  }
`;

const ChatList = () => {
  const limit = 20;
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("DESC");
  const [orderby, setOrderby] = useState("u.id");
  const [totalChats, setTotalChats] = useState(0);

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  function countCourses() {
    chat_client
      .query({
        query: COUNT_CHAT,
        variables: {
          search,
        },
      })
      .then((result) =>
        setTotalChats(result.data.chats_collections_count.count)
      );
  }

  useEffect(() => {
    countCourses();
  }, [search]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <NavLink
                to="/Chat-new"
                className="btn btn-info btn-fill"
                activeClassName=""
              >
                Nova Sala
              </NavLink>
              <NavLink
                to="/Chat-calendar"
                className="btn btn-success btn-fill"
                activeClassName=""
              >
                Calendário
              </NavLink>
            </div>
            <Card
              title="Sala Virtual"
              category="Gerenciar sala virtual"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Buscar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="u.id">ID</option>
                                <option value="title">Título</option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                              <select name="order" className="form-control">
                                <option value="DESC">Decrescente</option>
                                <option value="ASC" selected={order == "ASC"}>
                                  Crescente
                                </option>
                              </select>
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Ordenar
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Título</th>
                      <th>Início</th>
                      <th>Fim</th>
                      <th>Acessos</th>
                      <th>Relatórios</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Query
                      client={chat_client}
                      fetchPolicy="no-cache"
                      query={GET_CHATS}
                      variables={{
                        search,
                        limit,
                        page,
                        order,
                        orderby,
                      }}
                    >
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <tr>
                              <td>Carregando...</td>
                            </tr>
                          );
                        if (error)
                          return (
                            <tr>
                              <td>Erro :(</td>
                            </tr>
                          );

                        return data.chats_collections.chats.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>{item.datetime_start_format_br}</td>
                            <td>{item.datetime_end_format_br}</td>
                            <td>
                              <p>
                                <a
                                  className="btn btn-primary"
                                  href={getUrl("moderate", item.id)}
                                >
                                  Moderar Sala
                                </a>
                              </p>
                              <p>
                                <a
                                  className="btn btn-info"
                                  href={getUrl("Chat-edit", item.id)}
                                >
                                  Editar
                                </a>
                              </p>
                            </td>
                            <td>
                              <p>
                                <a
                                  className="btn btn-success"
                                  href={getUrl("segmentation", "Chat", item.id)}
                                >
                                  Segmentar
                                </a>
                              </p>
                              <p>
                                <a
                                  className="btn btn-info"
                                  href={getUrl("Dam/AnexoSala", item.id)}
                                >
                                  Inserir Anexos
                                </a>
                              </p>
                            </td>
                            <td>
                              <p>
                                <a
                                  className="btn btn-info"
                                  target="_blank"
                                  href={
                                    "https://salavirtual.sambalms.com.br/#/live/" +
                                    item.id
                                  }
                                  rel="noreferrer noopener"
                                >
                                  Acessar a Live
                                </a>
                              </p>
                              <p>
                                <a
                                  className="btn btn-info"
                                  target="_blank"
                                  href={getSiteUrl("live", item.id)}
                                  rel="noreferrer noopener"
                                >
                                  Acessar como funcionário
                                </a>
                              </p>
                            </td>
                            <td>
                              <p>
                                <a
                                  className="btn btn-info"
                                  href={
                                    Config.baseUrl +
                                    "/api/v1/analytics/get_users_by_chat?chat_id=" +
                                    item.id
                                  }
                                >
                                  Baixar usuários
                                </a>
                              </p>
                              <p>
                                <a
                                  className="btn btn-info"
                                  href={
                                    Config.baseUrl +
                                    "/api/v1/chat/report_messages?chat_id=" +
                                    item.id
                                  }
                                >
                                  Baixar menssagens
                                </a>
                              </p>
                              <p>
                                <a
                                  className="btn btn-info"
                                  href={
                                    Config.baseUrl +
                                    "/api/v1/lms/report_awnser_question_chat?chat_id=" +
                                    item.id
                                  }
                                >
                                  Baixar pesquisas
                                </a>
                              </p>
                            </td>
                            <td>
                            <Button
                              bsStyle="danger"
                              onClick={() => {
                                swal({
                                  title: `Atenção!`,
                                  text: `Tem certeza que deseja excluir o banner "${item.title}"?\n\nNão será possível recuperar o registro após a exclusão`,
                                  icon: "warning",
                                  buttons: ["Cancelar", "Excluir"],
                                  dangerMode: true,
                                  className: "custom-swal",
                                }).then((confirm) => {
                                  if (confirm) {
                                    chat_client.mutate({
                                      mutation: DELETE_CHAT,
                                      variables: {
                                        id: item.id,
                                      },
                                    }).then(() => {
                                      swal("Exclusão concluída com sucesso!", {
                                        icon: "success",
                                      }).then(() => {
                                        window.location.reload();
                                      });
                                    });
                                  }
                                });
                              }}
                            >
                              Excluir
                            </Button>
                            </td>
                          </tr>
                        ));
                      }}
                    </Query>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalChats / limit)}
              onChangePage={(page) => setPage(page)}
              siblingCount={2}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default ChatList;
