import React, {Component} from "react";
import ChartistGraph from "react-chartist";
import {Grid, Row, Col} from "react-bootstrap";
import { Link } from 'react-router-dom';

import {Card} from "../../components/Card/Card.jsx";
import {StatsCard} from "../../components/StatsCard/StatsCard.jsx";

import {Query} from "react-apollo";
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import DateTimePicker from 'react-datetime-picker';
import HttpLinkServices from "../../services/HttpLinkServices";
import Config from "../../config/config";
import SambaVideosReport from "../../components/Reports/SambaVideos.jsx";
import TermoCompromisso from './TermoCompromisso'

const analytics_client = new ApolloClient({
  link: HttpLinkServices.analytics,
  cache: new InMemoryCache(),
});

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const GET_AUTHENTICATED_USER = gql`
{
  get_authenticated_user{
    id
    name
    profile {
      id
      is_admin
      is_superadmin
    }
    
  }
}
`;

const PAGEVIEWS_ALL = gql`
{
  countPages{
    count
  }
}
`;

const PAGEVIEWS_DAY = gql`
query getCountPages($day: String!){
  countPages(day: $day){
    count
  }
}
`;


const COUNT_MEDIAS = gql`
{
  assetsCount(module_name: "Media"){
    count
  }
}
`;

const COUNT_MEDIAS_ACTIVE = gql`
query getCountMedia($active: Int!){
  assetsCount(module_name: "Media", active:$active){
    count
  }
}
`;

const COUNT_COURSE = gql`
query getCountCourse($type: String!){
  courseCount(type:$type){
    count
  }
}
`;
const COUNT_COURSE_ACTIVE = gql`
query getCountCourse($type: String!, $active: Int!){
  courseCount(type:$type, active:$active){
    count
  }
}
`;

const LIST_COURSES_ONLINE_TRENDING = gql`
{
  coursesOnlineTrending(limit:10) {
    curseshasuser{
      id, course{
        id
        title
      }
    }
  }
}
`;


const LIST_COURSES_PRESENTIAL_TRENDING = gql`
{
  coursesPresentialTrending(limit:10) {
    subscription{
        id
        classroom{
          course{
            id
            title
          }
        }
    }
  }
}
`;

const COUNT_USER = gql`
query getCountUser($interval: String!){
  userCount(interval: $interval) {
    count
  }
}
`;


class Dashboard extends Component {

  state = {
    graph_page_views: {
      dates_page_views: {
        labels: [],
        series: [
          []
        ]
      }
    }
  };

  onChange_calendar_start = dateCalendar_start => this.setState({dateCalendar_start})
  onChange_calendar_end = dateCalendar_end => this.setState({dateCalendar_end})


  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i}/>);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  componentDidMount() {
    this.getReports();
  }


  getReports = async () => {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var labels = [];
    var series = [];
    var max = 0;
    for (var i = 10; i >= 1; i--) {
      var date_graph = new Date();
      date_graph.setDate(date_graph.getDate() - i);
      var dd = String(date_graph.getDate()).padStart(2, '0');
      var mm = String(date_graph.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var $day = yyyy + '-' + mm + '-' + dd;

      const count_page_views_day = (await analytics_client.query({
        variables: {day: $day},
        query: PAGEVIEWS_DAY
      })).data.countPages.count;

      labels.push(dd + "/" + mm);
      series.push(count_page_views_day);
      if (count_page_views_day > max) {
        max = count_page_views_day;
      }

    }

    var dates_page_views = {
      labels: labels,
      series: [
        series
      ]
    };


    var graph_page_views = {dates_page_views}

    this.setState({graph_page_views});

  }


  render() {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    var today_string = yyyy + '-' + mm + '-' + dd;
    var yesterday_string = yyyy + '-' + mm + '-' + (dd - 1);

    var lastDDRel = (dd >= 1 && dd < 16 ? '01' : '16') + '/' + mm + '/' + yyyy;

    var yesterday_formatted = (
      function(){
        this.setDate(this.getDate()-1); 
        return this.toLocaleString('pt-BR',{
          year: 'numeric', 
          month: 'numeric', 
          day: 'numeric'
        })
      }
    )
    .call(new Date);

    return (
      <div className="content">
        <Query
          client={account_client}
          query={GET_AUTHENTICATED_USER}
        >
          {({loading, error, data}) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;

            var user = data.get_authenticated_user;
            return (

              <Grid fluid>
                {/* profile.id 15 = 'ADM Time Porto Educ' */}
                {(user.profile.is_superadmin || user.profile.id === 15) &&
                <div>
                  <Row>
                    <Col lg={12}>
                      <h3>Cursos</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} sm={4}>
                      <Query
                        client={lms_client}
                        query={COUNT_COURSE}
                        variables={{type: "online"}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-study text-info"/>}
                              statsText="Cursos Online"
                              statsValue={data.courseCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={lms_client}
                        query={COUNT_COURSE_ACTIVE}
                        variables={{type: "online", active: 1}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-study text-info"/>}
                              statsText="Cursos Online (Ativos)"
                              statsValue={data.courseCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={lms_client}
                        query={COUNT_COURSE_ACTIVE}
                        variables={{type: "online", active: 0}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-study text-info"/>}
                              statsText="Cursos Online (Inativos)"
                              statsValue={data.courseCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} sm={4}>
                      <Query
                        client={lms_client}
                        query={COUNT_COURSE}
                        variables={{type: "presential"}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-study text-info"/>}
                              statsText="Cursos Presenciais"
                              statsValue={data.courseCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={lms_client}
                        query={COUNT_COURSE_ACTIVE}
                        variables={{type: "presential", active: 1}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-study text-info"/>}
                              statsText="Cursos Presenciais (Ativos)"
                              statsValue={data.courseCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={lms_client}
                        query={COUNT_COURSE_ACTIVE}
                        variables={{type: "presential", active: 0}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-study text-info"/>}
                              statsText="Cursos Presenciais (Inativos)"
                              statsValue={data.courseCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                  </Row>
                  <Row>
                    <div className="dashboard-sections">
                      <a className="btn btn-info" href={Config.baseUrl + "/api/v1/lms/report_classes"} rel='noreferrer noopener'>
                        Lista de Turmas
                      </a>
                      <a className="btn btn-info" href={Config.baseUrl + "/api/v1/lms/download_turmas_desalocadas"} rel='noreferrer noopener'>
                        Turmas s/ Gestão ({lastDDRel})
                      </a>
                      <a className="btn btn-info" href={Config.baseUrl + "/api/v1/lms/relatorio_cm_progressao"} rel='noreferrer noopener'>
                        CMProgressao ({yesterday_formatted})
                      </a>


                      {user.profile.is_superadmin ? (
                        //Token esta setado manualmente pois se trata de um retorno segmetado apenas para este token. SLPS-254
                        <a className="btn btn-info" href={Config.baseUrl + "/api/v1/lms/relatorio_cursos_pb?stlms_token=5ce007774afee_17"}
                          rel='noreferrer noopener'>
                           Relatório de Cursos
                        </a>) : ''
                      }
                      


                    </div>
                  </Row>
                  <hr/>
                  <Row>
                    <Col md={12}>
                      <h3>Relatório Samba Vídeos</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <SambaVideosReport />
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col md={12}>
                      <h3>Programa Andamento</h3>
                    </Col>
                  </Row>

                  <Row>
                    <div className="dashboard-sections mt-16"> 
                      <Link   
                        className="btn btn-info" 
                        to="reports/program-in-progress"
                      > 
                        Programa Andamento
                      </Link> 
                      <Link   
                        className="btn btn-info" 
                        to="reports/turmas-presenciais"
                      > 
                        Turmas Presenciais
                      </Link> 
                    </div> 
                  </Row> 
                  
                  <hr/>
                  <Row>
                    <Col lg={12}>
                      <h3>Cursos mais feitos</h3>
                    </Col>
                  </Row>
                  <Row>

                    <Col md={6}>
                      <Card
                        title="Cursos online mais feitos"
                        category="Considerando apenas cursos concluídos"
                        stats="Atualizado"
                        statsIcon="fa fa-history"
                        content={
                          <div className="table-full-width">
                            <table className="table">
                              <tbody>
                              <Query
                                client={lms_client}
                                query={LIST_COURSES_ONLINE_TRENDING}
                              >
                                {({loading, error, data}) => {
                                  if (loading) return <tr>
                                    <td>Carregando...</td>
                                  </tr>;
                                  if (error) return <tr>
                                    <td>Erro :(</td>
                                  </tr>;

                                  if (!data.coursesOnlineTrending.curseshasuser) {
                                    return (<tr>
                                      <td>(Vazio)</td>
                                    </tr>);
                                  }

                                  return data.coursesOnlineTrending.curseshasuser.map((item) => (
                                    <tr key={item.id}>
                                      <td>
                                        #{item.course.id}
                                      </td>
                                      <td>{item.course.title}</td>
                                    </tr>
                                  ));
                                }}
                              </Query>
                              </tbody>
                            </table>
                          </div>
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <Card
                        title="Cursos presenciais mais feitos"
                        category="Considerando apenas as presenças"
                        stats="Atualizado"
                        statsIcon="fa fa-history"
                        content={
                          <div className="table-full-width">
                            <table className="table">
                              <tbody>
                              <Query
                                client={lms_client}
                                query={LIST_COURSES_PRESENTIAL_TRENDING}
                              >
                                {({loading, error, data}) => {
                                  if (loading) return <tr>
                                    <td>Carregando...</td>
                                  </tr>;
                                  if (error) return <tr>
                                    <td>Erro :(</td>
                                  </tr>;

                                  if (!data.coursesPresentialTrending.subscription) {
                                    return (<tr>
                                      <td>(Vazio)</td>
                                    </tr>);
                                  }

                                  return data.coursesPresentialTrending.subscription.map((item) => (
                                    <tr key={item.id}>
                                      <td>
                                        #{item.classroom.course.id}
                                      </td>
                                      <td>{item.classroom.course.title}</td>
                                    </tr>
                                  ));
                                }}
                              </Query>
                              </tbody>
                            </table>
                          </div>
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <h3>Conteúdos Digitais</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} sm={4}>
                      <Query
                        client={dam_client}
                        query={COUNT_MEDIAS}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-photo text-info"/>}
                              statsText="Conteúdos Digitais"
                              statsValue={data.assetsCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={dam_client}
                        query={COUNT_MEDIAS_ACTIVE}
                        variables={{active: 1}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-photo text-info"/>}
                              statsText="Conteúdos Digitais (Ativos)"
                              statsValue={data.assetsCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={dam_client}
                        query={COUNT_MEDIAS_ACTIVE}
                        variables={{active: 0}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;
                          return (
                            <StatsCard
                              bigIcon={<i className="pe-7s-photo text-info"/>}
                              statsText="Conteúdos Digitais (Inativos)"
                              statsValue={data.assetsCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                  </Row>
                  <Row>
                    <div className="dashboard-sections">
                      <Link   
                        className="btn btn-info" 
                        to="reports/download-conteudos"
                      > 
                        Downloads por usuário
                      </Link>  
                      <a className="btn btn-info" href={Config.baseUrl + "/api/v1/dam/report_download_items"} rel='noreferrer noopener'>
                        Downloads por arquivo
                      </a>
                    </div>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <h3>Usuários</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} sm={4}>
                      <Query
                        client={account_client}
                        query={COUNT_USER}
                        variables={{interval: 'all'}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-users text-info"/>}
                              statsText="Total de usuários"
                              statsValue={data.userCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={account_client}
                        query={COUNT_USER}
                        variables={{interval: 'today'}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-users text-info"/>}
                              statsText="Novos Usuários Hoje"
                              statsValue={data.userCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={account_client}
                        query={COUNT_USER}
                        variables={{interval: 'yesterday'}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-users text-info"/>}
                              statsText="Novos Usuários Ontem"
                              statsValue={data.userCount.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                  </Row>
                </div>
                }

                <Row>
                  <Col lg={12}>
                    <h3>Download Lista e Acessos dos Corretores</h3>
                  </Col>
                </Row>
                <Row> 
                  <div className="dashboard-sections mt-16"> 
                    <Link   
                        className="btn btn-info" 
                        to="reports/lista-corretores"
                      > 
                        Lista Corretores
                      </Link>
                    <Link   
                        className="btn btn-info" 
                        to="reports/acesso-corretores"
                      > 
                        Acesso Corretores
                      </Link> 
                  </div>
                </Row>                
                {user.profile.is_superadmin &&
                <div>
                  <Row>
                    <Col lg={12}>
                      <h3>Page Views</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} sm={4}>
                      <Query
                        client={analytics_client}
                        query={PAGEVIEWS_DAY}
                        variables={{day: today_string}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-browser text-info"/>}
                              statsText="Page Views Hoje"
                              statsValue={data.countPages.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={analytics_client}
                        query={PAGEVIEWS_DAY}
                        variables={{day: yesterday_string}}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-browser text-info"/>}
                              statsText="Page Views Ontem"
                              statsValue={data.countPages.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Query
                        client={analytics_client}
                        query={PAGEVIEWS_ALL}
                      >
                        {({loading, error, data}) => {
                          if (loading) return <div>Carregando...</div>;
                          if (error) return <div>Erro :(</div>;

                          return (

                            <StatsCard
                              bigIcon={<i className="pe-7s-browser text-info"/>}
                              statsText="Page Views Total"
                              statsValue={data.countPages.count}
                              statsIcon={<i className="fa fa-refresh"/>}
                              statsIconText="Atualizado agora"
                            />
                          );
                        }
                        }
                      </Query>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card
                        statsIcon="fa fa-history"
                        id="chartHours"
                        title="Page Views"
                        category="10 dias"
                        stats="Atualizado agora"
                        content={
                          <div className="ct-chart">
                            <ChartistGraph
                              data={this.state.graph_page_views.dates_page_views}
                              type="Line"
                            />
                          </div>
                        }
                      />
                    </Col>
                  </Row>

                </div>
                }
                <TermoCompromisso />
              </Grid>
            );
          }
          }
        </Query>
      </div>
    );
  }
}

export default Dashboard;
