import React from 'react';
import ReactSelect from 'react-select';

function Select({ name, placeholder, options, defaultValue, onChange }) {
  return (
    <ReactSelect
      name={name}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      className="basic-select"
      classNamePrefix="select"
      isClearable={false}
      noOptionsMessage={() => "Sem opções"}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#bbb",
          primary25: "rgba(170, 170, 170, 0.2)",
        }
      })}
    />
  );
}

export default Select;