import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import { HttpLink } from "apollo-link-http";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from "react-select";
import { validationFields } from "../../utils/validationFields.js";
import UserService from "../../services/UserServices.js";
import { toast } from "react-toastify";

import { UpdateInfo } from "../../components/UpdateInfo/index.jsx";

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});
// simplesamlphp_idp.conf/modules/core/templates/loginuserpass.php
const ADD_TODO = gql`
  mutation updateUser(
    $id: Int!
    $name: String!
    $mail: String!
    $uid: String!
    $profile_id: Int!
    $is_blocked: Int!
    $blocked_description: String!
  ) {
    updateUser(
      id: $id
      input: {
        name: $name
        mail: $mail
        uid: $uid
        profile_id: $profile_id
        is_blocked: $is_blocked
        blocked_description: $blocked_description
      }
    ) {
      id
    }
  }
`;

const GET_USER = gql`
  query getUser($user_id: Int!) {
    user(id: $user_id) {
      name
      mail
      uid
      created_at 
      created_by 
      updated_at 
      updated_by
      profile {
        value: id
        label: title
      }
      is_blocked
      blocked_description
    }
  }
`;

const userService = new UserService();
class UserItem extends Component {
  user_id;
  user_edit;
  parent_id;
  back_button;

  state = {
    alertInfo: "",
    checkboxValue_is_blocked: false,
  };

  constructor() {
    super();

    this.start_is_blocked = true;
  }

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_is_blocked: !this.state.checkboxValue_is_blocked,
    }));
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!event.target.elements.is_blocked.checked) {
      event.target.elements.blocked_description.value = "";
    }

    const data = {
      id: this.user_id,
      name: event.target.elements.name.value,
      mail: event.target.elements.mail.value,
      uid: event.target.elements.uid.value,
      is_blocked: event.target.elements.is_blocked.checked ? 1 : 0,
      blocked_description: event.target.elements.blocked_description.value,
      profile_id: event.target.elements.profile_id.value
        ? parseInt(event.target.elements.profile_id.value)
        : 0,
    };

    try {
      validationFields({ name: data.name, email: data.mail, uid: data.uid });

      await userService.updateUser(data);

      this.setState({
        alertInfo: "Salvo com sucesso!",
      });

      setTimeout(() => {
        this.setState({
          alertInfo: "",
        });
      }, 3000);
    } catch (error) {
      if (!error.message.includes("GraphQL")) {
        toast.error(error.message);
      } else {
        error.graphQLErrors.map((err) => toast.error(err.message));
      }
    }
  };

  render() {
    this.user_id = this.props.match.params.id ? this.props.match.params.id : 0;
    this.parent_id = this.props.match.params.parent_id
      ? this.props.match.params.parent_id
      : 0;

    return (
      <Query
        client={account_client}
        query={GET_USER}
        variables={{ user_id: this.user_id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.user_edit = data.user;

          if (
            this.start_is_blocked &&
            this.user_edit &&
            this.user_edit.is_blocked
          ) {
            this.start_is_blocked = false;
            this.setState({
              checkboxValue_is_blocked:
                this.user_edit.is_blocked === 1 ? true : false,
            });
          }

          return (
            <div className="content">
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    <div className="btn-top-container">
                      <NavLink
                        to="/User"
                        className="btn btn-info btn-fill"
                        activeClassName=""
                      >
                        VOLTAR
                      </NavLink>
                      <NotificationSystem
                        ref="notificationSystem"
                        style={style}
                      />
                    </div>

                    <Card
                      name="Usuário"
                      content={
                        <form
                          onSubmit={(e) => {
                            this.handleSubmit(e);
                          }}
                        >
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "name",
                                label: "Nome",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "Nome",
                                isRequired: true,
                                defaultValue: this.user_edit
                                  ? this.user_edit.name
                                  : "",
                                maxLength: 80
                              },
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "mail",
                                label: "E-mail",
                                type: "email",
                                bsClass: "form-control",
                                placeholder: "mail",
                                isRequired: true,
                                maxLength: 80,
                                defaultValue: this.user_edit
                                  ? this.user_edit.mail
                                  : "",
                              },
                            ]}
                          />
                          <FormInputs
                            ncols={["col-md-12"]}
                            proprieties={[
                              {
                                name: "uid",
                                label: "User ID (UID)",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "User ID (UID)",
                                isRequired: true,
                                maxLength: 14,
                                defaultValue: this.user_edit
                                  ? this.user_edit.uid
                                  : "",
                              },
                            ]}
                          />

                          <FormGroup>
                            <ControlLabel>Perfil</ControlLabel>
                            <div>
                              <Query
                                client={account_client}
                                query={gql`
                                  {
                                    profiles_collections {
                                      profiles {
                                        value: id
                                        label: title
                                      }
                                    }
                                  }
                                `}
                              >
                                {({ loading, error, data }) => {
                                  if (loading) return <div>Carregando...</div>;
                                  if (error) return <div>Erro :(</div>;
                                  return (
                                    <Select
                                      name="profile_id"
                                      options={
                                        data.profiles_collections
                                          ? data.profiles_collections.profiles
                                          : []
                                      }
                                      className="basic-select"
                                      classNamePrefix="select"
                                      isClearable={true}
                                      defaultValue={
                                        this.user_edit && this.user_edit
                                          ? this.user_edit.profile
                                          : ""
                                      }
                                    />
                                  );
                                }}
                              </Query>
                            </div>
                          </FormGroup>

                          <FormGroup>
                            <ControlLabel>
                              <input
                                value="1"
                                name="is_blocked"
                                type="checkbox"
                                checked={this.state.checkboxValue_is_blocked}
                                onChange={this.handleCheckboxChange}
                              />{" "}
                              Bloquear usuário
                            </ControlLabel>
                          </FormGroup>

                          <div
                            class="form-group"
                            className={
                              this.state.checkboxValue_is_blocked
                                ? ""
                                : "hidden"
                            }
                          >
                            <label class="control-label">
                              Motivo do bloqueio
                            </label>
                            <textarea
                              rows="8"
                              className="form-control"
                              name="blocked_description"
                              maxLength="255"
                            >
                              {this.user_edit
                                ? this.user_edit.blocked_description
                                : ""}
                            </textarea>
                          </div>

                          <Row>
                            <Col md={10}>
                              <AlertInfo
                                ref="alertSave"
                                alertInfo={this.state.alertInfo}
                              />
                            </Col>
                            <Col md={2}>
                              <Button
                                bsStyle="info"
                                pullRight
                                fill
                                type="submit"
                              >
                                Salvar
                              </Button>
                            </Col>
                          </Row>
                          <div className="clearfix" />
                        </form>
                      }
                    />
                  </Col>
                </Row>
                {(window.location.hash).includes('edit')
                  ? <UpdateInfo content={this.user_edit}/>
                  : <></>
                }
              </Grid>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default UserItem;