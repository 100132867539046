import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { toast } from 'react-toastify'
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import Config from "../../config/config";
import { UpdateInfo } from "../../components/UpdateInfo/index.jsx";

const course_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateCourse(
    $id: Int!
    $title: String!
    $description: String!
    $active: Int!
    $is_subscriptions: Int!
    $date_publish: String!
    $date_unpublish: String!
    $hours: Int!
    $minutes: Int!
    $category_id: Int!
  ) {
    createOrUpdateCourse(
      id: $id
      input: {
        title: $title
        description: $description
        active: $active
        is_subscriptions: $is_subscriptions
        date_publish: $date_publish
        date_unpublish: $date_unpublish
        hours: $hours
        minutes: $minutes
        type: "presential"
        category_id: $category_id
      }
    ) {
      id
    }
  }
`;

const GET_COURSE = gql`
  query getCourse($course_id: Int!) {
    course(id: $course_id) {
      id
      category_id
      title
      description
      thumbnail
      active
      is_subscriptions
      date_publish_format
      date_unpublish_format
      hours
      minutes
    }
  }
`;

const GET_CATEGORIES = gql`
  {
    category_collections {
      categories {
        value: id
        label: title
      }
    }
  }
`;

const GET_CATEGORY = gql`
  query getCategory($id: Int!) {
    category(id: $id) {
      value: id
      label: title
    }
  }
`;

class CourseItem extends Component {
  course_id;
  course_edit;
  thumbnail_on_create;

  constructor() {
    super();
    this.state = {
      is_loading: false,
      title_error: "",
      description_error: "",
      hours_error: "",
      minutes_error: "",
      category_error: "",
      responsible_error: "",
      alertInfo: "",
      datePublish: new Date(new Date().setHours(0,0,0,0)),
      dateUnpublish: null,
    };

    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_is_subscriptions = true;
  }

  onChange_datePublish = (date) => {
    const { dateUnpublish } = this.state;

    if(!date && dateUnpublish){
      this.setState({ dateUnpublish: null });
    }

    this.setState({ datePublish: date });
  }
  
  onChange_dateUnpublish = (date) => {
    const { datePublish } = this.state;
    
    if(!datePublish && date){
      toast.error('Informe a data de publicação', {
        autoClose: 5000
      });
      this.setState({ dateUnpublish: null });
      return;
    }

    if (date < datePublish && date) {
      toast.error('A data para despublicar não pode ser anterior a data de publicação', {
        autoClose: 5000
      });
      return;
    }
    
    this.setState({ dateUnpublish: date });
  }

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active,
    }));
  };

  handleCheckboxChange_is_subscriptions = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_is_subscriptions:
        !this.state.checkboxValue_is_subscriptions,
    }));
  };

  handleSubmit = (event) => {
    this.setState({
      alertInfo: "Salvo com sucesso!",
    });

    setTimeout(() => {
      this.setState({
        alertInfo: "",
      });
      window.location.href = Config.baseUrl + "#/Presential-course";
      window.scrollTo(0, 0);
    }, 2000);
  };

  handleSelectThumbnailOnCreate = (e) => {
    this.thumbnail_on_create = e.currentTarget.files[0];
  };

  sendThumbnail = (trailId) => {
    // Enviar a thumbnail com o link do conteúdo criado
    if (!this.thumbnail_on_create) return;

    const formData = new FormData();
    formData.append("id", trailId);
    formData.append("file", this.thumbnail_on_create);

    const request = new XMLHttpRequest();
    request.open("POST", Config.baseUrl + "/api/v1/lms/course_thumb");
    request.send(formData);
  };

  handleSubmitForm = async (e, createOrUpdateCourse) => {
    e.preventDefault();

    if (!this.formValidation(e)) return;

    this.setState({ is_loading: true });

    try {
      const response = await createOrUpdateCourse({
        variables: {
          id: this.course_id,
          title: e.target.elements.title.value,
          description: e.target.elements.description.value,
          active: e.target.elements.active.checked ? 1 : 0,
          is_subscriptions: e.target.elements.is_subscriptions.checked ? 1 : 0,
          date_publish: e.target.elements.date_publish.value,
          date_unpublish: e.target.elements.date_unpublish.value,
          hours: parseInt(e.target.elements.hours.value),
          minutes: parseInt(e.target.elements.minutes.value),
          category_id: e.target.elements.category_id.value
            ? e.target.elements.category_id.value
            : 0,
          responsible: e.target.elements.responsible.value,
        },
      });

      const courseId = response.data.createOrUpdateCourse.id;

      this.sendThumbnail(courseId);

      this.handleSubmit();
    } catch (e) {
      this.setState({ is_loading: false });
      alert("Houve um erro ao enviar os dados para o servidor");
    }
  };

  formValidation = (e) => {
    this.setState({
      title_error: "",
      description_error: "",
      hours_error: "",
      minutes_error: "",
      category_error: "",
      responsible_error: "",
    });
    if (!e.target.elements.title.value) {
      const titleInput = e.target.elements.title;
      titleInput.focus();
      this.setState({ title_error: "Digite um título" });
    }

    if (!e.target.elements.description.value) {
      const descriptionInput = e.target.elements.description;
      descriptionInput.focus();
      this.setState({ description_error: "Dê uma descrição" });
    }

    if (!e.target.elements.responsible.value) {
      const responsibleInput = e.target.elements.responsible;
      responsibleInput.focus();
      this.setState({ responsible_error: "Informe um responsável" });
    }

    if (!e.target.elements.hours.value) {
      const hoursInput = e.target.elements.hours;
      hoursInput.focus();
      this.setState({ hours_error: "Informe a carga horária (horas)" });
    }

    if (!e.target.elements.minutes.value) {
      const minutesInput = e.target.elements.minutes;
      minutesInput.focus();
      this.setState({ minutes_error: "Informe a carga horária (minutos)" });
    }

    if (!e.target.elements.category_id.value) {
      const categoryInput = e.target.elements.category_id;
      categoryInput.focus();
      this.setState({ category_error: "Selecione uma categoria" });
    }

    if (
      !e.target.elements.title.value ||
      !e.target.elements.description.value ||
      !e.target.elements.hours.value ||
      !e.target.elements.minutes.value ||
      !e.target.elements.category_id.value ||
      !e.target.elements.responsible.value
    ) {
      return false;
    }

    return true;
  };

  render() {
    if (this.props.match.params.id) {
      this.course_id = parseInt(this.props.match.params.id);
    } else {
      this.course_id = 0;
    }

    return (
      <Query
        client={course_client}
        query={GET_COURSE}
        variables={{ course_id: this.course_id }}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.course_edit = data.course;

          if (
            this.start_publish_date &&
            this.course_edit &&
            this.course_edit.date_publish_format
          ) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.course_edit.date_publish_format),
            });
          }
          if (
            this.start_unpublish_date &&
            this.course_edit &&
            this.course_edit.date_unpublish_format
          ) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.course_edit.date_unpublish_format),
            });
          }
          if (
            this.start_active &&
            this.course_edit &&
            this.course_edit.active
          ) {
            this.start_active = false;
            this.setState({
              checkboxValue_active:
                this.course_edit.active === 1 ? true : false,
            });
          }
          if (
            this.start_is_subscriptions &&
            this.course_edit &&
            this.course_edit.is_subscriptions
          ) {
            this.start_is_subscriptions = false;
            this.setState({
              checkboxValue_is_subscriptions:
                this.course_edit.is_subscriptions === 1 ? true : false,
            });
          }

          return (
            <Mutation mutation={ADD_TODO} client={course_client}>
              {(createOrUpdateCourse, { data }) => (
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div className="btn-top-container">
                          <NavLink
                            to="/Presential-course"
                            className="btn btn-info btn-fill"
                            activeClassName=""
                          >
                            VOLTAR
                          </NavLink>

                          <NotificationSystem
                            ref="notificationSystem"
                            style={style}
                          />
                        </div>

                        <Card
                          title="Curso"
                          content={
                            <form
                              onSubmit={(e) => {
                                this.handleSubmitForm(e, createOrUpdateCourse);
                              }}
                            >
                              <FormGroup>
                                <ControlLabel>Categoria</ControlLabel>
                                <div>
                                  <Query
                                    client={dam_client}
                                    query={GET_CATEGORIES}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                      var categories = data;
                                      return (
                                        <Query
                                          variables={{
                                            id:
                                              this.course_edit &&
                                              this.course_edit.category_id
                                                ? this.course_edit.category_id
                                                : 0,
                                          }}
                                          client={dam_client}
                                          query={GET_CATEGORY}
                                        >
                                          {({ loading, error, data }) => {
                                            if (loading)
                                              return <div>Carregando...</div>;
                                            if (error)
                                              return <div>Erro :(</div>;
                                            var category = data;
                                            return (
                                              <>
                                                <Select
                                                  name="category_id"
                                                  options={
                                                    categories.category_collections
                                                      ? categories
                                                          .category_collections
                                                          .categories
                                                      : []
                                                  }
                                                  className="basic-select"
                                                  classNamePrefix="select"
                                                  defaultValue={
                                                    category.category &&
                                                    this.course_edit
                                                      ? category.category
                                                      : ""
                                                  }
                                                />
                                                {this.state.category_error && (
                                                  <p className="inputError">
                                                    {this.state.category_error}
                                                  </p>
                                                )}
                                              </>
                                            );
                                          }}
                                        </Query>
                                      );
                                    }}
                                  </Query>
                                </div>
                              </FormGroup>

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "title",
                                    label: "Título",
                                    type: "text",
                                    maxLength: 140,
                                    bsClass: "form-control",
                                    placeholder: "Título",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.title
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.title_error && (
                                <p className="inputError">
                                  {this.state.title_error}
                                </p>
                              )}

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "description",
                                    label: "Descrição",
                                    maxLength: 1024,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Descrição",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.description
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.description_error && (
                                <p className="inputError">
                                  {this.state.description_error}
                                </p>
                              )}

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "responsible",
                                    label: "Responsável",
                                    maxLength: 80,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Responsável",
                                    defaultValue: "",
                                  },
                                ]}
                              />
                              {this.state.responsible_error && (
                                <p className="inputError">
                                  {this.state.responsible_error}
                                </p>
                              )}

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "hours",
                                    label: "Carga horária (em horas)",
                                    type: "number",
                                    bsClass: "form-control",
                                    maxLength: 3,
                                    placeholder: "Carga horária (em horas)",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.hours
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.hours_error && (
                                <p className="inputError">
                                  {this.state.hours_error}
                                </p>
                              )}

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "minutes",
                                    label: "Carga horária (em minutos)",
                                    type: "number",
                                    maxLength: 2,
                                    bsClass: "form-control",
                                    placeholder: "Carga horária (em minutos)",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.minutes
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.minutes_error && (
                                <p className="inputError">
                                  {this.state.minutes_error}
                                </p>
                              )}

                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="is_subscriptions"
                                    type="checkbox"
                                    checked={
                                      this.state.checkboxValue_is_subscriptions
                                    }
                                    onChange={
                                      this.handleCheckboxChange_is_subscriptions
                                    }
                                  />{" "}
                                  Aberto para autoinscrição
                                </ControlLabel>
                              </FormGroup>

                              <hr />
                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="active"
                                    type="checkbox"
                                    checked={this.state.checkboxValue_active}
                                    onChange={this.handleCheckboxChange}
                                  />{" "}
                                  Ativo
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>Data de publicação</ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_publish"
                                    onChange={this.onChange_datePublish}
                                    value={this.state.datePublish}
                                    required={true}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  Data para despublicar
                                </ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_unpublish"
                                    onChange={this.onChange_dateUnpublish}
                                    value={this.state.dateUnpublish}
                                    required={false}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>

                              <Row>
                                <Col md={10}>
                                  <AlertInfo
                                    ref="alertSave"
                                    alertInfo={this.state.alertInfo}
                                  />
                                </Col>
                                <Col md={2}>
                                  <Button
                                    bsStyle="info"
                                    pullRight
                                    fill
                                    type="submit"
                                    disabled={this.state.is_loading}
                                  >
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          }
                        />

                        <Card
                          title="Thumbnail"
                          content={
                            this.course_edit ? (
                              <form
                                action={
                                  Config.baseUrl + "/api/v1/lms/course_thumb"
                                }
                                method="post"
                                enctype="multipart/form-data"
                              >
                                <input
                                  type="hidden"
                                  name="returnUrl"
                                  value={
                                    "/#/Course-edit/" + this.course_edit.id
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="id"
                                  value={this.course_edit.id}
                                />

                                <input type="file" name="file" required />

                                <Row>
                                  <Col md={2}>
                                    <Button
                                      bsStyle="info"
                                      pullRight
                                      fill
                                      type="submit"
                                    >
                                      Upload
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="clearfix" />
                              </form>
                            ) : (
                              <input
                                type="file"
                                name="file"
                                accept="image/*"
                                required
                                onChange={this.handleSelectThumbnailOnCreate}
                              />
                            )
                          }
                        />

                        <Card
                          title=""
                          content={
                            this.course_edit && this.course_edit.thumbnail ? (
                              <div>
                                <img
                                  alt="thumb"
                                  src={
                                    Config.baseUrl +
                                    "/lms/assets/" +
                                    this.course_edit.thumbnail
                                  }
                                  height="100px"
                                />
                              </div>
                            ) : (
                              <div />
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Grid>
                  {(window.location.hash).includes('edit')
                    ? <UpdateInfo content={this.course_edit}/>
                    : <></>
                  }
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default CourseItem;
