import React, { Component } from "react";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import Button from "../CustomButton/CustomButton.jsx";
import Message from "./Message.jsx";
import HttpLinkServices from "../../services/HttpLinkServices";

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const chat_client = new ApolloClient({
  link: HttpLinkServices.chat,
  cache: new InMemoryCache(),
});

const SAVE_CHAT_MSG = gql`
  mutation AddTodo($user_id: Int!, $user_name: String!, $chat_id: Int!, $message: String!, $response_to_user_id: Int!){
    createChatMessage(input:{user_id:$user_id,user_name:$user_name,chat_id:$chat_id,message:$message, response_to_user_id:$response_to_user_id }){
      id
    }
  }
`;

const GET_CHAT_MSG = gql`
  query getChatMgs($user_id: Int!, $chat_id: Int!, $limit: Int!, $offset: Int!, $show_my_msgs: Int!){
    chat_message_collections_by_user(user_id:$user_id, chat_id:$chat_id,limit:$limit,offset:$offset,show_my_msgs:$show_my_msgs){
      messages{
        is_admin,
        user_name,
        message,
        response_from{
          user_name,
          message
        }
      }
    }
  }
`;

// const GET_USER = gql`
//   query getUser($user_id: Int!){
//     user(id:$user_id){
//       id,
//       name
//     }
//   }
// `;

const GET_AUTH_USER = gql`
{
  get_authenticated_user{
    id,
    first_and_last_name
  }
}
`;


export class Chat extends Component {


  user_auth;
  start_msgs;
  offset_messages;

  constructor() {
    super();
    this.state = {
      messages: []
    };

    this.start_msgs = true;
    

  }

  scrollMessages() {
    setTimeout(function(){
      var chat_body = document.getElementById("chat_body");
      chat_body.scrollTop = chat_body.scrollHeight;
    },100);
  }

  newUserMessage(newMessage, user_name = null) {

    var $chat_id = parseInt(this.props.chat_id);
    
    // Se não for passado o nome do usuário no param, pega o o nome do usuário logado
    var $user_name = (user_name !== null ? user_name : this.user_auth.first_and_last_name);
    
    var all_messages = this.state.messages;
    all_messages.push({user_name:this.user_auth.first_and_last_name,  message:newMessage});

    this.offset_messages++;
    this.setState(state => ({
      ...state,
      messages: all_messages
    }));

    this.scrollMessages();

    // Salva a mensagem no banco
    chat_client.mutate({
      mutation: SAVE_CHAT_MSG,
      variables: {
        user_id:this.user_auth.id, user_name:$user_name, chat_id:$chat_id,message:newMessage, response_to_user_id: 0
      }
    });
    
  }

  previous_messages () {
    chat_client.query({
      query: GET_CHAT_MSG,
      fetchPolicy: 'no-cache',
      variables: {
        user_id:this.user_auth.id, chat_id:this.chat_id, limit:2000, offset:0, show_my_msgs:1 
      }
    }).then(result => {
        this.setState(state => ({
          ...state,
          messages: result.data.chat_message_collections_by_user.messages
        }));
        this.offset_messages = (result.data.chat_message_collections_by_user.messages.length);
        this.scrollMessages();
        this.next_messages();
    });
  }

  next_messages () {
    chat_client.query({
      query: GET_CHAT_MSG,
      fetchPolicy: 'no-cache',
      variables: {
        user_id:this.user_auth.id, chat_id:this.chat_id, limit:2000, offset:this.offset_messages, show_my_msgs:1
      }
    }).then(result => {
        
        var new_messages = result.data.chat_message_collections_by_user.messages;
        if(new_messages.length) {
          var messages_concat = this.state.messages.concat(new_messages);
          this.setState(state => ({
            ...state,
            messages: messages_concat
          }));
          this.offset_messages = (this.state.messages.length);
          this.scrollMessages();
        }
        // tenta pegar mais mensagens após alguns segundos
        var self = this;
        setTimeout(function() {
          self.next_messages();
        },5000);
    });
  }

  
  render() {

    return (
      <Query
          client={account_client}
          query={GET_AUTH_USER}
        >
          {({ loading, error, data }) => {
            if (loading) return <div>Carregando...</div>;
            if (error) return <div>Erro :(</div>;
              
            this.user_auth = data.get_authenticated_user;
            this.chat_id = parseInt(this.props.chat_id);
            
            // Printar as mensagens existentes
            if(this.start_msgs) {
              this.start_msgs = false;
              var self = this;
              setTimeout(function() {
                self.previous_messages();
              },1000);
            }
            
            return (

                <div className="chat">
                  <div className="chat_header">
                    {this.user_auth.first_and_last_name}
                    <div className="chat_label">ONLINE</div>
                  </div>
                  <div id="chat_body" className="chat_body">
                    <div id="chat_messages">
                      {this.state.messages.map((message, i) => (
                        <Message key={i} user_name={message.user_name} message={message.message} response_from={message.response_from ? message.response_from : null} is_admin={message.is_admin ? message.is_admin : null} />
                      ))}
                    </div>
                  </div>
                  <div className="chat_footer">
                    <input type="text" placeholder="Comece com # para perguntar" id="input_message" />
                    <Button bsStyle="info" onClick={() => {
                        var message = document.getElementById("input_message").value;
                        if(message) {
                          this.newUserMessage(message);
                          document.getElementById("input_message").value = "";
                        }
                      }}>
                      Enviar
                      </Button>
                  </div>
                </div>
        );

        }}
        </Query>
    );
  }
}

export default Chat;
