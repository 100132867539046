import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { toast } from "react-toastify";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import { requestVideosSearch } from "../../services/RequestSambaVideos";

import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import Config from "../../config/config";
import { UpdateInfo } from "../../components/UpdateInfo/index.jsx";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateCourse(
    $id: Int!
    $title: String!
    $description: String!
    $video: String!
    $active: Int!
    $external_view: Int!
    $showgrid: Int!
    $date_publish: String!
    $date_unpublish: String!
    $reactionevaluation_id: Int!
    $proof_id: Int!
    $owner: Int!
    $hours: Int!
    $minutes: Int!
    $code: String!
  ) {
    createOrUpdateCourse(
      id: $id
      input: {
        title: $title
        description: $description
        video: $video
        active: $active
        external_view: $external_view
        showgrid: $showgrid
        date_publish: $date_publish
        date_unpublish: $date_unpublish
        type: "online"
        reactionevaluation_id: $reactionevaluation_id
        proof_id: $proof_id
        owner: $owner
        hours: $hours
        minutes: $minutes
        code: $code
      }
    ) {
      id
    }
  }
`;

const GET_COURSE = gql`
  query getCourse($course_id: Int!) {
    course(id: $course_id) {
      id
      title
      description
      thumbnail
      scorm
      active
      external_view
      showgrid
      owner
      date_publish_format
      date_unpublish_format
      created_at
      created_by
      updated_at
      updated_by
      hours
      minutes
      code
      video {
        value: id
        label: title
      }
      reactionevaluation {
        value: id
        label: title
      }
      proof {
        value: id
        label: title
      }
    }
  }
`;

const GET_PROOFS = gql`
  query getProofs($type_id: Int!) {
    proof_collections(type_id: $type_id) {
      proofs {
        value: id
        label: title
      }
    }
  }
`;

const GET_CATEGORY_HAS_COURSE = gql`
  query categoryHasCourse_collection($course_id: Int!) {
    categoryHasCourse_collection(course_id: $course_id) {
      category_has_course {
        id
        category_id
      }
    }
  }
`;

const CREATE_CATEGORY_HAS_COURSE = gql`
  mutation createCategoryHasCourse($course_id: Int!, $category_id: Int!) {
    createCategoryHasCourse(course_id: $course_id, category_id: $category_id) {
      id
    }
  }
`;

const DELETE_CATEGORY_HAS_COURSE = gql`
  mutation deleteCategoryHasCourse($course_id: Int!, $category_id: Int!) {
    deleteCategoryHasCourse(course_id: $course_id, category_id: $category_id) {
      id
    }
  }
`;

const GET_CATEGORIES = gql`
  {
    category_collections {
      categories {
        value: id
        label: title
      }
    }
  }
`;

const GET_GUARDIANS = gql`
query usersByProfile($id: Int!) {
  get_users_by_profile(id: $id) {
    users {
      name
      id
      mail
      uid
      __typename
    }
    __typename
  }
}`;

class CourseItem extends Component {
  course_id;
  course_edit;
  categories_on_create;
  thumbnail_on_create;

  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      title_error: "",
      reactionevaluation_id_error: "",
      proof_id_error: "",
      owner_error: "",
      hours_error: "",
      minutes_error: "",
      categories_error: "",
      alertInfo: "",
      datePublish: new Date(new Date().setHours(0, 0, 0, 0)),
      dateUnpublish: null,
      videoSearchData: [],
      selectedOwner: this.getOwnerFromProps(props),
    };

    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_showgrid = true;
    this.start_external_view = true;
  }

  getOwnerFromProps = (props) => {
    const { course_edit, guardians } = props;
    const ownerId = course_edit ? course_edit.owner : null;

    if (ownerId) {
      const owner = guardians.find((guardian) => guardian.id === ownerId);

      if (owner) {
        return owner;
      }
    }

    return null;
  };

  handleOwnerChange = (selectedOption) => {
    this.setState({ selectedOwner: selectedOption });
  };

  onChange_datePublish = (date) => {
    const { dateUnpublish } = this.state;

    if (!date && dateUnpublish) {
      this.setState({ dateUnpublish: null });
    }

    this.setState({ datePublish: date });
  };

  onChange_dateUnpublish = (date) => {
    const { datePublish } = this.state;

    if (!datePublish && date) {
      toast.error("Informe a data de publicação", {
        autoClose: 5000,
      });
      this.setState({ dateUnpublish: null });
      return;
    }

    if (date < datePublish && date) {
      toast.error(
        "A data para despublicar não pode ser anterior a data de publicação",
        {
          autoClose: 5000,
        }
      );
      return;
    }

    this.setState({ dateUnpublish: date });
  };

  onChange = (inputValue, action, item_id) => {
    // Adicionar
    if (action.action === "select-option") {
      lms_client.mutate({
        mutation: CREATE_CATEGORY_HAS_COURSE,
        variables: {
          course_id: item_id,
          category_id: action.option.value,
        },
      });
    }
    // Remover
    else if (action.action === "remove-value") {
      lms_client.mutate({
        mutation: DELETE_CATEGORY_HAS_COURSE,
        variables: {
          course_id: item_id,
          category_id: action.removedValue.value,
        },
      });
    }
  };

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active,
    }));
  };

  handleCheckboxChange_showgrid = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_showgrid: !this.state.checkboxValue_showgrid,
    }));
  };

  handleCheckboxChange_externalView = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_externalView: !this.state.checkboxValue_externalView,
    }));
  };

  getSambaVideos = async (event) => {
    // Caso as teclas de ação (Shift, Control) sejam pressionadas, o evento de request não será acionado.
    if (event.key.length > 1 && event.key !== "Backspace") {
      return;
    }

    const videos = await requestVideosSearch(event.target.value);

    this.setState({
      videoSearchData: videos.data.map(({ id, title }) => ({
        __typename: "Video",
        label: title,
        value: id,
      })),
    });
  };

  handleSubmit = () => {
    this.setState({
      alertInfo: "Salvo com sucesso!",
    });

    setTimeout(() => {
      this.setState({
        alertInfo: "",
      });
      window.location.href = Config.baseUrl + "#/Course";
      window.scrollTo(0, 0);
    }, 2000);
  };

  handleSelectCategoriesOnCreate = (inputValue) => {
    this.categories_on_create = inputValue;
  };

  sendCategories = async (courseId, categories) => {
    // Enviar a mutação com o link do conteúdo criado
    const categoriesId = categories.map((category) => category.value);

    await categoriesId.forEach((id) => {
      lms_client.mutate({
        mutation: CREATE_CATEGORY_HAS_COURSE,
        variables: {
          course_id: courseId,
          category_id: parseInt(id),
        },
      });
    });
  };

  onCreate = async (e, createOrUpdateCourse) => {
    e.preventDefault();
    if (!this.formValidation(e)) return;
    this.setState({ is_loading: true });

    try {
      const response = await createOrUpdateCourse({
        variables: {
          id: this.course_id,
          title: e.target.elements.title.value,
          description: e.target.elements.description.value,
          video:
            e.target.elements.video && e.target.elements.video.value
              ? e.target.elements.video.value
              : "",
          active: e.target.elements.active.checked ? 1 : 0,
          showgrid: e.target.elements.showgrid.checked ? 1 : 0,
          external_view: e.target.elements.externalView.checked ? 1 : 0,
          date_publish: e.target.elements.date_publish.value,
          date_unpublish: e.target.elements.date_unpublish.value,
          reactionevaluation_id: e.target.elements.reactionevaluation_id.value
            ? parseInt(e.target.elements.reactionevaluation_id.value)
            : 0,
          proof_id: e.target.elements.proof_id.value
            ? parseInt(e.target.elements.proof_id.value)
            : 0,
          owner: e.target.elements.owner.value
            ? parseInt(e.target.elements.owner.value)
            : 0,
          hours: parseInt(e.target.elements.hours.value),
          minutes: parseInt(e.target.elements.minutes.value),
          code: e.target.elements.code.value,
        },
      });

      const courseId = response.data.createOrUpdateCourse.id;

      this.sendCategories(courseId, this.categories_on_create);
      this.sendThumbnail(courseId);

      this.handleSubmit();
    } catch (e) {
      this.setState({ is_loading: false });
      alert("Houve um erro ao enviar os dados para o servidor");
    }
  };

  onEdit = async (e, createOrUpdateCourse) => {
    e.preventDefault();

    this.setState({ is_loading: true });

    try {
      createOrUpdateCourse({
        variables: {
          id: this.course_id,
          title: e.target.elements.title.value,
          description: e.target.elements.description.value,
          video:
            e.target.elements.video && e.target.elements.video.value
              ? e.target.elements.video.value
              : "",
          active: e.target.elements.active.checked ? 1 : 0,
          showgrid: e.target.elements.showgrid.checked ? 1 : 0,
          external_view: e.target.elements.externalView.checked ? 1 : 0,
          date_publish: e.target.elements.date_publish.value,
          date_unpublish: e.target.elements.date_unpublish.value,
          reactionevaluation_id: e.target.elements.reactionevaluation_id.value
            ? parseInt(e.target.elements.reactionevaluation_id.value)
            : 0,
          proof_id: e.target.elements.proof_id.value
            ? parseInt(e.target.elements.proof_id.value)
            : 0,
          owner: e.target.elements.owner.value
            ? parseInt(e.target.elements.owner.value)
            : 0,
          hours: parseInt(e.target.elements.hours.value),
          minutes: parseInt(e.target.elements.minutes.value),
          code: e.target.elements.code.value,
        },
      });

      this.handleSubmit();
    } catch (e) {
      this.setState({ is_loading: false });
      alert("Houve um erro ao atualizar o conteúdo");
    }
  };

  handleSelectThumbnailOnCreate = (e) => {
    this.thumbnail_on_create = e.currentTarget.files[0];
  };

  sendThumbnail = (courseId) => {
    // Enviar a thumbnail com o link do conteúdo criado
    if (!this.thumbnail_on_create) return;

    const formData = new FormData();
    formData.append("id", courseId);
    formData.append("file", this.thumbnail_on_create);

    const request = new XMLHttpRequest();
    request.open("POST", Config.baseUrl + "/api/v1/lms/course_thumb");
    request.send(formData);
  };

  formValidation = (e) => {
    this.setState({
      title_error: "",
      reactionevaluation_id_error: "",
      proof_id_error: "",
      owner_error: "",
      hours_error: "",
      minutes_error: "",
      categories_error: "",
      datepublish_error: "",
      dateunpublish_error: "",
    });

    if (!e.target.elements.title.value) {
      const titleInput = e.target.elements.title;
      titleInput.focus();
      this.setState({ title_error: "Digite um título" });
    }

    if (!e.target.elements.reactionevaluation_id.value) {
      const reactionevaluationIdInput = e.target.elements.reactionevaluation_id;
      reactionevaluationIdInput.focus();
      this.setState({
        reactionevaluation_id_error: "Escolha uma avaliação de reação",
      });
    }

    if (!e.target.elements.proof_id.value) {
      const proofIdInput = e.target.elements.proof_id;
      proofIdInput.focus();
      this.setState({
        proof_id_error: "Escolha uma prova",
      });
    }

    if (!e.target.elements.owner.value) {
      const ownerInput = e.target.elements.owner;
      ownerInput.focus();
      this.setState({
        owner_error: "Escolha a pessoa responsável",
      });
    }

    if (!e.target.elements.hours.value) {
      const hoursInput = e.target.elements.hours;
      hoursInput.focus();
      this.setState({
        hours_error: "Digite a carga horária (horas)",
      });
    }

    //     minutes: parseInt(e.target.elements.minutes.value),
    if (!e.target.elements.minutes.value) {
      const minutesInput = e.target.elements.minutes;
      minutesInput.focus();
      this.setState({
        minutes_error: "Digite a carga horária (minutos)",
      });
    }

    if (!(!!this.categories_on_create && !!this.categories_on_create.length)) {
      const categoriesInput = e.target.elements.categories;
      categoriesInput.focus();
      this.setState({ categories_error: "Selecione pelo menos uma categoria" });
    }

    if (
      !e.target.elements.title.value ||
      !e.target.elements.reactionevaluation_id.value ||
      !e.target.elements.proof_id.value ||
      !e.target.elements.owner.value ||
      !e.target.elements.hours.value ||
      !e.target.elements.minutes.value ||
      !(!!this.categories_on_create && !!this.categories_on_create.length)
    )
      return false;

    return true;
  };

  mergeData = (data1, data2) => {
    return data1.concat(data2);
  };

  render() {
    if (this.props.match.params.id) {
      this.course_id = parseInt(this.props.match.params.id);
    } else {
      this.course_id = 0;
    }

    const { selectedOwner } = this.state;
    const { guardians } = this.props;

    return (
      <Query
        client={lms_client}
        query={GET_COURSE}
        variables={{ course_id: this.course_id }}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.course_edit = data.course;

          if (
            this.start_publish_date &&
            this.course_edit &&
            this.course_edit.date_publish_format
          ) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.course_edit.date_publish_format),
            });
          }

          if (
            this.start_unpublish_date &&
            this.course_edit &&
            this.course_edit.date_unpublish_format
          ) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.course_edit.date_unpublish_format),
            });
          }

          if (
            this.start_active &&
            this.course_edit &&
            this.course_edit.active
          ) {
            this.start_active = false;
            this.setState({
              checkboxValue_active:
                this.course_edit.active === 1 ? true : false,
            });
          }

          if (
            this.start_showgrid &&
            this.course_edit &&
            this.course_edit.showgrid
          ) {
            this.start_showgrid = false;
            this.setState({
              checkboxValue_showgrid:
                this.course_edit.showgrid === 1 ? true : false,
            });
          }

          if (
            this.start_external_view &&
            this.course_edit &&
            this.course_edit.external_view
          ) {
            this.start_external_view = false;
            this.setState({
              checkboxValue_externalView:
                this.course_edit.external_view === 1 ? true : false,
            });
          }

          return (
            <Mutation mutation={ADD_TODO} client={lms_client}>
              {(createOrUpdateCourse, { data }) => (
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div className="btn-top-container">
                          <NavLink
                            to="/Course"
                            className="btn btn-info btn-fill"
                            activeClassName=""
                          >
                            VOLTAR
                          </NavLink>

                          <NotificationSystem
                            ref="notificationSystem"
                            style={style}
                          />
                        </div>

                        <Card
                          title="Curso"
                          content={
                            <form
                              onSubmit={(e) => {
                                if (this.course_edit && this.course_edit.id) {
                                  return this.onEdit(e, createOrUpdateCourse);
                                }

                                return this.onCreate(e, createOrUpdateCourse);
                              }}
                            >
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "title",
                                    label: "Título",
                                    maxLength: 2048,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Título",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.title
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.title_error && (
                                <p className="inputError">
                                  {this.state.title_error}
                                </p>
                              )}
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "description",
                                    label: "Descrição",
                                    maxLength: 1024,
                                    type: "text",
                                    bsClass: "form-control",
                                    placeholder: "Descrição",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.description
                                      : "",
                                  },
                                ]}
                              />
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "hours",
                                    label: "Carga horária (hrs)",
                                    maxLength: 3,
                                    type: "number",
                                    bsClass: "form-control",
                                    placeholder: "Carga horária (hrs)",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.hours
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.hours_error && (
                                <p className="inputError">
                                  {this.state.hours_error}
                                </p>
                              )}
                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "minutes",
                                    maxLength: 2,
                                    label: "Carga horária (min)",
                                    type: "number",
                                    bsClass: "form-control",
                                    placeholder: "Carga horária (min)",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.minutes
                                      : "",
                                  },
                                ]}
                              />
                              {this.state.minutes_error && (
                                <p className="inputError">
                                  {this.state.minutes_error}
                                </p>
                              )}

                              <FormGroup>
                                <ControlLabel>Responsável</ControlLabel>
                                <div>
                                  <Query
                                    variables={{ id: 15 }}
                                    client={account_client}
                                    query={GET_GUARDIANS}
                                  >
                                    {({
                                      loading: loading1,
                                      error: error1,
                                      data: data1,
                                    }) => (
                                      <Query
                                        variables={{ id: 1 }}
                                        client={account_client}
                                        query={GET_GUARDIANS}
                                      >
                                        {({
                                          loading: loading2,
                                          error: error2,
                                          data: data2,
                                        }) => {
                                          if (loading1 || loading2)
                                            return <div>Carregando...</div>;
                                          if (error1 || error2)
                                            return <div>Erro :(</div>;

                                          const guardians1 =
                                            data1.get_users_by_profile.users;
                                          const guardians2 =
                                            data2.get_users_by_profile.users;
                                          const allGuardians = this.mergeData(
                                            guardians1,
                                            guardians2
                                          );

                                          // Verifica se o this.course_edit.owner possui um valor válido
                                          const ownerValue =
                                            this.course_edit &&
                                            this.course_edit.owner
                                              ? this.course_edit.owner
                                              : null;

                                          // Se houver um ownerValue, procura o nome correspondente
                                          const ownerName =
                                            ownerValue &&
                                            allGuardians.find(
                                              (guardian) =>
                                                guardian.id === ownerValue
                                            );

                                          return (
                                            <>
                                              <Select
                                                name="owner"
                                                options={allGuardians.map(
                                                  (guardian) => ({
                                                    value: guardian.id,
                                                    label: guardian.name,
                                                  })
                                                )}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                isClearable={
                                                  this.state.selectedOwner
                                                    ? true
                                                    : false
                                                }
                                                value={
                                                  this.state.selectedOwner
                                                    ? this.state.selectedOwner
                                                    : ownerValue
                                                    ? {
                                                        value: ownerValue,
                                                        label: ownerName.name,
                                                      }
                                                    : null
                                                }
                                                onChange={
                                                  this.handleOwnerChange
                                                }
                                              />
                                              {this.state.owner_error && (
                                                <p className="inputError">
                                                  {this.state.owner_error}
                                                </p>
                                              )}
                                            </>
                                          );
                                        }}
                                      </Query>
                                    )}
                                  </Query>
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>Vídeo</ControlLabel>
                                <div>
                                  <Select
                                    name="video"
                                    options={this.state.videoSearchData}
                                    className="basic-select"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    defaultValue={
                                      this.course_edit && this.course_edit
                                        ? this.course_edit.video
                                        : ""
                                    }
                                    onKeyDown={(event) =>
                                      this.getSambaVideos(event)
                                    }
                                  />
                                </div>
                              </FormGroup>

                              <FormInputs
                                ncols={["col-md-12"]}
                                proprieties={[
                                  {
                                    name: "code",
                                    label: "Código do curso",
                                    type: "text",
                                    maxLength: 30,
                                    bsClass: "form-control",
                                    placeholder: "Código do curso",
                                    defaultValue: this.course_edit
                                      ? this.course_edit.code
                                      : "",
                                  },
                                ]}
                              />

                              <FormGroup>
                                <ControlLabel>Prova</ControlLabel>
                                <div>
                                  <Query
                                    variables={{ type_id: 1 }} // 1 é o ID da prova no microseviço de avalações
                                    client={lms_client}
                                    query={GET_PROOFS}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                      var proofs = data;
                                      return (
                                        <>
                                          <Select
                                            name="proof_id"
                                            options={
                                              proofs.proof_collections
                                                ? proofs.proof_collections
                                                    .proofs
                                                : []
                                            }
                                            className="basic-select"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            defaultValue={
                                              this.course_edit &&
                                              this.course_edit.proof
                                                ? this.course_edit.proof
                                                : ""
                                            }
                                          />
                                          {this.state.proof_id_error && (
                                            <p className="inputError">
                                              {this.state.proof_id_error}
                                            </p>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Query>
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>Avaliação de reação</ControlLabel>
                                <div>
                                  <Query
                                    variables={{ type_id: 2 }} // 2 é o ID da avaliçaão de reação no microseviço de avalações
                                    client={lms_client}
                                    query={GET_PROOFS}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return <div>Carregando...</div>;
                                      if (error) return <div>Erro :(</div>;
                                      var proofs = data;
                                      return (
                                        <>
                                          <Select
                                            name="reactionevaluation_id"
                                            options={
                                              proofs.proof_collections
                                                ? proofs.proof_collections
                                                    .proofs
                                                : []
                                            }
                                            className="basic-select"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            defaultValue={
                                              this.course_edit &&
                                              this.course_edit
                                                .reactionevaluation
                                                ? this.course_edit
                                                    .reactionevaluation
                                                : ""
                                            }
                                          />
                                          {this.state
                                            .reactionevaluation_id_error && (
                                            <p className="inputError">
                                              {
                                                this.state
                                                  .reactionevaluation_id_error
                                              }
                                            </p>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Query>
                                </div>
                              </FormGroup>

                              <hr />

                              <FormGroup>
                                <ControlLabel>Categorias</ControlLabel>

                                <div>
                                  <Query
                                    client={dam_client}
                                    query={GET_CATEGORIES}
                                  >
                                    {({ loading, error, data }) => {
                                      if (loading)
                                        return (
                                          <tr>
                                            <td>Carregando...</td>
                                          </tr>
                                        );
                                      if (error)
                                        return (
                                          <tr>
                                            <td>Erro :(</td>
                                          </tr>
                                        );

                                      var categories =
                                        data.category_collections.categories;

                                      if (
                                        this.course_edit &&
                                        this.course_edit.id
                                      ) {
                                        return (
                                          <Query
                                            client={lms_client}
                                            query={GET_CATEGORY_HAS_COURSE}
                                            variables={{
                                              course_id: this.course_edit.id,
                                            }}
                                            fetchPolicy="no-cache"
                                          >
                                            {({ loading, error, data }) => {
                                              if (loading)
                                                return <div>Carregando...</div>;
                                              if (error)
                                                return <div>Erro :(</div>;

                                              var categories_has_course_collection_default =
                                                [];
                                              var categories_has_course_collection =
                                                data
                                                  .categoryHasCourse_collection
                                                  .category_has_course;
                                              categories.map(
                                                (category_item) => {
                                                  categories_has_course_collection.map(
                                                    (
                                                      categories_has_course_item
                                                    ) => {
                                                      if (
                                                        category_item.value ===
                                                        categories_has_course_item.category_id
                                                      ) {
                                                        categories_has_course_collection_default.push(
                                                          category_item
                                                        );
                                                      }
                                                    }
                                                  );
                                                }
                                              );

                                              return (
                                                <>
                                                  <Select
                                                    defaultValue={
                                                      categories_has_course_collection_default
                                                    }
                                                    isMulti
                                                    isSearchable
                                                    name="categories"
                                                    options={categories}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(
                                                      inputValue,
                                                      action
                                                    ) =>
                                                      this.onChange(
                                                        inputValue,
                                                        action,
                                                        this.course_edit.id
                                                      )
                                                    }
                                                    isClearable={true}
                                                  />
                                                  {this.state
                                                    .categories_error && (
                                                    <p className="inputError">
                                                      {
                                                        this.state
                                                          .categories_error
                                                      }
                                                    </p>
                                                  )}
                                                </>
                                              );
                                            }}
                                          </Query>
                                        );
                                      }
                                      return (
                                        <>
                                          <Select
                                            isMulti
                                            isSearchable
                                            name="categories"
                                            options={categories}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={
                                              this
                                                .handleSelectCategoriesOnCreate
                                            }
                                            isClearable={true}
                                          />
                                          {this.state.categories_error && (
                                            <p className="inputError">
                                              {this.state.categories_error}
                                            </p>
                                          )}
                                        </>
                                      );
                                    }}
                                  </Query>
                                </div>
                              </FormGroup>

                              <hr />
                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="active"
                                    type="checkbox"
                                    checked={this.state.checkboxValue_active}
                                    onChange={this.handleCheckboxChange}
                                  />{" "}
                                  Ativo
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="showgrid"
                                    type="checkbox"
                                    checked={this.state.checkboxValue_showgrid}
                                    onChange={
                                      this.handleCheckboxChange_showgrid
                                    }
                                  />{" "}
                                  Exibir fora das trilhas
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  <input
                                    value="1"
                                    name="externalView"
                                    type="checkbox"
                                    checked={this.state.checkboxValue_externalView}
                                    onChange={
                                      this.handleCheckboxChange_externalView
                                    }
                                  />{" "}
                                  Exibir em nova janela
                                </ControlLabel>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>Data de publicação</ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_publish"
                                    onChange={this.onChange_datePublish}
                                    value={this.state.datePublish}
                                    required={true}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>

                              <FormGroup>
                                <ControlLabel>
                                  Data para despublicar
                                </ControlLabel>
                                <div>
                                  <DateTimePicker
                                    name="date_unpublish"
                                    onChange={this.onChange_dateUnpublish}
                                    value={this.state.dateUnpublish}
                                    required={false}
                                    format="dd/MM/y HH:mm"
                                    lang="pt"
                                  />
                                </div>
                              </FormGroup>

                              <Row>
                                <Col md={10}>
                                  <AlertInfo
                                    ref="alertSave"
                                    alertInfo={this.state.alertInfo}
                                  />
                                </Col>
                                <Col md={2}>
                                  <Button
                                    bsStyle="info"
                                    pullRight
                                    fill
                                    type="submit"
                                    disabled={this.state.is_loading}
                                  >
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          }
                        />

                        <Card
                          title="Thumbnail"
                          content={
                            this.course_edit ? (
                              <form
                                action={
                                  Config.baseUrl + "/api/v1/lms/course_thumb"
                                }
                                method="post"
                                enctype="multipart/form-data"
                              >
                                <input
                                  type="hidden"
                                  name="returnUrl"
                                  value={
                                    "/#/Course-edit/" + this.course_edit.id
                                  }
                                />
                                <input
                                  type="hidden"
                                  name="id"
                                  value={this.course_edit.id}
                                />

                                <input type="file" name="file" required />

                                <Row>
                                  <Col md={2}>
                                    <Button
                                      bsStyle="info"
                                      pullRight
                                      fill
                                      type="submit"
                                    >
                                      Upload
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="clearfix" />
                              </form>
                            ) : (
                              <input
                                type="file"
                                name="file"
                                accept="image/*"
                                required
                                onChange={this.handleSelectThumbnailOnCreate}
                              />
                            )
                          }
                        />

                        <Card
                          title=""
                          content={
                            this.course_edit && this.course_edit.thumbnail ? (
                              <div>
                                <img
                                  alt="thumb"
                                  src={
                                    Config.baseUrl +
                                    "/lms/assets/" +
                                    this.course_edit.thumbnail
                                  }
                                  height="100px"
                                />
                              </div>
                            ) : (
                              <div />
                            )
                          }
                        />
                      </Col>
                    </Row>
                    {window.location.hash.includes("edit") ? (
                      <UpdateInfo content={this.course_edit} />
                    ) : (
                      <></>
                    )}
                  </Grid>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default CourseItem;
