import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { Query } from "react-apollo";
import { RedirectURL } from "../../variables/RedirectURL";
import HttpLinkServices from "../../services/HttpLinkServices";
import Paginator from "../../components/Paginator";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Card from "../../components/Card/Card.jsx";
import gql from "graphql-tag";
import "react-confirm-alert/src/react-confirm-alert.css";
import { NavLink } from "react-router-dom";

const user_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const urlToPath = RedirectURL(window.location.host);

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}

const GET_AUTHENTICATED_USER = gql`
  {
    get_authenticated_user {
      id
      name
      profile {
        id
        is_admin
        is_superadmin
      }
    }
  }
`;

const GET_USERS = gql`
  query users_collections(
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    users_collections(
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      users {
        id
        name
        token
        profile {
          title
        }
      }
    }
  }
`;

export default function UserList() {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("id");
  const [order, setOrder] = useState("DESC");
  const [totalUsers, setTotalUsers] = useState(0);

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  function countUsers() {
    user_client
      .query({
        query: gql`
          query countUsers($search: String!) {
            users_count(search: $search) {
              count
            }
          }
        `,
        variables: {
          search: search,
        },
      })
      .then((result) => setTotalUsers(result.data.users_count.count));
  }

  useEffect(() => {
    countUsers();
  }, [search]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Query client={user_client} query={GET_AUTHENTICATED_USER}>
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Error...</div>;
                const {
                  get_authenticated_user: {
                    profile: { is_superadmin, is_admin, id },
                  },
                } = data;

                const isButtonEnabled =
                  is_superadmin || (is_admin === 1 && (id === 21 || id === 15));

                return (
                  <div className="btn-top-container">
                    <NavLink
                      style={{ display: isButtonEnabled ? "block" : "none" }}
                      to={"/User-create-one"}
                      className="btn btn-info btn-fill"
                      activeClassName=""
                    >
                      Novo usuário
                    </NavLink>
                  </div>
                );
              }}
            </Query>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card
              name={"Usuários"}
              user="Gerenciar usuários"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Buscar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="id">ID</option>
                                <option
                                  value="name"
                                  selected={orderby == "name"}
                                >
                                  Nome
                                </option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                              <select name="order" className="form-control">
                                <option value="DESC">Decrescente</option>
                                <option value="ASC" selected={order == "ASC"}>
                                  Crescente
                                </option>
                              </select>
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Ordenar
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Perfil</th>
                      <th style={{ textAlign: "right" }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Query
                      client={user_client}
                      query={GET_USERS}
                      variables={{
                        search: search,
                        limit: limit,
                        page: page,
                        order: order,
                        orderby: orderby,
                      }}
                      fetchPolicy="no-cache"
                    >
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <tr>
                              <td>Carregando...</td>
                            </tr>
                          );
                        if (error)
                          return (
                            <tr>
                              <td>Erro :(</td>
                            </tr>
                          );

                        if (!data.users_collections) {
                          return (
                            <tr>
                              <td>(Vazio)</td>
                            </tr>
                          );
                        }

                        return data.users_collections.users.map((item) => (
                          <tr key={item.id}>
                            <td className="tablelist-id">{item.id}</td>
                            <td className="tablelist-desc">{item.name}</td>
                            <td>{item.profile ? item.profile.title : ""}</td>
                            <td className="buttons-list">
                              <a title="Editar usuário" href={getUrl('User-edit',item.id)}>
                                <i className="pe-7s-note"></i>
                              </a>
                              <a title="Segmentações do usuário" href={getUrl('segmentation', "User",item.id)}>
                                <i className="pe-7s-network"></i>
                              </a>
                              <a title="Entrar com esse usuário" target="_blank"
                                rel="noopener noreferrer"
                                href={`${urlToPath}/api/v1/account/authtoken?token=${item.token}`}>
                                <i className="pe-7s-id"></i>
                              </a>
                            </td>
                          </tr>
                        ));
                      }}
                    </Query>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalUsers / limit)}
              onChangePage={(page) => setPage(page)}
              siblingCount={2}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
