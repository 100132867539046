import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../../../components/Card/Card";
import { FaDownload } from 'react-icons/fa';
import config from '../../../config/config';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Button from "../../../components/CustomButton/CustomButton.jsx";
import '../styles.css';

export default function Download() {
  const [reports, setReports] = useState([]);
  const currentDate = new Date();

  const loadReports = useCallback(() => {
    fetch(`${config.baseUrl}/api/v1/dam/buscar_relatorios_existentes?name=report_download`)
      .then(response => response.json())
      .then(response => setReports(Object.values(response).reverse()));
  }, []);

  const handleDownloadReport = useCallback((month, year) => {
    const url = `${config.baseUrl}/api/v1/dam/downloadRelatoriosServer?name=report_download&month=${month < 10 ? `0${month}` : month}&year=${year}`;
    fetch(url)
      .then(response => {
        if (response.status === 200) {
          window.location.href = url;
        }
      });
  }, []);

  const handleDownloadReportByPeriod = useCallback((period, year) => {
    const url = `${config.baseUrl}/api/v1/dam/downloadRelatoriosServer?name=report_download&period=${period}&year=${year}`;
    fetch(url)
      .then(response => {
        if (response.status === 200) {
          window.location.href = url;
        }
      });
  }, []);

  useEffect(() => {
    loadReports();
  }, []);

  const currentFormattedDate = useMemo(() => {
    return format(currentDate, "MMMM 'de' yyyy", {
      locale: ptBR,
    });
  }, [currentDate]);

  const currentFormattedPeriod = useMemo(() => {
    return format(currentDate, "01'/'MM'/'yyyy '-' dd'/'MM'/'yyyy", {
      locale: ptBR,
    });
  }, [currentDate]);

  const months = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              className="relatorio-card"
              name={"Relatórios"}
              user="Relatório de Download de Conteúdos Digitais"
              ctTableFullWidth
              ctTableResponsive
              content={
                <>
                  <header className="relatorio-header">
                    <h3>Relatório de Download de Conteúdos Digitais</h3>

                    <h3>{currentFormattedDate}</h3>
                    <span>{currentFormattedPeriod}</span>
                    <Button onClick={() => handleDownloadReport(currentDate.getMonth() + 1, currentDate.getFullYear())} className="btn btn-info btn-fill relatorio-button ">
                      <FaDownload />
                      Baixar relatório
                    </Button>
                  </header>
                  <div className="relatorio-content">
                    <div className="relatorio-title-container">
                      <h3>Por período</h3>
                    </div>

                    {reports.map(report => {
                      return (
                        <div className="report-period">
                          <h3>{report.year}</h3>
                          <Button 
                            className="btn period-month-button btn-info"
                            onClick={() => handleDownloadReportByPeriod('anual', report.year)}
                          >
                          <FaDownload />
                            Anual
                          </Button>
                          <div className="period-month-container">
                            {months.map((monthName, index) => {
                              const isEmptyData = months
                              .find((month) => month === monthName)
                              && Number(report.year) < 2023;
                              return (
                                <Button
                                  onClick={() => handleDownloadReport(index + 1, report.year)}
                                  className={(index + 1 < currentDate.getMonth() + 1
                                    && report.year <= currentDate.getFullYear())
                                    && !isEmptyData
                                    || report.year < currentDate.getFullYear()&& !isEmptyData
                                  ? 'btn period-month-button btn-info' 
                                  : 'btn period-month-button'}
                                  disabled={index + 1 >= currentDate.getMonth() + 1 && report.year >= currentDate.getFullYear() || isEmptyData ? true : false}
                                >
                                  <FaDownload />
                                  {monthName}
                                </Button>
                              );
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
