import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";

import DateTimePicker from 'react-datetime-picker';

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from 'react-select';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToRaw, convertFromRaw, EditorState, ContentState, convertFromHTML  } from 'draft-js';

import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';
import HttpLinkServices from "../../services/HttpLinkServices";
import Config from "../../config/config";

const chat_client = new ApolloClient({
  link: HttpLinkServices.chat,
  cache: new InMemoryCache(),
});

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});


const ADD_TODO = gql`
  mutation createOrUpdateChat($id: Int!, $title: String!,  $short_description: String!,  $canceled_description: String!, $description: String!, $scope: String!, $level: String!, $externallink: String!, $date_publish: String!, $date_unpublish: String!, $datetime_start: String!, $datetime_end: String!, $liveid: String!, $active: Int!, $canceled: Int!, $moderation: Int!, $reactionevaluation_id: Int!, $quiz_id: Int!, $responsible: String!, $area: String!, $room: String!){
    createOrUpdateChat(id:$id, input:{title:$title, short_description:$short_description, canceled_description:$canceled_description, description:$description,scope:$scope,level:$level,externallink:$externallink,date_publish:$date_publish,date_unpublish:$date_unpublish,datetime_start:$datetime_start,datetime_end:$datetime_end,liveid:$liveid,active:$active,canceled:$canceled,moderation:$moderation, reactionevaluation_id:$reactionevaluation_id, quiz_id:$quiz_id, responsible:$responsible, area:$area, room:$room}){
      id
    }
  }
`;

const GET_CHAT = gql`
  query getCha($chat_id: Int!){
    chat(id:$chat_id){
      id,
      title,
      short_description,
      canceled_description,
      description,
      scope,
      level,
      externallink,
      date_publish_format,
      date_unpublish_format,
      datetime_start_format,
      datetime_end_format,
      liveid,
      moderation,
      active,
      canceled,
      reactionevaluation_id,
      quiz_id,
      thumbnail,
      background,
      responsible,
      area,
      room
    }
  }
`;

const GET_PROOFS = gql`query getProofs($type_id: Int!){
  proof_collections(type_id: $type_id){
    proofs{
      value:id, label:title
    }
  }
}
`;

const GET_PROOF = gql`
  query getProof($proof_id: Int!){
    proof(id:$proof_id){
      value:id, label:title
    }
  }
`;

class ChatItem extends Component {

  chat_id;
  chat_edit;

  state = {
    alertInfo: "",
    checkboxValue_active: false,
    checkboxValue_canceled: false,
    checkboxValue_moderation: false,
    datePublish: new Date(),
    dateUnpublish: null,
    editorState: undefined
  };

  constructor() {
    super();
    this.start_publish_date = true;
    this.start_unpublish_date = true;

    this.start_datetime_start = true;
    this.start_datetime_end = true;
    
    this.start_active = true;
    this.start_canceled = true;
    this.start_moderation = true;

    this.start_description = true;
    
  }

  

  handleSubmit = (event) => {
    event.preventDefault();
    

    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }


  
  onChange_datePublish = datePublish => this.setState({ datePublish })
  onChange_dateUnpublish = dateUnpublish => this.setState({ dateUnpublish })

  onChange_datetimeStart = datetimeStart => this.setState({ datetimeStart })
  onChange_datetimeEnd = datetimeEnd => this.setState({ datetimeEnd })

  handleCheckboxChange = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active
    }));

  }

  handleCheckboxChange_canceled = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_canceled: !this.state.checkboxValue_canceled
    }));

  }

  handleCheckboxChange_moderation = (e) => {
    this.setState(state => ({
      ...state,
      checkboxValue_moderation: !this.state.checkboxValue_moderation
    }));

  }


  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  
  render() {

    
    if(this.props.match.params.id) {
      this.chat_id = this.props.match.params.id;
    } else {
      this.chat_id = 0;
    }

    return (

      <Query
      client={chat_client}
      fetchPolicy='no-cache'
      query={GET_CHAT}
      variables={{chat_id:this.chat_id}}
      >
      {({ loading, error, data }) => {
        if (loading) return <div>Carregando...</div>;
        if (error) return <div>Erro :(</div>;
          
          this.chat_edit = data.chat;

          if(this.chat_edit && this.start_description) {
            this.start_description = false;
            const contentState = convertFromRaw(markdownToDraft(this.chat_edit.description));
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              editorState: editorState
            })
          }
          if(this.start_active && this.chat_edit && this.chat_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: (this.chat_edit.active === 1 ? true : false)
            })
          }
          if(this.start_canceled && this.chat_edit && this.chat_edit.canceled) {
            this.start_canceled = false;
            this.setState({
              checkboxValue_canceled: (this.chat_edit.canceled === 1 ? true : false)
            })
          }
          if(this.start_moderation && this.chat_edit && this.chat_edit.moderation) {
            this.start_moderation = false;
            this.setState({
              checkboxValue_moderation: (this.chat_edit.moderation === 1 ? true : false)
            })
          }

          if(this.start_publish_date && this.chat_edit && this.chat_edit.date_publish_format) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.chat_edit.date_publish_format)
            })
          }
          if(this.start_unpublish_date && this.chat_edit && this.chat_edit.date_unpublish_format) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.chat_edit.date_unpublish_format)
            })
          }

          if(this.start_datetime_start && this.chat_edit && this.chat_edit.datetime_start_format) {
            this.start_datetime_start = false;
            this.setState({
              datetimeStart: new Date(this.chat_edit.datetime_start_format)
            })
          }
          if(this.start_datetime_end && this.chat_edit && this.chat_edit.datetime_end_format) {
            this.start_datetime_end = false;
            this.setState({
              datetimeEnd: new Date(this.chat_edit.datetime_end_format)
            })
          }

        return (

      <Mutation mutation={ADD_TODO} client={chat_client}>
      {(createOrUpdateChat, { data }) => (
      <div className="content">
        {this.chat_edit &&
          <Grid fluid>
            <Row>
              <Col md={12}>
              <Card
                title="URLs para compartilhamento"
                content={
                  <div>
                    <div>
                      <label>URL visitante:</label>
                      <input type="text" className="form-control" value={"https://salavirtual.sambalms.com.br/#/live/"+this.chat_edit.id} />
                    </div>
                    <hr />
                    <div>
                      <label>URL corretor:</label>
                      <input type="text" className="form-control" value={"https://www.portoseguroeducacao.com.br//#/live/"+this.chat_edit.id} />
                    </div>
                  </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        }
        <Grid fluid>
          <Row>
            <Col md={12}>

              <div className="btn-top-container">
                <a
                    href='javascript:history.go(-1)'
                    className="btn btn-info btn-fill"
                  >
                  VOLTAR
                </a>
                
                <NotificationSystem ref="notificationSystem" style={style} />
              </div>
            
              <Card
                title="Sala Virtual"
                content={
                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                    createOrUpdateChat({variables: { 
                      id: (data && data.createOrUpdateChat && data.createOrUpdateChat.id ? data.createOrUpdateChat.id : this.chat_id), // Verifica se existe o ID de retorno da inserção, no cso de criar um novo item ele pega o ID do item que acabou de ser inserido
                      title: e.target.elements.title.value, 
                      short_description: e.target.elements.short_description.value, 
                      canceled_description: e.target.elements.canceled_description.value, 
                      description: e.target.elements.description.value,
                      scope: e.target.elements.scope.value,
                      level: e.target.elements.level.value,
                      externallink: e.target.elements.externallink.value,
                      moderation: (e.target.elements.moderation.checked ? 1 : 0),
                      active: (e.target.elements.active.checked ? 1 : 0),
                      canceled: (e.target.elements.canceled.checked ? 1 : 0),
                      date_publish: e.target.elements.date_publish.value,
                      date_unpublish: e.target.elements.date_unpublish.value,
                      datetime_start: e.target.elements.datetime_start.value,
                      datetime_end: e.target.elements.datetime_end.value,
                      liveid: e.target.elements.liveid.value,
                      reactionevaluation_id: (e.target.elements.reactionevaluation_id.value ? e.target.elements.reactionevaluation_id.value : 0),
                      quiz_id: (e.target.elements.quiz_id.value ? e.target.elements.quiz_id.value : 0),
                      responsible: e.target.elements.responsible.value,
                      area: e.target.elements.area.value,
                      room: e.target.elements.room.value,
                    } });
                  }}
                  >

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "title",
                        label: "Título",
                        maxLength: 80,
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Título",
                        defaultValue: (this.chat_edit ? this.chat_edit.title : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "short_description",
                        label: "Descrição curta",
                        maxLength: 255,
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Descrição curta",
                        defaultValue: (this.chat_edit ? this.chat_edit.short_description : "")
                      }
                    ]}
                  />

                  <div class="form-group">
                    <label class="control-label">Descrição</label>
                    <Editor
                      editorState={this.state.editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={this.onEditorStateChange}
                    />
                    <textarea
                      disabled
                      hidden={true}
                      name="description"
                      maxLength="255"
                      value={this.state.editorState && draftToMarkdown(convertToRaw(this.state.editorState.getCurrentContent()))}
                    />
                  </div>

                    
                  
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "scope",
                        label: "Abrangência",
                        type: "text",
                        maxLength: 80,
                        bsClass: "form-control",
                        placeholder: "Abrangência",
                        defaultValue: (this.chat_edit ? this.chat_edit.scope : "")
                      }
                    ]}
                  />

                  <div class="form-group">
                    <label class="control-label">Nível da Ação</label>
                    <Select
                    name="level"
                    options={[{'label':'Segmentado', 'value':'Segmentado'},  {'label':'Funcionários', 'value':'Funcionários'}, {'label':'Corretores', 'value':'Corretores'}, {'label':'Comerciais', 'value':'Comerciais'}]}
                    className="basic-select"
                    classNamePrefix="select"
                    isClearable={true}
                    defaultValue={this.chat_edit ? {'label':this.chat_edit.level, 'value':this.chat_edit.level} : ""}
                  />
                  </div>
                  
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "externallink",
                        label: "Link Externo",
                        type: "url",
                        maxLength: 255,
                        bsClass: "form-control",
                        placeholder: "Descrição",
                        defaultValue: (this.chat_edit ? this.chat_edit.externallink : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "liveid",
                        label: "Embed da Live",
                        type: "text",
                        maxLength: 255,
                        bsClass: "form-control",
                        placeholder: "ID da Live",
                        defaultValue: (this.chat_edit ? this.chat_edit.liveid : "")
                      }
                    ]}
                  />

                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "responsible",
                        label: "Solicitante",
                        type: "text",
                        maxLength: 80,
                        bsClass: "form-control",
                        placeholder: "Solicitante",
                        defaultValue: (this.chat_edit ? this.chat_edit.responsible : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "area",
                        label: "Área",
                        type: "text",
                        maxLength: 80,
                        bsClass: "form-control",
                        placeholder: "Área",
                        defaultValue: (this.chat_edit ? this.chat_edit.area : "")
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    proprieties={[
                      {
                        name: "room",
                        label: "Local",
                        maxLength: 40,
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "Local",
                        defaultValue: (this.chat_edit ? this.chat_edit.room : "")
                      }
                    ]}
                  />

<FormGroup>
                    <ControlLabel>Avaliação de reação</ControlLabel>
                    <div>
                      <Query
                          variables={{type_id: 2}} // 2 é o ID da avaliçaão de reação no microseviço de avalações
                          client={lms_client}
                          query={GET_PROOFS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              var proofs = data;
                            return (
                              <Query
                                  variables={{proof_id: (this.chat_edit && this.chat_edit.reactionevaluation_id ? this.chat_edit.reactionevaluation_id : 0)}}
                                  client={lms_client}
                                  query={GET_PROOF}
                                  >
                                  {({ loading, error, data }) => {
                                    if (loading) return <div>Carregando...</div>;
                                    if (error) return <div>Erro :(</div>;
                                      var proof = data;
                                      return (
                                          <Select
                                            name="reactionevaluation_id"
                                            options={proofs.proof_collections ? proofs.proof_collections.proofs : []}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            defaultValue={proof.proof && this.chat_edit ? proof.proof : ""}
                                          />
                                      );
                                }}
                              </Query>
                          );
                        }}
                      </Query>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Quiz</ControlLabel>
                    <div>
                      <Query
                          variables={{type_id: 3}} // 3 é o ID do Quiz no microserviço de avaliações
                          client={lms_client}
                          query={GET_PROOFS}
                          >
                          {({ loading, error, data }) => {
                            if (loading) return <div>Carregando...</div>;
                            if (error) return <div>Erro :(</div>;
                              var proofs = data;
                            return (
                              <Query
                                  variables={{proof_id: (this.chat_edit && this.chat_edit.quiz_id ? this.chat_edit.quiz_id : 0)}}
                                  client={lms_client}
                                  query={GET_PROOF}
                                  >
                                  {({ loading, error, data }) => {
                                    if (loading) return <div>Carregando...</div>;
                                    if (error) return <div>Erro :(</div>;
                                      var proof = data;
                                      return (
                                          <Select
                                            name="quiz_id"
                                            options={proofs.proof_collections ? proofs.proof_collections.proofs : []}
                                            className="basic-select"
                                            classNamePrefix="select"
                                            isClearable={true}
                                            defaultValue={proof.proof && this.chat_edit ? proof.proof : ""}
                                          />
                                      );
                                }}
                              </Query>
                          );
                        }}
                      </Query>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="moderation"
                        type="checkbox"
                        checked={this.state.checkboxValue_moderation}
                        onChange={this.handleCheckboxChange_moderation}
                      /> Ativar moderação:
                    </ControlLabel>
                  </FormGroup>


                  <FormGroup>
                    <ControlLabel>Data e Horá de início</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_start"
                        onChange={this.onChange_datetimeStart}
                        value={this.state.datetimeStart}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data e Hora de fim</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="datetime_end"
                        onChange={this.onChange_datetimeEnd}
                        value={this.state.datetimeEnd}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="canceled"
                        type="checkbox"
                        checked={this.state.checkboxValue_canceled}
                        onChange={this.handleCheckboxChange_canceled}
                      /> Cancelar Sala Virtual
                    </ControlLabel>
                  </FormGroup>

                  <div class="form-group" className={this.state.checkboxValue_canceled ? '' : 'hidden'}>
                    <label class="control-label">Justificativa do cancelamento</label>
                    <textarea rows="8" className="form-control" name="canceled_description">{(this.chat_edit ? this.chat_edit.canceled_description : "")}</textarea>
                  </div>

                  <hr />
                  <FormGroup>
                    <ControlLabel>
                      <input
                        value="1"
                        name="active"
                        type="checkbox"
                        checked={this.state.checkboxValue_active}
                        onChange={this.handleCheckboxChange}
                      /> Ativo
                    </ControlLabel>
                  </FormGroup>

                  

                  <FormGroup>
                    <ControlLabel>Data de publicação</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_publish"
                        onChange={this.onChange_datePublish}
                        value={this.state.datePublish}
                        required={true}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>Data para despublicar</ControlLabel>
                    <div>
                      <DateTimePicker
                        name="date_unpublish"
                        onChange={this.onChange_dateUnpublish}
                        value={this.state.dateUnpublish}
                        required={false}
                        format='d/M/y H:m'
                        lang= 'pt'
                      />
                    </div>
                  </FormGroup>
                 
                  <Row>
                    <Col md={10}>
                      <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                    </Col>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
                }
              />

              <Card
                title={this.chat_edit ?"Thumbnail":""}
                content={this.chat_edit ?
                  <form 
                  action={Config.baseUrl + "/api/v1/chat/thumb"} method="post" encType='multipart/form-data'
                  >
                  
                  <input type="hidden" name="returnUrl" value={"/#/Chat-edit/"+this.chat_edit.id} />
                  <input type="hidden" name="id" value={this.chat_edit.id} />

                  <input
                  type="file"
                  name="file"
                  required
                  />

                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
              : <div></div>}
              />

              <Card
                title=""
                content={this.chat_edit && this.chat_edit.thumbnail ?
                  <div><img alt="thumb" src={Config.baseUrl + "/chat/assets/"+this.chat_edit.thumbnail} height="100px" /></div>
              : <div></div>}
              />
              

            <Card
                title={this.chat_edit ?"Background":""}
                content={this.chat_edit ?
                  <form 
                  action={Config.baseUrl + "/api/v1/chat/background"} method="post" encType='multipart/form-data'
                  >
                  
                  <input type="hidden" name="returnUrl" value={"/#/Chat-edit/"+this.chat_edit.id} />
                  <input type="hidden" name="id" value={this.chat_edit.id} />

                  <input
                  type="file"
                  name="file"
                  required
                  />

                  <Row>
                    <Col md={2}>
                      <Button bsStyle="info" pullRight fill type="submit">
                        Upload
                      </Button>
                    </Col>
                  </Row>
                    <div className="clearfix" />
                  </form>
              : <div></div>}
              />

              <Card
                title=""
                content={this.chat_edit && this.chat_edit.background ?
                  <div><img alt="thumb" src={Config.baseUrl + "/chat/assets/"+this.chat_edit.background} height="100px" /></div>
              : <div></div>}
              />
              
            </Col>

            
          </Row>
        </Grid>
      </div>
      )}
      </Mutation>

      );
      }}
      </Query>
    );
  }
}

export default ChatItem;
