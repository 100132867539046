function keyDictionary(key) {
    switch (key) {
      case "name":
        return "nome";
      case "susep_externalId":
        return "código susep";
      case "susep_grupo":
        return "susep grupo"
      case "phone":
        return "telefone"
      case "telefone":
        return "telefone"
      case "company":
        return "empresa"
      case "role":
        return "cargo"
      case "papel":
        return "papel"
      case "uid":
        return "user id (cpf ou matrícula)"
      default:
        return key;
    }
  }
  
  export function validationFields(data) {
    for (let key in data) {
      if (data[key] === "") {
        let keyName = keyDictionary(key);
        throw new Error(`O campo ${keyName} é obrigatório!`);
      }
    }
  }