import React from "react";
import {HashRouter, Route, Switch} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import indexRoutes from "./routes/index.jsx";

import ApolloClient from "apollo-boost";
import {ApolloProvider} from "react-apollo";
import {render} from "react-dom";
import gql from "graphql-tag";

import Config from "./config/config";

import getCookie from "./components/Cookie/Cookie.jsx";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/css/sambatech.css";

import 'react-toastify/dist/ReactToastify.css';

const account_client = new ApolloClient({
  uri: `${Config.baseUrl}/api/v1/account/`
});


function go_to_login() {
  account_client.query({
    query: gql`{
        account_auth{
            login_url
        }
      }`
  })
    .then(
      result => {
        window.location.replace(result.data.account_auth.login_url);
      }
    );
}

const auth_uri = "#/auth/";
if (window.location.hash.indexOf(auth_uri) === 0) {// Verifica se está na página de auth
  const token_user = window.location.hash.replace(auth_uri, "");
  document.cookie = "token_user=" + token_user;// Grava o coookie
  window.location.replace(window.location.protocol + "//" + window.location.host);// Envia para raiz
}

const token_user = getCookie("token_user");

if (token_user) {

  account_client.query({
    variables: {token: token_user},
    query: gql`
      query get_user($token: String!) {
        get_user_by_token(token: $token){
            id
        }
    }`
  })
  .then(
    result => {
      if(result.data.get_user_by_token) {
        const App = () => (
          <ApolloProvider client={account_client}>
            <HashRouter>
              <>
                <Switch>
                  {indexRoutes.map((prop, key) => {
                    return <Route to={prop.path} component={prop.component} key={key} />;
                  })}
                </Switch>

                <ToastContainer autoClose={3000} className="foo" />
              </>
            </HashRouter>
          </ApolloProvider>
        );

        render(<App />, document.getElementById("root"));
      } else {
        go_to_login();
      }
    }
  );
} else {
  go_to_login();
}

