import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const ADD_QUESTION = gql`
  mutation createOrUpdateQuestion($id: Int!, $title: String!, $proof_id: Int!) {
    createOrUpdateQuestion(id: $id, input: { title: $title, proof_id: $proof_id }) {
      id
    }
  }
`;

const ADD_ANSWER = gql`
  mutation createOrUpdateAnswer(
    $id: Int!
    $title: String!
    $question_id: Int!
    $is_correct: Int!
  ) {
    createOrUpdateAnswer(
      id: $id
      input: {
        title: $title
        question_id: $question_id
        is_correct: $is_correct
      }
    ) {
      id
    }
  }
`;

const GET_QUESTION = gql`
  query getQuestion($question_id: Int!) {
    question(id: $question_id) {
      title
    }
  }
`;

class QuestionNew extends Component {
  question_id;
  question_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: "",
      questionTitle: "",
      additionalInputs: [
        { id: 0, title: "", is_correct: false },
      ],
      isSaving: false,
    };

    this.start_is_correct = true;
    this.toastId = null;
  }

  handleSubmit = async (event, createOrUpdateQuestion, createOrUpdateAnswer) => {
    event.preventDefault();
  
    const { questionTitle, additionalInputs } = this.state;
  
    if (questionTitle.trim() === "") {
      if (this.toastId) {
        toast.dismiss(this.toastId);
      }
      this.toastId = toast.error("Por favor, preencha a pergunta.");
      return;
    }
  
    const isAnyInputEmpty = additionalInputs.some(
      (input) => input.title === ""
    );
  
    if (isAnyInputEmpty) {
      if (this.toastId) {
        toast.dismiss(this.toastId);
      }
      this.toastId = toast.error("Por favor, preencha todos os campos de resposta.");
      return;
    }
  
    this.setState({
      isSaving: true,
      alertInfo: "",
    });
  
    try {
      const questionResult = await createOrUpdateQuestion({
        variables: {
          id: 0,
          proof_id: parseInt(this.props.match.params.proof_id),
          title: questionTitle,
        },
      });
  
      const questionId = questionResult.data.createOrUpdateQuestion.id;
  
      for (let i = 0; i < additionalInputs.length; i++) {
        const input = additionalInputs[i];
  
        await createOrUpdateAnswer({
          variables: {
            id: 0,
            question_id: questionId,
            title: input.title,
            is_correct: input.is_correct ? 1 : 0,
          },
        });
      }
  
      this.setState({
        isSaving: false,
        alertInfo: "Salvo com sucesso!",
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isSaving: false,
        alertInfo: "Erro ao salvar os inputs.",
      });
    }
  };
  

  handleShowAdditionalInput = () => {
    const newInputId = this.state.additionalInputs.length + 1;
    this.setState((prevState) => ({
      additionalInputs: [
        ...prevState.additionalInputs,
        { id: newInputId, title: "", is_correct: false },
      ],
    }));
  };

  handleAdditionalInputChange = (id, field, e) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const additionalInputs = prevState.additionalInputs.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            [field]: value,
          };
        }
        return input;
      });
      return { additionalInputs };
    });
  };

  handleAdditionalButtonToggle = (id) => {
    this.setState((prevState) => {
      const additionalInputs = prevState.additionalInputs.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            is_correct: !input.is_correct,
          };
        }
        return input;
      });
      return { additionalInputs };
    });
  };

  handleRemoveAdditionalInput = (id) => {
    this.setState((prevState) => {
      const additionalInputs = prevState.additionalInputs.filter(
        (input) => input.id !== id
      );
      return { additionalInputs };
    });
  };

  render() {
    this.question_id = this.props.match.params.id
      ? this.props.match.params.id
      : 0;
    this.question_title = this.props.match.params.title
      ? this.props.match.params.title
      : "";

    return (
      <Query
        client={lms_client}
        query={GET_QUESTION}
        variables={{ question_id: this.question_id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.question_edit = data.question;

          if (
            this.start_is_correct &&
            this.question_edit &&
            this.question_edit.title
          ) {
            this.start_is_correct = false;
            this.setState({
              questionTitle: this.question_edit.title,
            });
          }

          return (
            <Mutation mutation={ADD_QUESTION} client={lms_client}>
              {(createOrUpdateQuestion, { loading: questionLoading }) => (
                <Mutation mutation={ADD_ANSWER} client={lms_client}>
                  {(createOrUpdateAnswer, { loading: answerLoading }) => (
                    <div className="content">
                      <Grid fluid>
                        <Row>
                          <Col md={12}>
                            <div className="btn-top-container">
                              <NavLink
                                to={`/Question/${this.props.match.params.type_id}/${this.props.match.params.proof_id}`}
                                className="btn btn-info btn-fill"
                                activeClassName=""
                              >
                                VOLTAR
                              </NavLink>
                            </div>
                            <Card
                              title="Adicionar pergunta e respostas"
                              content={
                                <form
                                  onSubmit={(e) =>
                                    this.handleSubmit(
                                      e,
                                      createOrUpdateQuestion,
                                      createOrUpdateAnswer
                                    )
                                  }
                                >
                                  <Row>
                                    <Col md={12}>
                                      <FormGroup>
                                        <ControlLabel>Descrição da pergunta</ControlLabel>
                                        <textarea
                                          className="form-control"
                                          placeholder="Descrição da pergunta"
                                          value={this.state.questionTitle}
                                          onChange={(e) =>
                                            this.setState({
                                              questionTitle: e.target.value,
                                            })
                                          }
                                          rows="2"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  {this.state.additionalInputs.map((input) => (
                                    <div key={input.id}>
                                      <Row>
                                        <div className="input-answer">
                                          <Col md={9}>
                                            <FormGroup>
                                              <ControlLabel>Descrição da resposta</ControlLabel>
                                              <textarea
                                                className="form-control"
                                                placeholder="Descrição da resposta"
                                                value={input.title}
                                                onChange={(e) =>
                                                  this.handleAdditionalInputChange(
                                                    input.id,
                                                    "title",
                                                    e
                                                  )
                                                }
                                                rows="2"
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md={2}>
                                            <FormGroup>
                                              <ControlLabel>
                                                <Button
                                                  bsStyle={
                                                    input.is_correct
                                                      ? "success"
                                                      : "default"
                                                  }
                                                  fill
                                                  onClick={() =>
                                                    this.handleAdditionalButtonToggle(
                                                      input.id
                                                    )
                                                  }
                                                >
                                                  {input.is_correct
                                                    ? "Resposta correta"
                                                    : "Resposta incorreta"}
                                                </Button>
                                              </ControlLabel>
                                            </FormGroup>
                                          </Col>
                                          <Col md={1}>
                                            <FormGroup>
                                              <ControlLabel>
                                                <Button
                                                  bsStyle="danger"
                                                  fill
                                                  onClick={() =>
                                                    this.handleRemoveAdditionalInput(
                                                      input.id
                                                    )
                                                  }
                                                >
                                                  X
                                                </Button>
                                              </ControlLabel>
                                            </FormGroup>
                                          </Col>
                                        </div>
                                      </Row>
                                      {input.id !==
                                        this.state.additionalInputs.length && (
                                        <hr />
                                      )}
                                    </div>
                                  ))}
                                  <Button
                                      bsStyle="info"
                                      onClick={this.handleShowAdditionalInput}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      Nova resposta
                                    </Button>
                                  <Row>
                                    <Col md={10}>
                                      <AlertInfo
                                        ref="alertSave"
                                        alertInfo={this.state.alertInfo}
                                      />
                                    </Col>
                                    <Col md={2}>
                                      <Button
                                        bsStyle="info"
                                        pullRight
                                        fill
                                        type="submit"
                                        disabled={questionLoading || answerLoading || this.state.isSaving}
                                      >
                                        Salvar
                                      </Button>
                                    </Col>
                                  </Row>
                                  <div className="clearfix" />
                                </form>
                              }
                            />
                          </Col>
                        </Row>
                      </Grid>
                      <NotificationSystem
                        ref={(ref) => (this.notificationSystem = ref)}
                        style={style}
                      />
                    </div>
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }

}

export default QuestionNew;