import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
} from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";

import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateQuestion($id: Int!, $title: String!, $proof_id: Int! ){
    createOrUpdateQuestion(id:$id, input:{title:$title, proof_id:$proof_id}){
      id
    }
  }
`;

const GET_QUESTION = gql`
  query getQuestion($question_id: Int!){
    question(id:$question_id){
      title
    }
  }
`;

class QuestionItem extends Component {

  question_id;
  question_edit;
  parent_id;
  back_button;

  constructor() {
    super();
    this.state = {
      alertInfo: ""
    };
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    
    this.setState({
      alertInfo: "Salvo com sucesso!"
    });

    setTimeout(() => {
      this.setState({
        alertInfo: ""
      })
    },3000);
  }

  
  render() {

    
    this.question_id = (this.props.match.params.id ? this.props.match.params.id : 0);
    this.proof_id = (this.props.match.params.parent_id ? this.props.match.params.proof_id : "");

    return (
      <Query
        client={lms_client}
        query={GET_QUESTION}
        variables={{ question_id: this.question_id }}
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.question_edit = data.question;

          return (
            <Mutation mutation={ADD_TODO} client={lms_client}>
              {(createOrUpdateQuestion, { data }) => (
                <div className="content">
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                        <div className="btn-top-container">
                          <NavLink
                            to={`/Question/${this.props.match.params.type_id}/${this.props.match.params.proof_id}`}
                            className="btn btn-info btn-fill"
                            activeClassName=""
                          >
                            VOLTAR
                          </NavLink>
                          <NotificationSystem ref="notificationSystem" style={style} />
                        </div>

                        <Card
                          title="Editar pergunta"
                          content={
                            <form
                              onSubmit={(e) => {
                                this.handleSubmit(e);
                                e.preventDefault();
                                createOrUpdateQuestion({
                                  variables: {
                                    id: this.question_id,
                                    proof_id: parseInt(this.props.match.params.proof_id),
                                    title: e.target.elements.title.value
                                  }
                                });
                              }}
                            >

                              <div className="form-group">
                                <label>Descrição da pergunta</label>
                                <textarea
                                  className="form-control"
                                  placeholder="Descrição da pergunta"
                                  defaultValue={
                                    this.question_edit ? this.question_edit.title : ""
                                  }
                                  name="title"
                                  rows="2"
                                />
                              </div>

                              <Row>
                                <Col md={10}>
                                  <AlertInfo ref="alertSave" alertInfo={this.state.alertInfo} />
                                </Col>
                                <Col md={2}>
                                  <Button bsStyle="info" pullRight fill type="submit">
                                    Salvar
                                  </Button>
                                </Col>
                              </Row>
                              <div className="clearfix" />
                            </form>
                          }
                        />
                      </Col>
                    </Row>
                  </Grid>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default QuestionItem;