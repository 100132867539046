import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import HttpLinkServices from "../../services/HttpLinkServices";
import Button from "../../components/CustomButton/CustomButton.jsx";
import swal from "sweetalert";
import 'react-confirm-alert/src/react-confirm-alert.css'; //

const answer_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

const GET_ANSWERS = gql`query getAnswers($question_id: Int!){
    answer_collections(question_id: $question_id){
      answers{
        id,title, is_correct
      }
    }
  }
`;

const DELETE_ANSWER = gql`
  mutation deleteAnswer($id: Int!){
    deleteAnswer(id:$id) {
      id
    }
  }
`;

class AnswerList extends Component {


  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={"/Question/"+this.props.match.params.type_id+"/"+this.props.match.params.proof_id}
                    className="btn btn-default btn-fill"
                    activeClassName=""
                  >
                  VOLTAR
                </NavLink>
                <NavLink
                    to={'/Answer-new/'+this.props.match.params.type_id+"/"+this.props.match.params.proof_id+"/"+this.props.match.params.question_id}
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  Nova Resposta
                </NavLink>
              </div>

              <Card
                title={"Respostas"}
                answer="Gerenciar perguntas"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Correta</th>
                        <th style={{ textAlign: "right" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          variables={{question_id: this.props.match.params.question_id}}
                          client={answer_client}
                          query={GET_ANSWERS}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.answer_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.answer_collections.answers.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td>{item.is_correct ? "Sim" : "Não"}</td>
                                <td className="buttons-list">
                                  <a title="Editar resposta" href={getUrl('Answer-edit/'+this.props.match.params.type_id+"/"+this.props.match.params.question_id+"/"+this.props.match.params.question_id,item.id)}>
                                    <i className="pe-7s-note"></i>
                                  </a>
                                  <a title="Excluir resposta" onClick={() => {
                                    swal({
                                      title: `Atenção!`,
                                      text: `Tem certeza que deseja excluir a resposta selecionada?\n\nNão será possível recuperar o registro após a exclusão`,
                                      icon: "warning",
                                      buttons: ["Cancelar", "Excluir"],
                                      className: "custom-swal",
                                      dangerMode: true,
                                    }).then((confirm) => {
                                      if (confirm) {
                                        answer_client.mutate({
                                          mutation: DELETE_ANSWER,
                                          variables: {
                                            id: item.id,
                                          },
                                        }).then((response) => {
                                          if (response.data.errors) {
                                            swal(
                                              "Não foi possível excluir o item por existirem registros filhos",
                                              {
                                                icon: "error",
                                              }
                                            );
                                          } else {
                                            swal(
                                              "Exclusão concluída com sucesso!",
                                              {
                                                icon: "success",
                                              }
                                            ).then(() => {
                                              window.location.reload();
                                            });
                                          }
                                        })
                                        .catch(() => {
                                          swal("Não foi possível excluir o item por existirem registros filhos", {
                                            icon: "error",
                                          });
                                        });
                                      }
                                    });
                                  }}>
                                    <i className="pe-7s-trash danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default AnswerList;
