import React, {useEffect, useState} from "react";
import {Grid, Row, Col, Table} from "react-bootstrap";

import { NavLink } from "react-router-dom";
import Config from "../../config/config";
import Card from "../../components/Card/Card";
import gifCarregando from "../../assets/img/carregando.gif";
import gql from "graphql-tag";
import {ApolloClient} from "apollo-client";
import HttpLinkServices from "../../services/HttpLinkServices";
import {InMemoryCache} from "apollo-cache-inmemory";
import getCookie from "../../components/Cookie/Cookie";

const account_client = new ApolloClient({
    link: HttpLinkServices.account,
    cache: new InMemoryCache(),
});

const lms_client = new ApolloClient({
    link: HttpLinkServices.lms,
    cache: new InMemoryCache(),
});

const token_user = getCookie("token_user");

export default function FiltroRelatorioTrilha({ match }) {

    const trail_id = match.params.id;
    const [perfil, setPerfil] = useState([]);
    const [title, setTitle] = useState("");
    const [idUsuario, setIdUsuario] = useState(0);
    const [relatorioExistente, setRelatorioExistente] = useState("");
    const [cpfIsFilled, setCpfIsFilled] = useState("");

    function exibirElementosCarregando() {
        document.getElementById("download").style.display = "none";
        
        // SLPS-291: Inativação temporária do botão verificar ultimo relatório gerado.
        //document.getElementById("alerta-relatorio-gerado").style.display = "none";
        //document.getElementById("button-verificar-relatorio-gerado").style.display = "none";
        document.getElementById("carregando").style.display = "flex";
    }

    function exibirElementosDownload(){
        document.getElementById("carregando").style.display = "none";
        document.getElementById("download").removeAttribute("style");
    }

    function gerarRelatorio(url) {
        exibirElementosCarregando();
        fetch(url);

        // Realiza uma requisição ao backend em intervalos de 60 segundos, para verificar se o relatório já foi gerado.
        const verificaSeOrelatorioFoiGerado = setInterval(() => {
            fetch(Config.baseUrl + "/api/v1/lms/verifica_relatorio_trilha_existente?id=" + trail_id + "&usuario=" + idUsuario)
                .then(response => {
                    if (response.status === 200) {
                        clearInterval(verificaSeOrelatorioFoiGerado);
                        exibirElementosDownload();
                    }
                })
        }, 30000);
    }
    
    // SLPS-291: Inativação temporária do botão verificar ultimo relatório gerado.
    // function buscarRelatoriosGerados() {
    //     fetch(Config.baseUrl + "/api/v1/lms/verifica_relatorio_trilha_existente?id=" + trail_id + "&usuario=" + idUsuario).then(result => {
    //         if(result.status === 200){
    //             window.location.href = Config.baseUrl + "/api/v1/lms/download_relatorio_trilha_existente?id=" + trail_id + "&usuario=" + idUsuario;
    //         } else {
    //             document.getElementById("button-verificar-relatorio-gerado").style.display = "none";
    //             document.getElementById("alerta-relatorio-gerado").style.display = "block";
    //         }
    //     });
    // }

    function buscaPerfis() {
        account_client.query({
            query: gql`
                    query getAccountFieldValues(
                      $account_field_id: Int!
                    ) {
                      get_account_field_values(
                        account_field_id: $account_field_id
                      ) {
                        accountfieldvalues {
                          id: id
                          label: value
                        }
                      }
                    }`,
            variables: {account_field_id: 8}
        }).then(result => setPerfil(result.data.get_account_field_values.accountfieldvalues));
    }

    function buscaTituloDaTrilha() {
        lms_client.query({
            query: gql`
            query buscaTituloDaTrilha($id: Int!){
            trail(id: $id){
                  title
              }
            }
            `,
            variables: {id: trail_id}
        }).then(result => setTitle(result.data.trail.title));
    }

    function buscarUsuarioPorToken() {
        account_client.query({
           query: gql`
            query buscaUsuarioPorToken($token: String) {
                get_user_by_token(token: $token) {
                    id
                  }
            }
           `,
            variables: {token: token_user}
        }).then(result => setIdUsuario(result.data.get_user_by_token.id));
    }

    useEffect(() => {
        buscarUsuarioPorToken();
        buscaPerfis();
        buscaTituloDaTrilha();
    }, []);

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <div className="btn-top-container">
                                <NavLink
                                    to={"/Trail/"}
                                    className="btn btn-info btn-fill"
                                    activeClassName=""
                                >
                                    Voltar
                                </NavLink>
                            </div>
                            <Card
                                title={"Trilhas ativas"}
                                category={"GERAR RELATÓRIO (" + title + ")"}
                                trail="Gerar relatório de trilhas"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <Table striped hover>
                                        <thead>
                                        <tr>
                                            <th colSpan={9}>
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();

                                                        if (e.target.elements.inicio.value > e.target.elements.fim.value) {
                                                            document.getElementById("alerta").removeAttribute("hidden");
                                                            return;
                                                        } else {
                                                            document.getElementById("alerta").setAttribute("hidden", true);
                                                        }

                                                        var url = Config.baseUrl + "/api/v1/lms/report_trail?trail_id=" + trail_id
                                                            + "&usuario=" + idUsuario
                                                            + (e.target.elements.cpf.value ? "&cpf=" + e.target.elements.cpf.value.replace(/([/./-])/g, '') : "")
                                                            + (e.target.elements.perfil.value ? "&perfil=" + e.target.elements.perfil.value : "")
                                                            + (e.target.elements.inicio.value ? "&inicio=" + e.target.elements.inicio.value : "")
                                                            + (e.target.elements.fim.value ? "&fim=" + e.target.elements.fim.value : "")
                                                            + (e.target.elements.status.value ? "&concluido=" + e.target.elements.status.value : "");

                                                        gerarRelatorio(url);
                                                    }}
                                                >
                                                    <Row>
                                                        <Col md={6} lg={2}>
                                                            <label className="control-label">CPF</label>
                                                            <input
                                                                pattern="\d{3}\.?\d{3}\.?\d{3}-?\d{2}"
                                                                type="text"
                                                                name="cpf"
                                                                className="cpf-mask form-control"
                                                                placeholder={"Ex: 00000000000"}
                                                                maxLength={14}
                                                                minLength={11}
                                                                onChange={event => setCpfIsFilled(event.target.value)}
                                                            />
                                                        </Col>
                                                        <Col md={6} lg={2}>
                                                            <label className="control-label">Status</label>
                                                            <select name="status" className="form-control" required={cpfIsFilled ? false : true}>
                                                                <option value="">Selecione...</option>
                                                                <option value="1">Concluído</option>
                                                                <option value="0">Em andamento</option>
                                                            </select>
                                                        </Col>
                                                        <Col md={6} lg={2}>
                                                            <label className="control-label">Perfil</label>
                                                            <select name="perfil" className="form-control">
                                                                <option value="">Todos</option>
                                                                {
                                                                    perfil.map((item) => (
                                                                        <option value={item.id}> {item.label} </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </Col>
                                                        <Col md={8} lg={2}>
                                                            <label className="control-label">Data inicial</label>
                                                            <input
                                                                type="date"
                                                                name="inicio"
                                                                className="form-control"
                                                                required={cpfIsFilled ? false : true}
                                                            />
                                                        </Col>
                                                        <Col md={8} lg={2}>
                                                            <label className="control-label">Data final</label>
                                                            <input
                                                                type="date"
                                                                name="fim"
                                                                className="form-control"
                                                                required={cpfIsFilled ? false : true}
                                                            />
                                                            <div id="alerta" className="alert alert-danger" role="alert" style={{fontSize: 10, marginTop: 5, textAlign: "center"}} hidden>
                                                                Data inicial não pode ser superior a data final!
                                                            </div>
                                                        </Col>
                                                        <Col md={2}>
                                                            <button
                                                                className="btn btn-info btn-fill"
                                                                type={"submit"}
                                                                style={{marginTop: 21}}
                                                            >
                                                                Gerar relatório
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </th>
                                        </tr>
                                        </thead>
                                    </Table>
                                }
                                legend = {
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        marginTop: "30px"
                                    }}>
                                        {// SLPS-291: Inativação temporária do botão verificar ultimo relatório gerado.
                                        /* <div id="relatorio-existente">
                                            <button
                                                id="button-verificar-relatorio-gerado"
                                                className="btn btn-info btn-fill"
                                                style={{marginTop: "10px", display: "block"}}
                                                onClick={() => {buscarRelatoriosGerados()}}
                                            >
                                                <svg width="15" height="17" viewBox="0 0 15 17" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg" style={{marginRight: "8px"}}>
                                                    <path
                                                        d="M6.1875 0.5H8.47917C8.86016 0.5 9.16667 0.834375 9.16667 1.25V6.5H11.6789C12.1888 6.5 12.4438 7.17188 12.0828 7.56563L7.72578 12.3219C7.51094 12.5562 7.15859 12.5562 6.94375 12.3219L2.58099 7.56563C2.22005 7.17188 2.475 6.5 2.9849 6.5H5.5V1.25C5.5 0.834375 5.80651 0.5 6.1875 0.5ZM14.6667 12.25V15.75C14.6667 16.1656 14.3602 16.5 13.9792 16.5H0.6875C0.30651 16.5 0 16.1656 0 15.75V12.25C0 11.8344 0.30651 11.5 0.6875 11.5H4.88984L6.29349 13.0312C6.86927 13.6594 7.7974 13.6594 8.37318 13.0312L9.77682 11.5H13.9792C14.3602 11.5 14.6667 11.8344 14.6667 12.25ZM11.1146 15C11.1146 14.6562 10.8568 14.375 10.5417 14.375C10.2266 14.375 9.96875 14.6562 9.96875 15C9.96875 15.3438 10.2266 15.625 10.5417 15.625C10.8568 15.625 11.1146 15.3438 11.1146 15ZM12.9479 15C12.9479 14.6562 12.6901 14.375 12.375 14.375C12.0599 14.375 11.8021 14.6562 11.8021 15C11.8021 15.3438 12.0599 15.625 12.375 15.625C12.6901 15.625 12.9479 15.3438 12.9479 15Z"
                                                        fill="white"/>
                                                </svg>
                                                Verificar último relatório gerado
                                            </button>
                                        </div> 
                                        <div id="alerta-relatorio-gerado" className="alert alert-warning" role="alert" style={{fontSize: 10, marginTop: 5, display: "none"}}>
                                            <b>Não existe nenhum relatório já gerado para esta trilha!</b>
                                        </div>
                                    */}
                                        <div style={{display: "none", flexDirection: "column", alignItems: "center", justifyContent: "center"}} id="carregando" >
                                            <p style={{ marginBottom: 10, fontSize: 18, color: "#9A9A9A"}}>Geração do relatório em andamento.</p>
                                            <p style={{ marginBottom: 25, fontSize: 18, color: "#9A9A9A"}}>Em alguns minutos, o botão de download será exibido. Por favor, aguarde.</p>
                                           <img src={gifCarregando} height={75}/> 
                                        </div>
                                        <div id="download" style={{display: "none"}}
                                             className="dashboard-sections mt-16">
                                            <label><b>Relatório gerado com sucesso!</b></label>
                                            <a className="btn btn-success btn-fill"
                                               style={{display: "block", marginTop: "10px"}}
                                               href={`${Config.baseUrl}/api/v1/lms/download_relatorio_trilha?id=${trail_id}&usuario=${idUsuario}`}
                                               rel='noreferrer noopener'>
                                                <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:"8px"}}>
                                                    <path d="M6.1875 0.5H8.47917C8.86016 0.5 9.16667 0.834375 9.16667 1.25V6.5H11.6789C12.1888 6.5 12.4438 7.17188 12.0828 7.56563L7.72578 12.3219C7.51094 12.5562 7.15859 12.5562 6.94375 12.3219L2.58099 7.56563C2.22005 7.17188 2.475 6.5 2.9849 6.5H5.5V1.25C5.5 0.834375 5.80651 0.5 6.1875 0.5ZM14.6667 12.25V15.75C14.6667 16.1656 14.3602 16.5 13.9792 16.5H0.6875C0.30651 16.5 0 16.1656 0 15.75V12.25C0 11.8344 0.30651 11.5 0.6875 11.5H4.88984L6.29349 13.0312C6.86927 13.6594 7.7974 13.6594 8.37318 13.0312L9.77682 11.5H13.9792C14.3602 11.5 14.6667 11.8344 14.6667 12.25ZM11.1146 15C11.1146 14.6562 10.8568 14.375 10.5417 14.375C10.2266 14.375 9.96875 14.6562 9.96875 15C9.96875 15.3438 10.2266 15.625 10.5417 15.625C10.8568 15.625 11.1146 15.3438 11.1146 15ZM12.9479 15C12.9479 14.6562 12.6901 14.375 12.375 14.375C12.0599 14.375 11.8021 14.6562 11.8021 15C11.8021 15.3438 12.0599 15.625 12.375 15.625C12.6901 15.625 12.9479 15.3438 12.9479 15Z" fill="white"/>
                                                </svg>
                                                Baixar relatório
                                            </a>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
}
