import ApiServices from "./ApiServices";
import gql from "graphql-tag";

export default class Lms extends ApiServices {
  constructor(credential = 'include') {
    super('/api/v1/lms/', credential);
  }

  async getInstrutoresList(variables = {}, fetchPolicy = "") {
    return this.apolloClass.query({
      query: gql`
        query getInstrutores(
          $search: String,
          $limit: Int,
          $page: Int,
          $order: String,
          $orderby: String
        ) {
          instrutor_collection(
            search: $search,
            page: $page,
            limit: $limit,
            order: $order,
            orderby: $orderby
          ) {
          instrutores {
            id,
            nome,
            cpf,
            is_interno,
            is_active
          }
        }
      }`,
      variables,
      fetchPolicy,
    });
  }

  async getInstrutor(variables = {}) {
    return this.apolloClass.query({
      query: gql`
        query getInstrutor(
          $id: Int!,
        ) {
          instrutor(
            id: $id,
          ) {
            id,
            nome,
            cpf,
            is_interno,
            is_active
          }
        }
      `,
      variables,
    });
  }

  async createInstrutor(variables = {}) {
    return this.apolloClass.mutate({
      mutation: gql`
        mutation criaOuAtualizaInstrutor(
          $nome: String!,
          $cpf: String!,
          $is_interno: Boolean!
        ) {
          criaOuAtualizaInstrutor(input: {
            nome: $nome,
            cpf: $cpf,
            is_interno: $is_interno,
          }) {
            id,
            nome,
            cpf,
            is_interno,
          }
        }
      `,
      variables,
    });
  }

  async updateInstrutor(variables = {}) {
    return this.apolloClass.mutate({
      mutation: gql`
      mutation criaOuAtualizaInstrutor(
        $id: Int!
        $nome: String!
        $cpf: String!,
        $is_interno: Boolean!
      ) {
        criaOuAtualizaInstrutor(id: $id, input: {
          nome: $nome,
          cpf: $cpf,
          is_interno: $is_interno,
        }) {
          id,
          nome,
          cpf,
          is_interno,
        }
      }
      `,
      variables,
    });
  }

  async deleteInstrutor(id) {
    return this.apolloClass.mutate({
      mutation: gql`
        mutation removeInstrutor($id: Int!) {
          removeInstrutor(id: $id) {
            nome
          }
        }
      `,
      variables: {
        id,
      }
    })
  }

  async getInstrutoresCount(variables = {}) {
    return await this.apolloClass.query({
      query: gql`
        query getInstrutorCount{
          instrutor_count {
          count
        }
      }`,
      variables,
    });
  }
}
