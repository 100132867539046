import React, { Component } from "react";
import { Grid } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <nav className="pull-left">
            <ul>
              <li>
                <a target="_blank" href="http://sambavideos.sambatech.com" rel='noreferrer noopener'>Samba Vídeos</a>
              </li>
              <li>
                <a href="mailto:suporte@sambatech.com.br" rel='noreferrer noopener'>Suporte</a>
              </li>
              <li>
                <a class="ot-sdk-show-settings" data-gtm-type="click" data-gtm-clicktype="link" data-gtm-name="cookie-config" style={{cursor:"pointer"}}>Configurações dos cookies</a>
              </li>
              <li>
                <a target="_blank" href="https://sambatech.com/blog/" rel='noreferrer noopener'>Blog Samba</a>
              </li>
            </ul>
          </nav>
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "}
            <a target="_blank" href="http://www.sambatech.com" rel='noreferrer noopener'>Samba Tech</a>
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;
