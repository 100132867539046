import Axios from "axios";

export const APISVdata = {
  url: 'https://api.sambavideos.sambatech.com/v1/medias?',
  urlCategory: 'https://api.sambavideos.sambatech.com/v1/categories',
  tokenSV: 'c9dd1d27-9a52-41ee-bdb5-d9c53a7f79ff',
  pid: '9002',
};

export const allCategories = {};

export const token = `access_token=${APISVdata.tokenSV}`;

export const pid = `&pid=${APISVdata.pid}`;

export const requestSambaVideos = async (limit, start) =>
  Axios.get(
    `${APISVdata.url}${token}${pid}&published=true&limit=${limit}&start=${start}`
  );

export const setAllCategories = ({ parent, id, name, children }) => {
  const toAllCategories = { parent, id, name };

  allCategories[id] = toAllCategories;

  if (children) {
    children.forEach((child) => setAllCategories(child));
  }
};

let arrayToString = [];

export const reportCategoryString = (id) => {
  arrayToString.push(allCategories[id].name);
  
  if(Number(allCategories[id].parent) > 0) {
    return reportCategoryString(allCategories[id].parent);
  };

  const categoryString = arrayToString.reverse().join(' > ');
  arrayToString = [];
  return categoryString;
}

export const JSONHeaders = [
  { label: 'Título', key: 'title'},
  { label: 'Categoria', key: 'category'},
  { label: 'Status', key: 'status' },
  { label: 'Data de Publicação', key: 'postdate' },
  { label: 'Duração (em segundos)', key: 'timePerSecond'},
  { label: 'ID da mídia', key: 'mediaId'},
];

export const requestCategories = async () => {
  const { data } = await Axios.get(`${APISVdata.urlCategory}?${token}${pid}`);
  data.forEach((category) => setAllCategories(category));
};
