import React, { Component } from "react";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";

import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";
import { Card } from "../../components/Card/Card.jsx";
import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";

import { NavLink } from "react-router-dom";

import { Query, Mutation } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import gql from "graphql-tag";
import HttpLinkServices from "../../services/HttpLinkServices";
import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";

import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import Config from "../../config/config";
import { UpdateInfo } from "../../components/UpdateInfo/index.jsx";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const ADD_TODO = gql`
  mutation createOrUpdateTrail(
    $id: Int!
    $title: String!
    $description: String!
    $reactionevaluation_id: Int!
    $proof_id: Int!
    $active: Int!
    $owner: Int!
    $blockcontent: Int!
    $date_publish: String!
    $date_unpublish: String!
  ) {
    createOrUpdateTrail(
      id: $id
      input: {
        title: $title
        description: $description
        reactionevaluation_id: $reactionevaluation_id
        active: $active
        owner: $owner
        proof_id: $proof_id
        blockcontent: $blockcontent
        date_publish: $date_publish
        date_unpublish: $date_unpublish
      }
    ) {
      id
    }
  }
`;

const GET_TRAIL = gql`
  query getTrail($trail_id: Int!) {
    trail(id: $trail_id) {
      id
      title
      description
      thumbnail
      active
      blockcontent
      date_publish_format
      date_unpublish_format
      created_at
      created_by
      updated_at 
      updated_by
      owner
      reactionevaluation {
        value: id
        label: title
      }
      proof {
        value: id
        label: title
      }
    }
  }
`;

const GET_PROOFS = gql`
  query getProofs($type_id: Int!) {
    proof_collections(type_id: $type_id) {
      proofs {
        value: id
        label: title
      }
    }
  }
`;

const GET_CATEGORY_HAS_TRAIL = gql`
  query categoryHasTrail_collection($trail_id: Int!) {
    categoryHasTrail_collection(trail_id: $trail_id) {
      category_has_trail {
        id
        category_id
      }
    }
  }
`;

const CREATE_CATEGORY_HAS_TRAIL = gql`
  mutation createCategoryHasTrail($trail_id: Int!, $category_id: Int!) {
    createCategoryHasTrail(trail_id: $trail_id, category_id: $category_id) {
      id
    }
  }
`;

const DELETE_CATEGORY_HAS_TRAIL = gql`
  mutation deleteCategoryHasTrail($trail_id: Int!, $category_id: Int!) {
    deleteCategoryHasTrail(trail_id: $trail_id, category_id: $category_id) {
      id
    }
  }
`;

const GET_CATEGORIES = gql`
  {
    category_collections {
      categories {
        value: id
        label: title
      }
    }
  }
`;

const GET_GUARDIANS = gql`
query usersByProfile($id: Int!) {
  get_users_by_profile(id: $id) {
    users {
      name
      id
      mail
      uid
      __typename
    }
    __typename
  }
}`;

class TrailItem extends Component {
  trail_id;
  trail_edit;
  thumbnail_on_create;
  categories_on_create;

  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      title_error: "",
      categories_error: "",
      reaction_evaluation_error: "",
      proof_id_error: "",
      alertInfo: "",
      datePublish: new Date(),
      dateUnpublish: null,
      selectedOwner: this.getOwnerFromProps(props),
    };

    this.start_publish_date = true;
    this.start_unpublish_date = true;
    this.start_active = true;
    this.start_blockcontent = true;
  }

  getOwnerFromProps = (props) => {
    const { trail_edit, guardians } = props;
    const ownerId = trail_edit ? trail_edit.owner : null;

    if (ownerId) {
      const owner = guardians.find((guardian) => guardian.id === ownerId);

      if (owner) {
        return owner;
      }
    }

    return null;
  };

  handleOwnerChange = (selectedOption) => {
    this.setState({ selectedOwner: selectedOption });
  };

  onChange_datePublish = (datePublish) => this.setState({ datePublish });
  onChange_dateUnpublish = (dateUnpublish) => this.setState({ dateUnpublish });

  handleCheckboxChange = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_active: !this.state.checkboxValue_active,
    }));
  };

  handleCheckboxChange_blockcontent = (e) => {
    this.setState((state) => ({
      ...state,
      checkboxValue_blockcontent: !this.state.checkboxValue_blockcontent,
    }));
  };

  handleSubmit = () => {
    this.setState({
      alertInfo: "Salvo com sucesso!",
    });

    setTimeout(() => {
      this.setState({
        alertInfo: "",
      });
      window.location.href = Config.baseUrl + "#/Trail";
      window.scrollTo(0, 0);
    }, 2000);
  };

  onChange = (inputValue, action, item_id) => {
    // Adicionar
    if (action.action === "select-option") {
      lms_client.mutate({
        mutation: CREATE_CATEGORY_HAS_TRAIL,
        variables: {
          trail_id: item_id,
          category_id: action.option.value,
        },
      });
    }
    // Remover
    else if (action.action === "remove-value") {
      lms_client.mutate({
        mutation: DELETE_CATEGORY_HAS_TRAIL,
        variables: {
          trail_id: item_id,
          category_id: action.removedValue.value,
        },
      });
    }
  };

  handleSelectThumbnailOnCreate = (e) => {
    this.thumbnail_on_create = e.currentTarget.files[0];
  };

  handleSelectCategoriesOnCreate = (inputValue) => {
    this.categories_on_create = inputValue;
  };

  sendCategories = async (trailId, categories) => {
    // Enviar a mutação com o link do conteúdo criado
    const categoriesId = categories.map((category) => category.value);

    await categoriesId.forEach((id) => {
      lms_client.mutate({
        mutation: CREATE_CATEGORY_HAS_TRAIL,
        variables: {
          trail_id: trailId,
          category_id: parseInt(id),
        },
      });
    });
  };

  sendThumbnail = (trailId) => {
    // Enviar a thumbnail com o link do conteúdo criado
    if (!this.thumbnail_on_create) return;

    const formData = new FormData();
    formData.append("id", trailId);
    formData.append("file", this.thumbnail_on_create);

    const request = new XMLHttpRequest();
    request.open("POST", Config.baseUrl + "/api/v1/lms/trail_thumb");
    request.send(formData);
  };

  onCreate = async (e, createOrUpdateTrail) => {
    // Função de criação para subir as informações iniciais para a mutation e usar o id do retorno para enviar as categories e thumbnail
    e.preventDefault();

    if (!this.formValidation(e, 'create')) return;

    this.setState({ is_loading: true });

    try {
      const response = await createOrUpdateTrail({
        variables: {
          id: parseInt(this.trail_id),
          title: e.target.elements.title.value,
          description: e.target.elements.description.value,
          reactionevaluation_id: e.target.elements.reactionevaluation_id.value
            ? parseInt(e.target.elements.reactionevaluation_id.value)
            : 0,
          proof_id: e.target.elements.proof_id.value
            ? parseInt(e.target.elements.proof_id.value)
            : 0,
          owner: e.target.elements.owner.value
          ? parseInt(e.target.elements.owner.value)
          : 0,
          active: e.target.elements.active.checked ? 1 : 0,
          blockcontent: e.target.elements.blockcontent.checked ? 1 : 0,
          date_publish: e.target.elements.date_publish.value,
          date_unpublish: e.target.elements.date_unpublish.value,
        },
      });

      const trailId = response.data.createOrUpdateTrail.id;

      this.sendCategories(trailId, this.categories_on_create);
      this.sendThumbnail(trailId);

      this.handleSubmit();
    } catch (e) {
      this.setState({ is_loading: false });
      alert("Houve um erro ao enviar os dados para o servidor");
    }
  };

  onEdit = async (e, createOrUpdateTrail) => {

    e.preventDefault();

    if (!this.formValidation(e, 'edit')) return;

    this.setState({ is_loading: true });

    try {
      await createOrUpdateTrail({
        variables: {
          id: parseInt(this.trail_id),
          title: e.target.elements.title.value,
          description: e.target.elements.description.value,
          reactionevaluation_id: e.target.elements.reactionevaluation_id.value
            ? parseInt(e.target.elements.reactionevaluation_id.value)
            : 0,
          proof_id: e.target.elements.proof_id.value
            ? parseInt(e.target.elements.proof_id.value)
            : 0,
          owner: e.target.elements.owner.value
          ? parseInt(e.target.elements.owner.value)
          : 0,
          active: e.target.elements.active.checked ? 1 : 0,
          blockcontent: e.target.elements.blockcontent.checked ? 1 : 0,
          date_publish: e.target.elements.date_publish.value,
          date_unpublish: e.target.elements.date_unpublish.value,
        },
      });

      this.handleSubmit();
    } catch (e) {
      this.setState({ is_loading: false });
      alert("Houve um erro ao atualizar o conteúdo");
    }
  };
 
  formValidation = (e, type) => {
    this.setState({
      title_error: "",
      description_error: "",
      categories_error: "",
      reaction_evaluation_error: "",
      proof_id_error: "",
    });

    if (!e.target.elements.title.value) {
      const titleInput = e.target.elements.title;
      titleInput.focus();
      this.setState({ title_error: "Digite um título" });
    }

    const descriptionVerify = e.target.elements.description.value ? e.target.elements.description.value : 0;
    if (descriptionVerify.length > 512) {
      const descriptionInput = e.target.elements.description;
      descriptionInput.focus();
      this.setState({ description_error: "Descrição muito longa" });
    }

    if ((!(!!this.categories_on_create && !!this.categories_on_create.length)) && type == 'create') {
      const categoriesInput = e.target.elements.categories;
      categoriesInput.focus();
      this.setState({ categories_error: "Selecione pelo menos uma categoria" });
    }

    if (!e.target.elements.reactionevaluation_id.value) {
      const reactionEvaluationInput = e.target.elements.reactionevaluation_id;
      reactionEvaluationInput.focus();
      this.setState({
        reaction_evaluation_error: "Selecione uma avaliação de reação",
      });
    }

    if (!e.target.elements.proof_id.value) {
      const proofIdInput = e.target.elements.proof_id;
      proofIdInput.focus();
      this.setState({ proof_id_error: "Selecione uma prova" });
    }

    if (!e.target.elements.owner.value) {
      const ownerInput = e.target.elements.owner;
      ownerInput.focus();
      this.setState({
        owner_error: "Escolha a pessoa responsável",
      });
    }

    if (
      !e.target.elements.title.value ||
      (!(!!this.categories_on_create && !!this.categories_on_create.length)) && type == 'create'||
      !e.target.elements.reactionevaluation_id.value ||
      !e.target.elements.proof_id.value ||
      descriptionVerify.length > 512
    )
      return false;

    return true;
  };

  mergeData = (data1, data2) => {
    return data1.concat(data2);
  };

  render() {
    if (this.props.match.params.id) {
      this.trail_id = this.props.match.params.id;
    } else {
      this.trail_id = 0;
    }

    const { selectedOwner } = this.state;
    const { guardians } = this.props;

    return (
      <Query
        client={lms_client}
        query={GET_TRAIL}
        variables={{ trail_id: this.trail_id }}
        fetchPolicy="no-cache"
      >
        {({ loading, error, data }) => {
          if (loading) return <div>Carregando...</div>;
          if (error) return <div>Erro :(</div>;

          this.trail_edit = data.trail;

          if (
            this.start_publish_date &&
            this.trail_edit &&
            this.trail_edit.date_publish_format
          ) {
            this.start_publish_date = false;
            this.setState({
              datePublish: new Date(this.trail_edit.date_publish_format),
            });
          }
          if (
            this.start_unpublish_date &&
            this.trail_edit &&
            this.trail_edit.date_unpublish_format
          ) {
            this.start_unpublish_date = false;
            this.setState({
              dateUnpublish: new Date(this.trail_edit.date_unpublish_format),
            });
          }
          if (this.start_active && this.trail_edit && this.trail_edit.active) {
            this.start_active = false;
            this.setState({
              checkboxValue_active: this.trail_edit.active === 1 ? true : false,
            });
          }
          if (
            this.start_blockcontent &&
            this.trail_edit &&
            this.trail_edit.blockcontent
          ) {
            this.start_blockcontent = false;
            this.setState({
              checkboxValue_blockcontent:
                this.trail_edit.blockcontent === 1 ? true : false,
            });
          }

          return (
            <Mutation mutation={ADD_TODO} client={lms_client}>
              {(createOrUpdateTrail, { data }) => {
                return (
                  <div className="content">
                    <Grid fluid>
                      <Row>
                        <Col md={12}>
                          <div className="btn-top-container">
                            <NavLink
                              to="/Trail"
                              className="btn btn-info btn-fill"
                              activeClassName=""
                            >
                              VOLTAR
                            </NavLink>
                            <NotificationSystem
                              ref="notificationSystem"
                              style={style}
                            />
                          </div>

                          <Card
                            title="Trilha"
                            content={
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  if (this.trail_edit) {
                                    return this.onEdit(e, createOrUpdateTrail);
                                  }
                                  return this.onCreate(e, createOrUpdateTrail);
                                }}
                              >
                                <FormInputs
                                  ncols={["col-md-12"]}
                                  proprieties={[
                                    {
                                      name: "title",
                                      label: "Título",
                                      type: "text",
                                      bsClass: "form-control",
                                      placeholder: "Título",
                                      defaultValue: this.trail_edit
                                        ? this.trail_edit.title
                                        : "",
                                    },
                                  ]}
                                />
                                {this.state.title_error && (
                                  <p className="inputError">
                                    {this.state.title_error}
                                  </p>
                                )}

                                <FormInputs
                                  ncols={["col-md-12"]}
                                  proprieties={[
                                    {
                                      name: "description",
                                      label: "Descrição",
                                      type: "text",
                                      bsClass: "form-control",
                                      placeholder: "Descrição",
                                      defaultValue: this.trail_edit
                                        ? this.trail_edit.description
                                        : "",
                                    },
                                  ]}
                                />
                                {this.state.description_error && (
                                  <p className="inputError">
                                    {this.state.description_error}
                                  </p>
                                )}

                              <FormGroup>
                                <ControlLabel>Responsável</ControlLabel>
                                <div>
                                  <Query
                                    variables={{ id: 15 }}
                                    client={account_client}
                                    query={GET_GUARDIANS}
                                    fetchPolicy="no-cache"
                                  >
                                    {({
                                      loading: loading1,
                                      error: error1,
                                      data: data1,
                                    }) => (
                                      <Query
                                        variables={{ id: 1 }}
                                        client={account_client}
                                        query={GET_GUARDIANS}
                                      >
                                        {({
                                          loading: loading2,
                                          error: error2,
                                          data: data2,
                                        }) => {
                                          if (loading1 || loading2)
                                            return <div>Carregando...</div>;
                                          if (error1 || error2)
                                            return <div>Erro :(</div>;

                                          const guardians1 =
                                            data1.get_users_by_profile.users;
                                          const guardians2 =
                                            data2.get_users_by_profile.users;
                                          const allGuardians = this.mergeData(
                                            guardians1,
                                            guardians2
                                          );

                                          // Verifica se o this.trail_edit.owner possui um valor válido
                                          const ownerValue =
                                            this.trail_edit &&
                                            this.trail_edit.owner
                                              ? this.trail_edit.owner
                                              : null;

                                          // Se houver um ownerValue, procura o nome correspondente
                                          const ownerName =
                                            ownerValue &&
                                            allGuardians.find(
                                              (guardian) =>
                                                guardian.id === ownerValue
                                            );

                                          return (
                                            <>
                                              <Select
                                                name="owner"
                                                options={allGuardians.map(
                                                  (guardian) => ({
                                                    value: guardian.id,
                                                    label: guardian.name,
                                                  })
                                                )}
                                                className="basic-select"
                                                classNamePrefix="select"
                                                isClearable={
                                                  this.state.selectedOwner
                                                    ? true
                                                    : false
                                                }
                                                value={
                                                  this.state.selectedOwner
                                                    ? this.state.selectedOwner
                                                    : ownerValue
                                                    ? {
                                                        value: ownerValue,
                                                        label: ownerName.name,
                                                      }
                                                    : null
                                                }
                                                onChange={
                                                  this.handleOwnerChange
                                                }
                                              />
                                              {this.state.owner_error && (
                                                <p className="inputError">
                                                  {this.state.owner_error}
                                                </p>
                                              )}
                                            </>
                                          );
                                        }}
                                      </Query>
                                    )}
                                  </Query>
                                </div>
                              </FormGroup>

                                <FormGroup>
                                  <ControlLabel>Categorias</ControlLabel>
                                  <div>
                                    <Query
                                      client={dam_client}
                                      query={GET_CATEGORIES}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return (
                                            <tr>
                                              <td>Carregando...</td>
                                            </tr>
                                          );
                                        if (error)
                                          return (
                                            <tr>
                                              <td>Erro :(</td>
                                            </tr>
                                          );

                                        var categories =
                                          data.category_collections.categories;

                                        if (this.trail_id)
                                          return (
                                            <Query
                                              client={lms_client}
                                              query={GET_CATEGORY_HAS_TRAIL}
                                              variables={{
                                                trail_id: this.trail_edit.id,
                                              }}
                                              fetchPolicy="no-cache"
                                            >
                                              {({ loading, error, data }) => {
                                                if (loading)
                                                  return (
                                                    <div>Carregando...</div>
                                                  );
                                                if (error)
                                                  return <div>Erro :(</div>;

                                                var categories_has_trail_collection_default =
                                                  [];
                                                var categories_has_trail_collection =
                                                  data
                                                    .categoryHasTrail_collection
                                                    .category_has_trail;
                                                categories.map(
                                                  (category_item) => {
                                                    categories_has_trail_collection.map(
                                                      (
                                                        categories_has_trail_item
                                                      ) => {
                                                        if (
                                                          category_item.value ===
                                                          categories_has_trail_item.category_id
                                                        ) {
                                                          categories_has_trail_collection_default.push(
                                                            category_item
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }
                                                );

                                                return (
                                                  <>
                                                    <Select
                                                      defaultValue={
                                                        categories_has_trail_collection_default
                                                      }
                                                      isMulti
                                                      isSearchable
                                                      name={"categories"}
                                                      options={categories}
                                                      className="basic-multi-select"
                                                      classNamePrefix="select"
                                                      onChange={(
                                                        inputValue,
                                                        action
                                                      ) =>
                                                        this.onChange(
                                                          inputValue,
                                                          action,
                                                          this.trail_edit.id
                                                        )
                                                      }
                                                      isClearable={true}
                                                    />
                                                    {this.state
                                                      .categories_error && (
                                                      <p className="inputError">
                                                        {
                                                          this.state
                                                            .categories_error
                                                        }
                                                      </p>
                                                    )}
                                                  </>
                                                );
                                              }}
                                            </Query>
                                          );

                                        return (
                                          <>
                                            <Select
                                              isMulti
                                              isSearchable
                                              name={"categories"}
                                              options={categories}
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              onChange={
                                                this
                                                  .handleSelectCategoriesOnCreate
                                              }
                                              isClearable={true}
                                            />
                                            {this.state.categories_error && (
                                              <p className="inputError">
                                                {this.state.categories_error}
                                              </p>
                                            )}
                                          </>
                                        );
                                      }}
                                    </Query>
                                  </div>
                                </FormGroup>

                                <hr />

                                <FormGroup>
                                  <ControlLabel>
                                    Avaliação de reação
                                  </ControlLabel>
                                  <div>
                                    <Query
                                      variables={{ type_id: 2 }} // 2 é o ID da avaliação de reação no micro serviço de avaliações
                                      client={lms_client}
                                      query={GET_PROOFS}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return <div>Carregando...</div>;
                                        if (error) return <div>Erro :(</div>;
                                        var proofs = data;
                                        return (
                                          <>
                                            <Select
                                              name="reactionevaluation_id"
                                              options={
                                                proofs.proof_collections
                                                  ? proofs.proof_collections
                                                      .proofs
                                                  : []
                                              }
                                              className="basic-select"
                                              classNamePrefix="select"
                                              isClearable={true}
                                              defaultValue={
                                                this.trail_edit &&
                                                this.trail_edit
                                                  .reactionevaluation
                                                  ? this.trail_edit
                                                      .reactionevaluation
                                                  : ""
                                              }
                                            />
                                            {this.state
                                              .reaction_evaluation_error && (
                                              <p className="inputError">
                                                {
                                                  this.state
                                                    .reaction_evaluation_error
                                                }
                                              </p>
                                            )}
                                          </>
                                        );
                                      }}
                                    </Query>
                                  </div>
                                </FormGroup>

                                <FormGroup>
                                  <ControlLabel>Prova</ControlLabel>
                                  <div>
                                    <Query
                                      variables={{ type_id: 1 }} // 1 é o ID da prova no micro serviço de avaliações
                                      client={lms_client}
                                      query={GET_PROOFS}
                                    >
                                      {({ loading, error, data }) => {
                                        if (loading)
                                          return <div>Carregando...</div>;
                                        if (error) return <div>Erro :(</div>;
                                        var proofs = data;
                                        return (
                                          <>
                                            <Select
                                              name="proof_id"
                                              options={
                                                proofs.proof_collections
                                                  ? proofs.proof_collections
                                                      .proofs
                                                  : []
                                              }
                                              className="basic-select"
                                              classNamePrefix="select"
                                              isClearable={true}
                                              defaultValue={
                                                this.trail_edit &&
                                                this.trail_edit.proof
                                                  ? this.trail_edit.proof
                                                  : ""
                                              }
                                            />
                                            {this.state.proof_id_error && (
                                              <p className="inputError">
                                                {this.state.proof_id_error}
                                              </p>
                                            )}
                                          </>
                                        );
                                      }}
                                    </Query>
                                  </div>
                                </FormGroup>

                                <FormGroup>
                                  <ControlLabel>
                                    <input
                                      value="1"
                                      name="blockcontent"
                                      type="checkbox"
                                      checked={
                                        this.state.checkboxValue_blockcontent
                                      }
                                      onChange={
                                        this.handleCheckboxChange_blockcontent
                                      }
                                    />{" "}
                                    Bloquear conteúdos
                                  </ControlLabel>
                                </FormGroup>

                                <hr />
                                <FormGroup>
                                  <ControlLabel>
                                    <input
                                      value="1"
                                      name="active"
                                      type="checkbox"
                                      checked={this.state.checkboxValue_active}
                                      onChange={this.handleCheckboxChange}
                                    />{" "}
                                    Ativo
                                  </ControlLabel>
                                </FormGroup>

                                <FormGroup>
                                  <ControlLabel>
                                    Data de publicação
                                  </ControlLabel>
                                  <div>
                                    <DateTimePicker
                                      name="date_publish"
                                      onChange={this.onChange_datePublish}
                                      value={this.state.datePublish}
                                      required={true}
                                      format="d/M/y H:m"
                                      lang="pt"
                                    />
                                  </div>
                                </FormGroup>

                                <FormGroup>
                                  <ControlLabel>
                                    Data para despublicar
                                  </ControlLabel>
                                  <div>
                                    <DateTimePicker
                                      name="date_unpublish"
                                      onChange={this.onChange_dateUnpublish}
                                      value={this.state.dateUnpublish}
                                      required={false}
                                      format="d/M/y H:m"
                                      lang="pt"
                                    />
                                  </div>
                                </FormGroup>

                                <Row>
                                  <Col md={10}>
                                    <AlertInfo
                                      ref="alertSave"
                                      alertInfo={this.state.alertInfo}
                                    />
                                  </Col>
                                  <Col md={2}>
                                    <Button
                                      bsStyle="info"
                                      pullRight
                                      fill
                                      type="submit"
                                      disabled={this.state.is_loading}
                                    >
                                      Salvar
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="clearfix" />
                              </form>
                            }
                          />

                          <Card
                            title="Thumbnail"
                            content={
                              this.trail_edit ? (
                                <form
                                  action={
                                    Config.baseUrl + "/api/v1/lms/trail_thumb"
                                  }
                                  method="post"
                                  enctype="multipart/form-data"
                                >
                                  <input
                                    type="hidden"
                                    name="returnUrl"
                                    value={
                                      "/#/Trail-edit/" + this.trail_edit.id
                                    }
                                  />
                                  <input
                                    type="hidden"
                                    name="id"
                                    value={this.trail_edit.id}
                                  />

                                  <input
                                    type="file"
                                    name="file"
                                    accept="image/*"
                                    required
                                  />

                                  <Row>
                                    <Col md={2}>
                                      <Button
                                        bsStyle="info"
                                        pullRight
                                        fill
                                        type="submit"
                                      >
                                        Upload
                                      </Button>
                                    </Col>
                                  </Row>
                                  <div className="clearfix" />
                                </form>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    name="file"
                                    accept="image/*"
                                    required
                                    onChange={
                                      this.handleSelectThumbnailOnCreate
                                    }
                                  />

                                  <div className="clearfix" />
                                </>
                              )
                            }
                          />

                          <Card
                            title=""
                            content={
                              this.trail_edit && this.trail_edit.thumbnail ? (
                                <div>
                                  <img
                                    alt="thumb"
                                    src={
                                      Config.baseUrl +
                                      "/lms/assets/" +
                                      this.trail_edit.thumbnail
                                    }
                                    height="100px"
                                  />
                                </div>
                              ) : (
                                <div />
                              )
                            }
                          />
                        </Col>
                      </Row>
                      {(window.location.hash).includes('edit')
                        ? <UpdateInfo content={this.trail_edit}/>
                        : <></>
                      }
                    </Grid>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default TrailItem;
