import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import axios from "axios";
import Config from "../../config/config";
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Paginator from "../../components/Paginator";

function traduzirTipo(tipo) {
  switch (tipo) {
    case 'file':
      return 'Conteúdo Digital';
    case 'curso':
      return 'Curso';
    case 'trilha':
      return 'Trilha';
    default:
      return tipo;
  }
}

function getEditPage(itemType) {
  switch (itemType) {
    case 'trilha':
      return 'Trail-edit';
    case 'curso':
      return 'Course-edit';
    case 'file':
      return 'dam-edit/Media';
    default:
      return '';
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/"+$param;
  if ($param_1 != null) {
    $url += "/"+$param_1;
  }
  if ($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}

class DespublicarConteudo extends Component {
  state = {
    contentData: [],
    currentPage: 1,
    totalPages: 1,
    limit: 20,
  };
  
  async componentDidMount() {
    try {
      const { currentPage, limit } = this.state;
      const offset = (currentPage - 1) * limit;
      const endpoint = Config.baseUrl + `/api/v1/lms/unpublished-content?offset=${offset}&limit=${limit}`;
      const response = await axios.get(endpoint);
      this.setState({
        contentData: response.data.data,
        totalPages: Math.ceil(response.data.total / limit),
      });
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  }
  
  handlePageChange = (page) => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.componentDidMount();
      }
    );
  };  
  
  renderPagination() {
    const { currentPage, totalPages } = this.state;
  
    return (
      <Paginator
        currentPage={currentPage}
        pages={totalPages}
        onChangePage={this.handlePageChange}
      />
    );
  }
  
  render() {
    const { contentData } = this.state;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
            <div className="btn-top-container">
              <a
                className="btn btn-info"
                href={Config.baseUrl + "/api/v1/lms/unpublished-content?report=true"}
              >
                Baixar Relatório
              </a>
              </div>

              <Card
                title={"Meus Conteúdos"}
                profile=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <>
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th>Tipo</th>
                        <th>Responsável</th>
                        <th>Data de Despublicação</th>
                        <th>Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                    {contentData && contentData.length > 0 ? (
                      contentData.map((item) => (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.title}</td>
                          <td>{traduzirTipo(item.type)}</td> {}
                          <td>{item.owner ? item.owner.name : 'Sem responsável'}</td>
                          <td>{formatDate(item.unpublish)}</td>
                          <td className="buttons-list">
                            <a title="Editar" href={getUrl(getEditPage(item.type), item.id)}>
                              <i className="pe-7s-note"></i>
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">Nenhum dado disponível</td>
                      </tr>
                    )}

                    </tbody>
                  </Table>
                  {this.renderPagination()} {}
                  </>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DespublicarConteudo;
