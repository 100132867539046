import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { Query } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import HttpLinkServices from "../../services/HttpLinkServices";
import NotificationSystem from "react-notification-system";
import { style } from "../../variables/Variables.jsx";


const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const TRAILS_BY_COURSE = gql`
  query trailsByItem($item_id: Int, $type_content: String!) {
    trailsByItem(item_id: $item_id, type_content: $type_content) {
      trailitems {
        trail {
          id
          title
        }
      }
      __typename
    }
  }
`;

class CourseComposition extends Component {
  controllerItens = [];
  urlBack;
  start_sortable = true;

  constructor() {
    super();
    this.state = {
      defaultOptions: [],
      items: [],
      ids_items: [],
    };
  }

  sortableItems = (trailitems) => {
    const items = trailitems.map((item) => {
      const { id, title } = item.trail;
      return { id, title };
    });
    this.setState({ items });
  };

  render() {
    const inputValue = "";
    const courseId = this.props.match.params.id;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <button
                  className="btn btn-info btn-fill"
                  onClick={() => window.history.back()}
                >
                  VOLTAR
                </button>
                <NotificationSystem
                  ref="notificationSystem"
                  style={style}
                />
              </div>
              <Card
                title="Trilhas vinculadas ao curso"
                category={`O curso ${courseId} está vinculado nas seguintes trilhas`}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Query
                    client={lms_client}
                    variables={{
                      item_id: parseInt(courseId),
                      type_content: "course",
                    }}
                    query={TRAILS_BY_COURSE}
                  >
                    {({ loading, error, data }) => {
                      if (loading)
                        return (
                          <tr>
                            <td>Carregando...</td>
                          </tr>
                        );
                      if (error)
                        return (
                          <tr>
                            <td>Erro :(</td>
                          </tr>
                        );

                      const trailitems = data.trailsByItem.trailitems;
                      if (this.start_sortable) {
                        this.start_sortable = false;
                        this.sortableItems(trailitems);
                      }

                      return (
                        <div className="sortable">
                          <ul>
                            {this.state.items.map((item, index) => (
                              <li key={`item-${index}`} style={{ cursor: 'default' }}>Trilha #{item.id} - {item.title}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    }}
                  </Query>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CourseComposition;
