import React, { Component } from "react";
import { Alert } from "react-bootstrap";

export class AlertInfo extends Component {
  
  render() {
    if(this.props.alertInfo !== "") {
      return (
        <Alert bsStyle="info" style={this.props.style}>
          <span>{this.props.alertInfo}</span>
        </Alert>
      );
    } else {
      return null;
    }
  }
}

export default AlertInfo;
