import {HttpLink} from "apollo-link-http";
import Config from "../config/config";

const HttpLinkServices = {

  // Account
  account: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/account/`,
    credentials: 'include'
  }),

  // Lms
  lms: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/lms/`,
    credentials: 'include'
  }),

  // Dam
  dam: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/dam/`,
    credentials: 'include'
  }),

  // Analytics
  analytics: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/analytics/`,
    credentials: 'include'
  }),

  // Chat
  chat: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/chat/`,
    credentials: 'include'
  }),

  // Cms
  cms: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/cms/`,
    credentials: 'include'
  }),

  // Corretor
  corretor: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/corretor/`,
    credentials: 'include'
  }),

  // Parceiro
  parceiro: new HttpLink({
    uri: `${Config.baseUrl}/api/v1/parceiro/`,
    credentials: 'include'
  }),

}

export default HttpLinkServices;