import React, { Component } from "react";
import { Grid, Row, Col, Table, Checkbox } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import HttpLinkServices from "../../services/HttpLinkServices";

import { FormInputs } from "../../components/FormInputs/FormInputs.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import { capitalizeString } from "../../variables/utils.js";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Config from "../../config/config";

import modeloArquivo from "../../assets/modelos-import/modelo-importar-lista-presenca.csv";

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const GET_SUBSCRIPTION = gql`
query getSubscription($classroom_id: Int!, $limit: Int!, $page: Int!){
	subscription(classroom_id:$classroom_id, limit: $limit, page: $page){
    subscription{
      id, user_id, is_present
    }
  }
}
`;

const GET_USER = gql`
query getUser($id: Int!){
  user(id:$id){
    id
    name
    uid
    mail
    userMetas{
      account_field{
        field
      }
      account_field_value{
        value
      }
    }
  }
}
`;

const COUNT_SUBSCRIPTIONS = gql`query countCourses($classroom_id: Int!){
  countSubscriptions(classroom_id:$classroom_id){
    count
  }
}
`;

const UPDATE_SUBSCRIPTION = gql`
  mutation AddTodo($id: Int!, $is_present: Int!){
    subscription_present(id:$id, is_present:$is_present){
      id
    }
  }
`;

const DELETE_SUBSCRIPTION = gql`
  mutation deleteSubscription($id: Int!){
    deleteSubscription(id:$id) {
      id
    }
  }
`;

const CREATE_SUBSCRIPTION_BY_UID = gql`
mutation createSubscription_by_uid($classroom_id: Int!, $uid: String!){
  createSubscription_by_uid(classroom_id:$classroom_id, uid:$uid){
    id
  }
}
`;

const GET_CLASSROOM = gql`
query getClassroom($classroom_id: Int!) {
  classroom(id: $classroom_id) {
    id
    receipt
  }
}
`;

class ClassroomList extends Component {
  classroom_receipt = true;

  constructor() {
    super();
    this.state = {
      limit: 20,
      page: 1,
      replyToUser: "",
      refreshPage: false,
      action_all: -1,
    };
  }

  approvedToggleAll() {
    if (this.state.action_all === 0) {
      var approved = -1;
    } else if (this.state.action_all === 1) {
      var approved = 0;
    } else {
      var approved = 1;
    }
    this.setState((state) => ({
      ...state,
      action_all: approved,
    }));

    lms_client
      .query({
        query: GET_SUBSCRIPTION,
        variables: {
          classroom_id: this.props.match.params.classroom_id,
          limit: 99999,
          page: 1,
        },
      })
      .then((result) => {
        result.data.subscription.subscription.forEach((item, key) => {
          lms_client.mutate({
            mutation: UPDATE_SUBSCRIPTION,
            variables: {
              id: item.id,
              is_present: approved,
            },
          });
          this.setState((state) => ({
            ...state,
            [item.id]: approved,
          }));
        });
      });
  }

  approvedToggle(item) {
    // Só utiliza a var item.approved, quando o State não está definido, pois o item.approved é fixo
    var approved_var =
      this.state[item.id] !== undefined ? this.state[item.id] : item.approved;

    // Altera os status das presenças, 0 = falta, 1 = presença, -1 (null) = Não iniciado
    if (approved_var === 0) {
      var approved = -1;
    } else if (approved_var === 1) {
      var approved = 0;
    } else {
      var approved = 1;
    }

    lms_client.mutate({
      mutation: UPDATE_SUBSCRIPTION,
      variables: {
        id: item.id,
        is_present: approved,
      },
    });
    // Alterar o state
    this.setState((state) => ({
      ...state,
      [item.id]: approved,
    }));
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                  to={"/Classroom/"}
                  className="btn btn-info btn-fill"
                  activeClassName=""
                >
                  VOLTAR
                </NavLink>
              </div>

              <Card
                title={"Lista de Presença"}
                classroom=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <td colSpan="5">
                          Editando turma #{this.props.match.params.classroom_id}
                        </td>
                      </tr>
                      <tr>
                        <th colspan="5">
                          <Mutation
                            mutation={CREATE_SUBSCRIPTION_BY_UID}
                            client={lms_client}
                          >
                            {(createSubscription_by_uid, { data }) => (
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  createSubscription_by_uid({
                                    variables: {
                                      classroom_id: parseInt(
                                        this.props.match.params.classroom_id
                                      ),
                                      uid: e.target.elements.uid.value,
                                    },
                                  });
                                  setTimeout(function () {
                                    window.location.reload();
                                  }, 500);
                                }}
                              >
                                <FormInputs
                                  ncols={["col-md-12"]}
                                  proprieties={[
                                    {
                                      name: "uid",
                                      label:
                                        "Inserir um registro por CPF ou Matrícula",
                                      type: "text",
                                      bsClass: "form-control",
                                      placeholder: "CPF ou Matrícula",
                                    },
                                  ]}
                                />

                                <Button bsStyle="info" fill type="submit">
                                  Alocar usuário
                                </Button>
                              </form>
                            )}
                          </Mutation>

                          <hr />

                          <form
                            target="_blank"
                            action={
                              Config.baseUrl +
                              "/api/v1/lms/import-users-to-classroom"
                            }
                            method="post"
                            encType="multipart/form-data"
                          >
                            <label>
                              Para cadastro em massa, importe o arquivo
                              disponibilizado abaixo preenchido com os dados dos
                              participantes
                            </label>
                            <input
                              type="hidden"
                              name="returnUrl"
                              value={"/" + window.location.hash}
                            />
                            <input
                              type="hidden"
                              name="classroom_id"
                              value={this.props.match.params.classroom_id}
                            />

                            <input type="file" name="file" required />

                            <Row className="import-sec">
                              <Col md={2}>
                                <Button
                                  type="submit"
                                  href={modeloArquivo}
                                  bsStyle="success"
                                  fill
                                >
                                  Baixar modelo
                                </Button>
                              </Col>
                              <Col md={2}>
                                <Button bsStyle="info" fill type="submit">
                                  Importar
                                </Button>
                              </Col>
                            </Row>
                            <div className="clearfix" />
                          </form>
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>Presente</th>
                        <th>Nome</th>
                        <th>CPF/Matricula</th>
                        <th>Hierarquia</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <Query
                        client={lms_client}
                        query={GET_SUBSCRIPTION}
                        variables={{
                          classroom_id: this.props.match.params.classroom_id,
                          limit: this.state.limit,
                          page: this.state.page,
                        }}
                      >
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <tr>
                                <td>Carregando...</td>
                              </tr>
                            );
                          if (error)
                            return (
                              <tr>
                                <td>Erro :(</td>
                              </tr>
                            );

                          if (!data.subscription.subscription) {
                            return (
                              <tr>
                                <td>(Vazio)</td>
                              </tr>
                            );
                          }

                          return data.subscription.subscription.map((item) => (
                            <Query
                              client={account_client}
                              variables={{ id: item.user_id }}
                              query={GET_USER}
                            >
                              {({ loading, error, data }) => {
                                if (loading)
                                  return (
                                    <tr>
                                      <td>Carregando...</td>
                                    </tr>
                                  );
                                if (error)
                                  return (
                                    <tr>
                                      <td>Erro :(</td>
                                    </tr>
                                  );

                                if (!data.user) {
                                  return <div></div>;
                                }
                                return (
                                  <tr key={item.id}>
                                    <td>
                                      <Button
                                        bsStyle={
                                          (this.state[item.id] === undefined &&
                                            item.is_present === null) ||
                                          this.state[item.id] === -1
                                            ? "default"
                                            : (item.is_present &&
                                                this.state[item.id] ===
                                                  undefined) ||
                                              this.state[item.id]
                                            ? "info"
                                            : "danger"
                                        }
                                        fill
                                        onClick={() =>
                                          this.approvedToggle(item)
                                        }
                                      >
                                        {(this.state[item.id] === undefined &&
                                          item.is_present === null) ||
                                        this.state[item.id] === -1
                                          ? "Não iniciado"
                                          : (item.is_present &&
                                              this.state[item.id] ===
                                                undefined) ||
                                            this.state[item.id]
                                          ? "Concluído"
                                          : "Ausente"}
                                      </Button>
                                    </td>
                                    <td>
                                      {data.user.name}
                                    </td>
                                    <td>{data.user.uid}</td>
                                    <td>
                                      {data.user.userMetas.map((item) => (
                                        <div>
                                          {item.account_field.field}:{" "}
                                          {item.account_field_value.value}
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      <Button
                                        bsStyle="danger"
                                        onClick={() => {
                                          confirmAlert({
                                            title: "",
                                            message:
                                              "Tem certeza que deseja excluir " +
                                              data.user.name,
                                            buttons: [
                                              {
                                                label: "Sim",
                                                onClick: () => {
                                                  // Delete item
                                                  lms_client
                                                    .mutate({
                                                      mutation:
                                                        DELETE_SUBSCRIPTION,
                                                      variables: {
                                                        id: item.id,
                                                      },
                                                    })
                                                    .then((result) => {
                                                      window.location.reload();
                                                    });
                                                },
                                              },
                                              {
                                                label: "Não",
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        Excluir
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              }}
                            </Query>
                          ));
                        }}
                      </Query>
                      <Query
                        client={lms_client}
                        query={COUNT_SUBSCRIPTIONS}
                        variables={{
                          classroom_id: this.props.match.params.classroom_id,
                        }}
                        fetchPolicy="no-cache"
                      >
                        {({ loading, error, data }) => {
                          if (loading)
                            return (
                              <tr>
                                <td>Carregando...</td>
                              </tr>
                            );
                          if (error)
                            return (
                              <tr>
                                <td>Erro :(</td>
                              </tr>
                            );

                          var pagination_buttons = [];
                          var total_pages =
                            data.countSubscriptions.count / this.state.limit;
                          for (
                            var page_num = 1;
                            Math.ceil(total_pages) >= page_num;
                            page_num++
                          ) {
                            pagination_buttons.push(
                              <span>
                                <span
                                  page_num={page_num}
                                  className={
                                    "btn_page " +
                                    (this.state.page == page_num
                                      ? "btn_page_active"
                                      : "")
                                  }
                                  onClick={(e) => {
                                    this.setState({
                                      page: e.target.getAttribute("page_num"),
                                    });
                                  }}
                                >
                                  {page_num}
                                </span>{" "}
                              </span>
                            );
                          }
                          return (
                            <tr key={1}>
                              <td colSpan={6}>{pagination_buttons}</td>
                            </tr>
                          );
                        }}
                      </Query>
                      <tr>
                        <td colspan="4">Ações para todos os itens: </td>
                        <td>
                          <Button
                            bsStyle={
                              this.state.action_all === -1
                                ? "default"
                                : this.state.action_all
                                ? "info"
                                : "danger"
                            }
                            fill
                            pullRight
                            onClick={() => {
                              confirmAlert({
                                title: "",
                                message:
                                  "Tem certeza que deseja alterar o status de todas pessoas da lista?",
                                buttons: [
                                  {
                                    label: "Sim",
                                    onClick: () => {
                                      this.approvedToggleAll();
                                    },
                                  },
                                  {
                                    label: "Não",
                                  },
                                ],
                              });
                            }}
                          >
                            {this.state.action_all === -1
                              ? "Não iniciado"
                              : this.state.action_all
                              ? "Concluído"
                              : "Ausente"}
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <Button
                            bsStyle="info"
                            pullRight
                            fill
                            onClick={() => {
                              window.location.reload();
                            }}
                          >
                            Salvar
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Card
            title="Comprovante de Lista de Presença"
            content={
              <Query
                client={lms_client}
                variables={{
                  classroom_id: this.props.match.params.classroom_id,
                }}
                query={GET_CLASSROOM}
              >
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <tr>
                        <td>Carregando...</td>
                      </tr>
                    );
                  if (error)
                    return (
                      <tr>
                        <td>Erro :(</td>
                      </tr>
                    );

                  if (!data.classroom) {
                    return <div></div>;
                  }

                  const handleReplaceReceipt = () => {
                    const fileInput = document.getElementById("fileInput");

                    if (fileInput) {
                      fileInput.style.display =
                        fileInput.style.display === "none" ? "block" : "none";
                    }
                  };

                  const handleSubmit = (event) => {
                    const fileReceipt = document.getElementById("file_receipt");
                    const filePath = fileReceipt.value;
                    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

                    if (!allowedExtensions.exec(filePath)) {
                      alert(
                        "Tipo de arquivo inválido. Por favor, escolha um arquivo com uma das seguintes extensões: .jpg, .jpeg, .png ou .pdf"
                        );
                      event.preventDefault();
                      return;
                    }
                  };

                  return (
                    <form
                      action={Config.baseUrl + "/api/v1/lms/receipts-classroom"}
                      method="post"
                      encType="multipart/form-data"
                      onSubmit={handleSubmit}
                    >
                      <input
                        type="hidden"
                        name="returnUrl"
                        value={
                          "/#/Classroom-subscription/" +
                          this.props.match.params.classroom_id
                        }
                      />
                      <input
                        type="hidden"
                        name="classroom_id"
                        value={this.props.match.params.classroom_id}
                      />

                      {data.classroom.receipt && (
                        <div>
                          <a
                            href={
                              Config.baseUrl +
                              "/api/v1/lms/downloadReceipt?classroom_id=" +
                              this.props.match.params.classroom_id
                            }
                            download
                          >
                            <Button bsStyle="default" fill>
                              Baixar comprovante existente
                            </Button>
                          </a>
                          <p
                            style={{
                              margin: "0 10px",
                              textDecoration: "none",
                              cursor: "pointer",
                              display: "inline",
                              fontSize: "13px",
                              fontStyle: "italic",
                              color: "grey"
                            }}
                            onClick={handleReplaceReceipt}
                          >
                            substituir comprovante
                          </p>
                        </div>
                      )}

                      <div
                        id="fileInput"
                        style={{ display: "none", marginTop: "10px" }}
                      >
                        {data.classroom.receipt && (
                          <>
                            <div
                              style={{
                                borderTop: "1px solid #ccc",
                                paddingTop: "10px",
                                marginBottom: "10px",
                              }}
                            ></div>
                            <input
                              type="file"
                              name="file_receipt"
                              id="file_receipt"
                              accept=".jpg, .jpeg, .png, .pdf"
                              required
                              style={{ marginTop: "10px" }}
                            />
                            <Button
                              bsStyle="info"
                              fill
                              type="submit"
                              style={{ marginTop: "10px" }}
                            >
                              Enviar
                            </Button>
                          </>
                        )}
                      </div>

                      {!data.classroom.receipt ? (
                        <>
                          <div
                            style={{
                              borderTop: "1px solid #ccc",
                              paddingTop: "10px",
                              marginBottom: "10px",
                            }}
                          ></div>
                          <input
                            type="file"
                            name="file_receipt"
                            id="file_receipt"
                            accept=".jpg, .jpeg, .png, .pdf"
                            required
                            style={{ marginTop: "10px" }}
                          />
                          <Button
                            bsStyle="info"
                            fill
                            type="submit"
                            style={{ marginTop: "10px" }}
                          >
                            Enviar
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="clearfix" />
                    </form>
                  );
                }}
              </Query>
            }
          />
        </Grid>
      </div>
    );
  }
}

export default ClassroomList;
