import React, { Component } from "react";



class Auth extends Component {
  
  render() {
    return (
      <div className="content">
        
      </div>
    );
  }
}

export default Auth;
