import React, { useMemo } from 'react';
import './styles.css';

export default function Paginator(
  {
    currentPage = 0,
    pages = 0,
    onChangePage = null,
    siblingCount = 1
  }
) {

  let indents = [];

  const dots = <span className="paginator-dots">...</span>

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }
  const button = (number) => (
    <button
      key={`button-${number}`}
      className={`paginator-component-item ${+number === +currentPage && "paginator-component-item-selected"}`}
      onClick={() => onChangePage(number)}>
      {number}
    </button>
  )

  const paginationRange = useMemo(() => {
    const totalPageCount = pages;
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      range(1, totalPageCount).forEach(n => {
        indents.push(button(n));
      })

      return indents;
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount).map(n => button(n));

      indents = [...leftRange, dots, button(totalPageCount)];

      return indents;
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      ).map(n => button(n));

      indents = [button(firstPageIndex), dots, ...rightRange];

      return indents;
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex).map(n => button(n));

      indents = [button(firstPageIndex), dots, ...middleRange, dots, button(lastPageIndex)];

      return indents;
    }
  }, [pages, currentPage])

  return (
    <div>
      {pages > 1 && (
        <div className='paginator-component'>
          <div className='buttons-paginator'>
            {paginationRange}
          </div>
        </div>
      )}
    </div>
  );
};
