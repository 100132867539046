import React, {useEffect, useState} from "react";
import {Grid, Row, Col, Table} from "react-bootstrap";

import { NavLink } from "react-router-dom";
import Config from "../../config/config";
import Card from "../../components/Card/Card";
import gifCarregando from "../../assets/img/carregando.gif";
import gql from "graphql-tag";
import {ApolloClient} from "apollo-client";
import HttpLinkServices from "../../services/HttpLinkServices";
import {InMemoryCache} from "apollo-cache-inmemory";
import getCookie from "../../components/Cookie/Cookie";

const account_client = new ApolloClient({
    link: HttpLinkServices.account,
    cache: new InMemoryCache(),
});

const lms_client = new ApolloClient({
    link: HttpLinkServices.lms,
    cache: new InMemoryCache(),
});

const token_user = getCookie("token_user");

export default function FiltroRelatorioCurso({ match }) {

    const course_id = match.params.id;
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [relatorioExistente, setRelatorioExistente] = useState(false);
    const [dataUltimoRelatorio, setDataUltimoRelatorio] = useState(null);
    const [showRelatorioExistente, setShowRelatorioExistente] = useState(false);
    const [gerandoRelatorio, setGerandoRelatorio] = useState(false);

    function exibirElementosCarregando() {
        document.getElementById("download").style.display = "none";
        document.getElementById("carregando").style.display = "flex";
    }

    function exibirElementosDownload(){
        document.getElementById("carregando").style.display = "none";
        document.getElementById("download").removeAttribute("style");
    }

    function gerarRelatorio(url) {
        setGerandoRelatorio(true);

        const gerar = document.getElementById('btn-gerar-relatorio');
        gerar.disabled = true;
    
        exibirElementosCarregando();
        fetch(url);

        // Realiza uma requisição ao backend em intervalos de 60 segundos, para verificar se o relatório já foi gerado.
        const verificaSeOrelatorioFoiGerado = setInterval(() => {
            fetch(Config.baseUrl + "/api/v1/lms/verificaRelatorioExistenteProgramaAndamento?id=" + course_id)
                .then(response => {
                    if (response.status === 200) {
                        clearInterval(verificaSeOrelatorioFoiGerado);
                        exibirElementosDownload();
                    }
                })
        }, 15000);
    }

    function buscaTituloDoCurso() {
        lms_client.query({
            query: gql`
            query buscaTituloDoCurso($id: Int!){
            course(id: $id){
                id
                title
                description
              }
            }
            `,
            variables: {id: course_id}
        }).then((result) => {
            setTitle(result.data.course.title);
            setDescription(result.data.course.description);
          });
    }

    useEffect(() => {
        buscaTituloDoCurso();

        // Verifica se o relatório existe ao carregar a tela
        fetch(Config.baseUrl + "/api/v1/lms/verificaRelatorioExistenteProgramaAndamento?id=" + course_id)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Erro ao verificar relatório");
                }
            })
            .then(data => {
                if (data && data.name) {
                    const timestamp = data.name.split("_").pop();
                    setDataUltimoRelatorio(new Date(timestamp * 1000).toLocaleString().replace(',', ' às '));
                    setRelatorioExistente(true);
                } else {
                    setRelatorioExistente(false);
                }
                setShowRelatorioExistente(true);
            })
            .catch(error => {
                console.log("Erro:", error);
            });
    }, []);

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <div className="btn-top-container">
                                <NavLink
                                    to={"/Course/"}
                                    className="btn btn-info btn-fill"
                                    activeClassName=""
                                >
                                    Voltar
                                </NavLink>
                            </div>
                            <Card
                                title={"Gerar relatório de Programa em Andamento"}
                                course="Gerar programa em andamento do curso"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <div> 
                                        <hr/>
                                        <Col md={12} lg={12} sm={12} style={{'margin-left': '15px'}}>
                                            <Row>
                                                <span style={{'font-weight': '700'}}>Curso:</span> {course_id}
                                            </Row>
                                            <Row>
                                            <span style={{'font-weight': '700'}}>Título:</span> {title}
                                            </Row>
                                            {description &&
                                                <Row>
                                                    <span style={{'font-weight': '700'}}>Descrição:</span> {description}
                                                </Row>
                                            }
                                        </Col>
                                        <Table striped hover>
                                            <thead>
                                                <tr>
                                                <th colSpan={9}>
                                                    <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        var url = `${Config.baseUrl}/api/v1/lms/programa_andamento?course_id=${course_id}`;
                                                        gerarRelatorio(url);
                                                    }}
                                                    >
                                                    <div
                                                        style={{
                                                        display: "flex",
                                                        alignItems: "end",
                                                        justifyContent: "center",
                                                        }}
                                                    >
                                                        <div
                                                        style={{ flex: 1, marginRight: "10px", display: showRelatorioExistente ? "block" : "none" }}
                                                        id="relatorio-existente"
                                                        >
                                                        <span>Último relatório gerado em: {dataUltimoRelatorio && dataUltimoRelatorio}</span>
                                                        <a
                                                            className="btn btn-success btn-fill"
                                                            style={{ width: "100%" }}
                                                            href={`${Config.baseUrl}/api/v1/lms/verificaRelatorioExistenteProgramaAndamento?id=${course_id}&download=true`}
                                                            target="_self"
                                                            rel="noreferrer noopener"
                                                            id="btn-baixar-relatorio"
                                                            disabled={gerandoRelatorio}
                                                        >
                                                            Baixar relatório existente
                                                        </a>
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                        <button
                                                            className="btn btn-info btn-fill"
                                                            type={"submit"}
                                                            style={{ width: "100%" }}
                                                            id="btn-gerar-relatorio"
                                                        >
                                                            Gerar novo relatório
                                                        </button>
                                                        </div>
                                                    </div>
                                                    </form>
                                                </th>
                                                </tr>
                                            </thead>
                                        </Table>
                                    </div>
                                }
                                legend = {
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginBottom: "20px",
                                        marginTop: "30px"
                                    }}>
                                        <div style={{display: "none", flexDirection: "column", alignItems: "center", justifyContent: "center"}} id="carregando" >
                                            <p style={{ marginBottom: 10, fontSize: 18, color: "#9A9A9A"}}>Geração do relatório em andamento.</p>
                                            <p style={{ marginBottom: 25, fontSize: 18, color: "#9A9A9A"}}>Em alguns minutos, o botão de download será exibido. Por favor, aguarde.</p>
                                            <img src={gifCarregando} height={75}/> 
                                        </div>
                                        <div id="download" style={{display: "none"}}
                                                className="dashboard-sections mt-16">
                                            <label><b>Relatório gerado com sucesso!</b></label>
                                            <a className="btn btn-success btn-fill"
                                                style={{display: "block", marginTop: "10px"}}
                                                href={`${Config.baseUrl}/api/v1/lms/verificaRelatorioExistenteProgramaAndamento?id=${course_id}&download=true`}
                                                rel='noreferrer noopener'>
                                                <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:"8px"}}>
                                                    <path d="M6.1875 0.5H8.47917C8.86016 0.5 9.16667 0.834375 9.16667 1.25V6.5H11.6789C12.1888 6.5 12.4438 7.17188 12.0828 7.56563L7.72578 12.3219C7.51094 12.5562 7.15859 12.5562 6.94375 12.3219L2.58099 7.56563C2.22005 7.17188 2.475 6.5 2.9849 6.5H5.5V1.25C5.5 0.834375 5.80651 0.5 6.1875 0.5ZM14.6667 12.25V15.75C14.6667 16.1656 14.3602 16.5 13.9792 16.5H0.6875C0.30651 16.5 0 16.1656 0 15.75V12.25C0 11.8344 0.30651 11.5 0.6875 11.5H4.88984L6.29349 13.0312C6.86927 13.6594 7.7974 13.6594 8.37318 13.0312L9.77682 11.5H13.9792C14.3602 11.5 14.6667 11.8344 14.6667 12.25ZM11.1146 15C11.1146 14.6562 10.8568 14.375 10.5417 14.375C10.2266 14.375 9.96875 14.6562 9.96875 15C9.96875 15.3438 10.2266 15.625 10.5417 15.625C10.8568 15.625 11.1146 15.3438 11.1146 15ZM12.9479 15C12.9479 14.6562 12.6901 14.375 12.375 14.375C12.0599 14.375 11.8021 14.6562 11.8021 15C11.8021 15.3438 12.0599 15.625 12.375 15.625C12.6901 15.625 12.9479 15.3438 12.9479 15Z" fill="white"/>
                                                </svg>
                                                Baixar relatório
                                            </a>
                                        </div>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
}
