import ApiServices from "./ApiServices";
import gql from "graphql-tag";

export default class Corretor extends ApiServices {
  constructor(credential = 'include') {
    super('/api/v1/corretor/', credential);
  }

  async createOrUpdateRegistration(variables) {
    return await this.apolloClass.mutate({
      mutation: gql`
      mutation updateCadastro($id: Int!, $name: String!, $email: String!, $cpf: String!, $telefone: String!, $papel: String!, $susep: String!, $grupo: Int!) {
        createOrUpdateCadastro(id: $id, input: {name: $name, email: $email, cpf: $cpf, susep: $susep, telefone: $telefone, papel: $papel, grupo: $grupo}) {
          id
          }
        }
      `,
      variables
    })
  }
}
