export const RedirectURL = (url) => {
  const splitURL = url.split('.')[1];
  
  switch(splitURL) {
    case undefined:
      return 'localhost:3000';
    case 'testing':
      return 'https://portoeduc.testing.sambalms.com.br';
    case 'staging':
      return 'https://portoeduc.staging.sambalms.com.br';
    default:
      return 'https://www.portoseguroeducacao.com.br';
  };
};
