import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import gql from "graphql-tag";
import Button from "../../components/CustomButton/CustomButton.jsx";
import { AlertInfo } from "../../components/Alert/AlertInfo.jsx";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { Query } from "react-apollo";

import { Chat } from "../../components/Chat/Chat.jsx";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import HttpLinkServices from "../../services/HttpLinkServices";

const chat_client = new ApolloClient({
  link: HttpLinkServices.chat,
  cache: new InMemoryCache(),
});

const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const analytics_client = new ApolloClient({
  link: HttpLinkServices.analytics,
  cache: new InMemoryCache(),
});

const GET_USERS = gql`
  query getusers($chat_id: Int!, $status: String!){
    getUsersByChat(chat_id:$chat_id, status: $status) {
      ChatHasUser{
        id
        user_id
        name
        mail
        uid
        profile_json
      }
    }
  }
`;

const COUNT_USERS = gql`
  query countusers($chat_id: Int!, $status: String!){
      countUsersByChat(chat_id:$chat_id, status: $status) {
        count
      }
    }
`;

const COUNT_USERS_PROFILES = gql`
  query countusers($chat_id: Int!, $status: String!)  {
    countUsersProfilesByChat(chat_id:$chat_id, status:$status, fields:["Perfil", "Comercial", "Regional", "Sucursal", "Diretoria"]) {
    	AccountFieldCount{
        field
        values{
            account_field_value
            count
        }
      }
    }
  }
`;

const GET_CHAT = gql`
  query getChat($chat_id: Int!){
    chat(id:$chat_id){
      title,
      description,
      active,
      date_publish_format,
      date_unpublish_format,
      liveid,
      moderation,
    }
  }
`;

const APROVED_TOGGLE = gql`
  mutation AddTodo($id: Int!, $approved: Int!){
    approvedToggle(id:$id,approved:$approved){
      id
    }
  }
`;

const IS_HIDDEN_TOGGLE = gql`
  mutation AddTodo($id: Int!, $is_hidden: Int!){
    isHiddenToggle(id:$id,is_hidden:$is_hidden){
      id
    }
  }
`;

const IS_QUESTION_TOGGLE = gql`
  mutation AddTodo($id: Int!, $is_question: Int!){
    isQuestionToggle(id:$id,is_question:$is_question){
      id
    }
  }
`;

const GET_CHAT_MSG = gql`
  query getChatMessages($chat_id: Int!) {
    chat_message_collections(chat_id:$chat_id){
      messages{
        id,
        message,
        user_name,
        user_id,
        approved,
        is_hidden,
        is_question,
        response_from{
          id,
          user_id,
          user_name,
          message
        }
      }
    }
  }
`;

const GET_CHAT_MSG_FILTER = gql`
  query getChatMessages($chat_id: Int!, $only_question: Int!, $msg_hidden: Int!) {
    chat_message_collections_filter(chat_id:$chat_id, only_question: $only_question, msg_hidden: $msg_hidden){
      messages{
        id,
        message,
        user_name,
        user_id,
        approved,
        is_hidden,
        is_question,
        response_from{
          id,
          user_id,
          user_name,
          message
        }
      }
    }
  }
`;



const SAVE_CHAT_MSG = gql`
  mutation AddTodo($user_id: Int!, $user_name: String!, $chat_id: Int!, $message: String!, $approved: Int!, $response_from_id: Int!, $response_to_user_id: Int!){
    createChatMessage(input:{user_id:$user_id,user_name:$user_name,chat_id:$chat_id,message:$message,approved:$approved,response_from_id:$response_from_id,response_to_user_id:$response_to_user_id}){
      id
    }
  }
`;



class Moderate extends Component {

  first_print_msgs = true;
  state = {
    users_count: 0,
    users_count_profiles: [],
    users_count_profiles_online: [],
    users_online_count: 0,
    replyToUser: "",
    msgs: <tr><td>Carregando...</td></tr>,
    didFetchUser: false,
  };
  loggedUsersInterval;

  componentDidMount() {
    this.getLoggedUser();
    this.getChatHasUsers();
    this.loggedUsersInterval = setInterval(this.getChatHasUsers, 60000);
    this.print_msgs_interval(this.props.match.params.id);
  }

  componentWillUnmount() {
    clearInterval(this.loggedUsersInterval)
  }

  render() {

    const { didFetchUser } = this.state;


    if(!didFetchUser) return <div>Carregando...</div>

    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={7}>
              <div className="btn-top-container">
                <a
                    href='javascript:history.go(-1)'
                    className="btn btn-info btn-fill"
                  >
                  VOLTAR
                </a>
              </div>
            <Query
              client={chat_client}
              query={GET_CHAT}
              variables={{chat_id:this.props.match.params.id}}
              >
              {({ loading, error, data }) => {
                if (loading) return <div>Carregando...</div>;
                if (error) return <div>Erro :(</div>;
                  this.chat_edit = data.chat;
                  return(
                      <div className="embed-responsive embed-responsive-16by9 card shadow-sm">
                          <iframe title="embed_iframe" className="embed-responsive-item" allowFullScreen webkitallowfullscreen="true" mozallowfullscreen="true" src={"https://fast.player.liquidplatform.com/pApiv2/embed/a48377e3ea6d8603fcafcb42130e3369/live/"+this.chat_edit.liveid} scrolling="no" frameBorder="0" allow="geolocation; microphone; camera; encrypted-media; midi"></iframe>
                      </div>
                      );
                }}
              </Query>
            </Col>
            <Col md={5}>
            <Chat chat_id={this.props.match.params.id} />
            </Col>
          </Row>
        </Grid>

        <Grid fluid>
          <Row>
            <Col md={12}>
            <Card
                title="Geral"
                category=""
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="content">
                    <h2>Online agora</h2>
                    <div>
                      <strong>Total: {this.state.users_online_count}</strong>
                    </div>
                    {this.state.users_count_profiles_online.map((account_field) => (
                      <div className="box">
                        <div><strong>{account_field.field}</strong></div>
                        {account_field.values.map((account_field_value) => (
                          <div>
                            {account_field_value.account_field_value}: {account_field_value.count}
                          </div>
                        ))}
                      </div>
                    ))}

                    <h2>Entraram em algum momento</h2>
                    <div>
                      <strong>Total: {this.state.users_count}</strong>
                    </div>
                    {this.state.users_count_profiles.map((account_field) => (
                      <div className="box">
                        <div><strong>{account_field.field}</strong></div>
                        {account_field.values.map((account_field_value) => (
                          <div>
                            {account_field_value.account_field_value}: {account_field_value.count}
                          </div>
                        ))}
                      </div>
                    ))}

                  </div>
                } />
            </Col>
              
            </Row>
      </Grid>
        <Grid fluid>
          <Row>
            <Col md={12}>

              <Card
                title="Mensagens"
                category="Moderar mensagens"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <div>

                    {this.state.replyToUser}
                  
                    <Tabs>
                          <TabList>
                            <Tab>Mensagens</Tab>
                            <Tab>Perguntas</Tab>
                            <Tab>Ocultas</Tab>
                            <Tab>Pessoas Online</Tab>
                            <Tab>Todas Pessoas</Tab>
                          </TabList>
                          <TabPanel>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th>Usuário</th>
                                  <th>Mensagem</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>

                                {this.state["msgs_all"]}

                              </tbody>
                            </Table>
                          </TabPanel>
                          <TabPanel>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th>Usuário</th>
                                  <th>Mensagem</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>

                                {this.state["msgs_is_question"]}

                              </tbody>
                            </Table>

                          </TabPanel>
                          <TabPanel>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th>Usuário</th>
                                  <th>Mensagem</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>

                                {this.state["msgs_is_hidden"]}

                              </tbody>
                            </Table>

                    </TabPanel>
                    <TabPanel>
                      <Table striped hover>
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Matrícula/CPF</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.users_online &&
                                this.state.users_online.map((item) => (
                                      <tr row={item.id}>
                                        <td>{item.name}</td>
                                        <td>{item.mail}</td>
                                        <td>{item.uid}</td>
                                      </tr>
                                    ))
                              }
                              </tbody>
                          </Table>
                    </TabPanel>
                    <TabPanel>
                      <Table striped hover>
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Matrícula/CPF</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.users &&
                                this.state.users.map((item) => (
                                      <tr row={item.id}>
                                        <td>{item.name}</td>
                                        <td>{item.mail}</td>
                                        <td>{item.uid}</td>
                                      </tr>
                                    ))
                              }
                              
                              </tbody>
                          </Table>
                    </TabPanel>
                    </Tabs>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }


  getChatHasUsers = async () => {
    try {
      // Pega a quantidade de usuários que entraram em algum momento nessa live "all"
      const users_count = (await analytics_client.query({
        variables: {chat_id: parseInt(this.props.match.params.id), status:"all"},
        query: COUNT_USERS,
        fetchPolicy: 'no-cache'
      })).data.countUsersByChat.count;

      // Pega a quantidade de pessoas que entraram em todos os perfis
      const users_count_profiles = (await analytics_client.query({
        variables: {chat_id: parseInt(this.props.match.params.id), status:"all"},
        query: COUNT_USERS_PROFILES,
        fetchPolicy: 'no-cache'
      })).data.countUsersProfilesByChat.AccountFieldCount;

      // Pega a quantidade de pessoas ONLINE em todos os perfis
      const users_count_profiles_online = (await analytics_client.query({
        variables: {chat_id: parseInt(this.props.match.params.id), status:"active"},
        query: COUNT_USERS_PROFILES,
        fetchPolicy: 'no-cache'
      })).data.countUsersProfilesByChat.AccountFieldCount;

      // Pega a quantidade de usuários online nesse momento "active"
      const users_online_count = (await analytics_client.query({
        variables: {chat_id: parseInt(this.props.match.params.id), status:"active"},
        query: COUNT_USERS,
        fetchPolicy: 'no-cache'
      })).data.countUsersByChat.count;

      // Pega os usuários que entraram em algum momento nessa live "all"
      const users = (await analytics_client.query({
        variables: {chat_id: parseInt(this.props.match.params.id), status:"all"},
        query: GET_USERS,
        fetchPolicy: 'no-cache'
      })).data.getUsersByChat.ChatHasUser;
  
      // Pega os usuários online nesse momento "active"
      const users_online = (await analytics_client.query({
        variables: {chat_id: parseInt(this.props.match.params.id), status:"active"},
        query: GET_USERS,
        fetchPolicy: 'no-cache'
      })).data.getUsersByChat.ChatHasUser;

      // Seta os usuários e usuários online no State
      this.setState({users_online_count, users_count, users, users_online, users_count_profiles, users_count_profiles_online });
    } catch(err) {
      console.error(err)
    }
  }

  getLoggedUser = async () => {
    const result = await account_client.query({
      query: gql`{
        get_authenticated_user{
          id,
          first_and_last_name
        }
      }
      `
    })
    const user_auth = result.data.get_authenticated_user;
    this.setState({didFetchUser: true, user_auth })
  }

  showReplyToUser($item) {
    this.setState(state => ({
      ...state,
      replyToUser: 
                <form 
                  className="form_item"
                  onSubmit={e => {
                    e.preventDefault();
                    chat_client.mutate({
                      mutation: SAVE_CHAT_MSG,
                      variables: {
                        user_id:this.state.user_auth.id, 
                        user_name:this.state.user_auth.first_and_last_name,
                        chat_id:parseInt(this.props.match.params.id),
                        message:e.target.elements.msg.value,
                        approved:(e.target.elements.approved.value === 1 ? 1 : 0),
                        response_from_id: $item.id,
                        response_to_user_id: $item.user_id
                      }
                    });
                    this.setState(state => ({
                      ...state,
                      replyToUser: <AlertInfo alertInfo="Mensagem respondida com sucesso!" />
                    }));
                    return false;
                  }}
                  >
                  <Grid fluid>
                    <Row>
                      <Col md={12}>
                      <input type="hidden" defaultValue={$item.id} name="replyid" />
                      
                      <div className="form-group">
                        <label className="control-label">Pergunta:</label>
                        <div>{$item.message}</div>
                      </div>
                      <div className="form-group">
                        <label className="control-label">Resposta:</label>
                        <input className="form-control" type="text" name="msg" defaultValue={"@"+$item.user_name+", "} />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Quem pode ver essa resposta?</label>
                        <select className="form-control" name="approved">
                          <option value={0}>Responder somente para {$item.user_name}</option>
                          <option value={1}>Responder para todos</option>
                        </select>
                      </div>
                      <Button bsStyle="info" fill type="submit">
                        Responder
                      </Button>
                      </Col>
                    </Row>
                    </Grid>
                  </form>
    }));
  }


  //print_msgs($chat_id, $state_name, $only_questions = false, msg_hidden = 0) {
  print_msgs($state_name, $chat_id, $only_questions, $msg_hidden) {

    var $variables = { chat_id: $chat_id, msg_hidden:0, only_question:0};

    if($only_questions) {
      $variables = {chat_id: $chat_id, msg_hidden:0, only_question:1};
    }else if($msg_hidden == 1) {
      $variables = {chat_id: $chat_id, msg_hidden:1, only_question:0};
    } else if($msg_hidden == 2) {
      $variables = {chat_id: $chat_id, msg_hidden:2, only_question:0};
    }

      this.first_print_msgs = false;
      chat_client.query({
        query: GET_CHAT_MSG_FILTER,
        fetchPolicy: 'no-cache',
        variables: $variables
        })
        .then(

          result => {

            this.setState(state => ({
              ...state,
              [$state_name]: result.data.chat_message_collections_filter.messages.map((item) => (
                                    
                          <tr key={item.id}>
                            
                            <td>{item.response_from ? item.response_from.user_name : item.user_name /* Se a mensagem for uma resposta, exibe os dados da pergunta, que está chave "response_from" */}</td>
                            <td>
                              {item.response_from ? item.response_from.message : item.message /* Se a mensagem for uma resposta, exibe os dados da pergunta, que está chave "response_from" */}
                              <br />
                              {item.response_from ? 
                                "R.: " + item.user_name  + ": " + item.message 
                                : <span></span>}
                            </td>
                            <td>
                            <Button bsStyle={(item.is_question && this.state["is_question_"+item.id] === undefined) || this.state["is_question_"+item.id] ? "info" : "default"} fill onClick={() => this.isQuestionToggle(item, $chat_id)}>
                              Definir como pergunta
                            </Button>
                            </td>
                            <td>
                            <Button bsStyle={(item.is_hidden && this.state["is_hidden_"+item.id] === undefined) || this.state["is_hidden_"+item.id] ? "info" : "default"} fill onClick={() => this.isHiddenToggle(item, $chat_id)}>
                              Ocultar
                            </Button>
                            </td>
                            <td>
                            <Button bsStyle={(item.approved && this.state["approved_"+item.id] === undefined) || this.state["approved_"+item.id] ? "info" : "default"} fill onClick={() => this.approvedToggle(item, $chat_id)}>
                              Aprovar
                            </Button>
                            </td>
                            <td>
                            {!item.response_from ? // Não exibe o botão "Responder" para uma resposta
                              <Button bsStyle="info" fill onClick={() => this.showReplyToUser(item)}>
                                Responder
                              </Button>
                            : <span></span> }
                            </td>
                          </tr>
              
                ))
              }));
            
          }
        );

  }

  refereshMsgs($chat_id) {
    this.print_msgs("msgs_all", $chat_id, 0, 2); 
    this.print_msgs("msgs_is_hidden", $chat_id, 0, 1); 
    this.print_msgs("msgs_is_question", $chat_id, 1, 0); 
  }

  print_msgs_interval($chat_id) {
    var that = this;
    setInterval(function() {
      that.refereshMsgs($chat_id);
    }, 10000);
  }

  approvedToggle(item, $chat_id) {
    // Só utiliza a var item.approved, quando o State não está definido, pois o item.approved é fixo
    var approved_var = (this.state["approved_"+item.id] !== undefined ? this.state["approved_"+item.id] : item.approved);
    var approved = (approved_var ? 0 : 1); //inverte para trocar o status
    chat_client.mutate({
      mutation: APROVED_TOGGLE,
      variables: {
        id:item.id, approved:approved
      }
    }).then(
      result => {
        this.refereshMsgs($chat_id);
      }
    );
    // Alterar o state
    this.setState(state => ({
      ...state,
      ["approved_"+item.id]: approved
    }));
  }

  isHiddenToggle(item, $chat_id) {
    // Só utiliza a var item.approved, quando o State não está definido, pois o item.approved é fixo
    var is_hidden_var = (this.state["is_hidden_"+item.id] !== undefined ? this.state["is_hidden_"+item.id] : item.is_hidden);
    var is_hidden = (is_hidden_var ? 0 : 1); //inverte para trocar o status
    chat_client.mutate({
      mutation: IS_HIDDEN_TOGGLE,
      variables: {
        id:item.id, is_hidden:is_hidden
      }
    }).then(
      result => {
        this.refereshMsgs($chat_id);
      }
    );
    // Alterar o state
    this.setState(state => ({
      ...state,
      ["is_hidden_"+item.id]: is_hidden
    }));
  }

  isQuestionToggle(item, $chat_id) {
    // Só utiliza a var item.approved, quando o State não está definido, pois o item.approved é fixo
    var is_question_var = (this.state["is_question_"+item.id] !== undefined ? this.state["is_question_"+item.id] : item.is_question);
    var is_question = (is_question_var ? 0 : 1); //inverte para trocar o status
    chat_client.mutate({
      mutation: IS_QUESTION_TOGGLE,
      variables: {
        id:item.id, is_question:is_question
      }
    }).then(
      result => {
        this.refereshMsgs($chat_id);
      }
    );
    // Alterar o state
    this.setState(state => ({
      ...state,
      ["is_question_"+item.id]: is_question
    }));
    
  }
}

export default Moderate;
