import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Paginator from "../../components/Paginator";
import swal from "sweetalert";
import "react-confirm-alert/src/react-confirm-alert.css";
import HttpLinkServices from "../../services/HttpLinkServices";
import Config from "../../config/config"; //

const dam_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

const lms_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl(param, param_1 = null, param_2 = null) {
  let url = "/#/" + param;
  if (param_1 !== null) {
    url += "/" + param_1;
  }
  if (param_2 !== null) {
    url += "/" + param_2;
  }
  return url.replace(/\/\//g, "/"); // Remover barras duplicadas
}

const GET_DAM = gql`
  query getDam(
    $module_name: String!
    $external_parent_id: Int!
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    assets_collections(
      module_name: $module_name
      external_parent_id: $external_parent_id
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      assets {
        id
        title
      }
    }
  }
`;

const DELETE_DAM = gql`
  mutation deleteDam($id: Int!) {
    deleteAsset(id: $id) {
      id
    }
  }
`;

const IDTRAILS_BY_COURSE = gql`
  query trailsByItem($item_id: Int, $type_content: String!) {
    trailsByItem(item_id: $item_id, type_content: $type_content) {
      trailitems{
        id
      }
      __typename
    }
  }
`;

const DamList = (props) => {
  const [search, setSearch] = useState("");
  const limit = 20;
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("u.id");
  const [order, setOrder] = useState("DESC");
  const [totalDams, setTotalDams] = useState(0);

  function countAssets() {
    dam_client
      .query({
        query: gql`
          query AssetsCollectionsCount(
            $search: String!
            $module_name: String!
            $external_parent_id: Int!
          ) {
            assets_collections_count(
              search: $search
              module_name: $module_name
              external_parent_id: $external_parent_id
            ) {
              count
            }
          }
        `,
        variables: {
          search: search,
          module_name: props.match.params.type,
          external_parent_id: props.match.params.external_parent_id
            ? parseInt(props.match.params.external_parent_id)
            : 0,
        },
      })
      .then((result) =>
        setTotalDams(result.data.assets_collections_count.count)
      );
  }

  useEffect(() => {
    countAssets();
  }, [search]);

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  const titles = {
    Media: "Conteúdo Digital",
    chatBanner: "Banner da Sala Virtual"
  };
  
  const title = titles[props.match.params.type] || props.match.params.type;  

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <a
                href="javascript:history.go(-1)"
                className="btn btn-info btn-fill"
              >
                VOLTAR
              </a>
              <NavLink
                to={
                  "/Dam-new/" +
                  props.match.params.type +
                  (props.match.params.external_parent_id
                    ? "/" + props.match.params.external_parent_id
                    : "")
                }
                className="btn btn-success btn-fill"
                activeClassName=""
              >
                Novo arquivo
              </NavLink>
            </div>
            <Card
              title={`Arquivo de mídia - ${title}`}
              category="Gerenciar arquivo de mídia"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Buscar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="u.id">ID</option>
                                <option value="u.title">Título</option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                              <select name="order" className="form-control">
                                <option value="DESC">Decrescente</option>
                                <option value="ASC" selected={order == "ASC"}>
                                  Crescente
                                </option>
                              </select>
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Ordenar
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Título</th>
                      <th style={{ textAlign: "right" }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Query
                      client={dam_client}
                      variables={{
                        module_name: props.match.params.type,
                        external_parent_id: props.match.params
                          .external_parent_id
                          ? parseInt(props.match.params.external_parent_id)
                          : 0,
                        search,
                        limit,
                        page,
                        order,
                        orderby,
                      }}
                      query={GET_DAM}
                      fetchPolicy="no-cache"
                    >
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <tr>
                              <td>Carregando...</td>
                            </tr>
                          );
                        if (error)
                          return (
                            <tr>
                              <td>Erro :(</td>
                            </tr>
                          );

                        if (
                          !data.assets_collections ||
                          data.assets_collections.assets.length === 0
                        ) {
                          return (
                            <tr>
                              <td>(Vazio)</td>
                            </tr>
                          );
                        }

                        return data.assets_collections.assets.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td className="buttons-list">
                              {props.match.params.type === "Banner" && (
                                <a title="Baixar relatório" href={Config.baseUrl +
                                  "/api/v1/analytics/report_banner?banner_id=" +
                                  item.id}>
                                  <i className="pe-7s-file"></i>
                                </a>  
                              )}
                              <a title="Alterar arquivo" href={getUrl("dam-edit-file", props.match.params.type, item.id)}>
                                <i className='pe-7s-refresh-cloud'></i>
                              </a>
                              <a title="Editar arquivo" href={getUrl('dam-edit',props.match.params.type, item.id)}>
                                <i className="pe-7s-note"></i>
                              </a>
                              <>
                              {props.match.url === "/Dam/Media" && (
                                <Query
                                  client={lms_client} // Utiliza o lms_client para a consulta
                                  variables={{
                                    item_id: item.id,
                                    type_content: "asset",
                                  }}
                                  query={IDTRAILS_BY_COURSE}
                                  fetchPolicy="no-cache"
                                >
                                  {({ loading, error, data }) => {
                                    if (loading)
                                      return (
                                        <a title="Trilhas Vinculadas">
                                            <i className="pe-7s-albums inactive-icon"></i>
                                          </a>
                                      );
                                    if (error)
                                      return (
                                        <a title="Trilhas Vinculadas">
                                            <i className="pe-7s-albums inactive-icon"></i>
                                          </a>
                                      );

                                    const trailItems =
                                      data &&
                                      data.trailsByItem &&
                                      data.trailsByItem.trailitems;

                                    if (
                                      !trailItems ||
                                      trailItems.length === 0
                                    ) {
                                      return (
                                        <a title="Trilhas Vinculadas">
                                            <i className="pe-7s-albums inactive-icon"></i>
                                        </a>
                                      );
                                    }

                                    return (
                                      <a title="Trilhas Vinculadas" href={getUrl(
                                        "dam-composition",
                                        "",
                                        item.id
                                      )}>
                                        <i className="pe-7s-albums"></i>
                                      </a>
                                    );
                                  }}
                                </Query>
                              )}
                              </>
                              <a title="Segmentar arquivo" href={getUrl('segmentation', props.match.params.type, item.id)}>
                                <i className="pe-7s-network"></i>
                              </a>
                              <a title="Excluir arquivo"
                                onClick={() => {
                                  swal({
                                    title: `Atenção!`,
                                    text: `Tem certeza que deseja excluir "${item.title}"?\n\nNão será possível recuperar o registro após a exclusão`,
                                    icon: "warning",
                                    buttons: ["Cancelar", "Excluir"],
                                    dangerMode: true,
                                    className: "custom-swal",
                                  }).then((confirm) => {
                                    if (confirm) {
                                      dam_client
                                        .mutate({
                                          mutation: DELETE_DAM,
                                          variables: {
                                            id: item.id,
                                          },
                                        })
                                        .then((response) => {
                                          if (response.data.errors) {
                                            swal(
                                              "Não foi possível excluir o item por existirem registros filhos",
                                              {
                                                icon: "error",
                                              }
                                            );
                                          } else {
                                            swal("Exclusão concluída com sucesso!", {
                                              icon: "success",
                                            }).then(() => {
                                              window.location.reload();
                                            });
                                          }
                                        })
                                        .catch(() => {
                                          swal("Não foi possível excluir o item por existirem registros filhos", {
                                            icon: "error",
                                          });
                                        });
                                    }
                                  });
                                }}
                              >
                                <i className="pe-7s-trash danger"></i>
                              </a>
                            </td>
                          </tr>
                        ));
                      }}
                    </Query>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalDams / limit)}
              onChangePage={(page) => setPage(page)}
              siblingCount={2}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default DamList;
