import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { Query } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';

import Select from 'react-select';
import Button from "../../components/CustomButton/CustomButton.jsx";
import HttpLinkServices from "../../services/HttpLinkServices";


const account_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const CREATE_PROFILE_ACCOUNT_MODULE = gql`
  mutation createItem($profile_id: Int!, $account_module_id: Int!){
    createProfileAccountModule(input:{profile_id:$profile_id, account_module_id: $account_module_id}) {
        profile{
          id
          title
        }
        account_module{
          module{
            id
          }
        }
      }
    }
`;

const DELETE_PROFILE_ACCOUNT_MODULE = gql`
mutation deleteItem($profile_id: Int!, $account_module_id: Int!){
    deleteProfileAccountModule(profile_id: $profile_id, account_module_id: $account_module_id) {
        profile{
          id
          title
        }
        account_module{
          module{
            id
            label
          }
        }
      }
    }
`;
const GET_PROFILE_ACCOUNT_MODULE = gql`
  query getItens($profile_id: Int!){
    get_profile_account_module(profile_id:$profile_id) {
      profile_account_module{
        account_module{
          id
          module{
            id
            label
          }
        }
      }
    }
  }
`;


const GET_ACCOUNT_MODULE = gql`
{
  get_account_module_collection{
    account_modules{
      id
      module {
        id
        label
      }
    }
  }
}
`;




class ProfileHasModule extends Component {

  controllerItens = [];
  urlBack;

  constructor() {
    super();
     this.state = {
       defaultOptions : [],
    }

  }

  
  onChange = (inputValue, action ) => {
    
    // Adicionar
    if(action.action === 'select-option') {
      account_client.mutate({
        mutation: CREATE_PROFILE_ACCOUNT_MODULE,
        variables: {
          account_module_id: action.option.value, profile_id: parseInt(this.props.match.params.id)
        }
      });
    } 
    // Remover
    else if(action.action === 'remove-value') {
      account_client.mutate({
        mutation: DELETE_PROFILE_ACCOUNT_MODULE,
        variables: {
          account_module_id: action.removedValue.value, profile_id: parseInt(this.props.match.params.id)
        }
      });
    }
 
  } 

  onInputChange = (inputValue, action ) => {

    /*
    account_client.mutate({
      mutation: CREATE_PROFILE_ACCOUNT_MODULE,
      variables: {
        account_module_id: inputValue, profile_id: this.props.match.params.id
      }
    });
    */
    
  }


  handleSubmit = (event) => {
    event.preventDefault();
    

  }

  render() {
    
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <a
                    href='javascript:history.go(-1)'
                    className="btn btn-info btn-fill"
                  >
                  VOLTAR
                </a>
              </div>

              <Card
                title={"Módulos do perfil"}
                category="Definir quais recursos esse perfil pode acessar"
                ctTableFullWidth
                ctTableResponsive
                content={

                  <form 
                  onSubmit={e => {
                    this.handleSubmit(e);
                    e.preventDefault();
                  }}
                  >
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>

                    <Query
                        client={account_client}
                        query={GET_ACCOUNT_MODULE}
                      >
                        {({ loading, error, data }) => {

                          if (loading) return <tr><td>Carregando...</td></tr>;
                          if (error) return <tr><td>Erro :(</td></tr>;

                                var select_options = [];
                                data.get_account_module_collection.account_modules.map((account_module) => {
                                  if(account_module.module.label != "") {
                                    var item = {value: account_module.id, label: account_module.module.label};
                                    select_options.push(item); 
                                  }
                                });

                                return (
                        
                                <Query
                                  client={account_client}
                                  variables={{profile_id:this.props.match.params.id}}
                                  query={GET_PROFILE_ACCOUNT_MODULE}
                                >
                                  {({ loading, error, data }) => {

                                    if (loading) return <tr><td>Carregando...</td></tr>;
                                    if (error) return <tr><td>Erro :(</td></tr>;

                                      var defaultValues = [];
                                      data.get_profile_account_module.profile_account_module.map((profile_account_module) => {
                                        var account_module = profile_account_module.account_module;
                                        if(account_module.module.label != "") {
                                          var item = {value: account_module.id, label: account_module.module.label};
                                          defaultValues.push(item); 
                                        }
                                      });
                                      return (
                                          <tr>
                                            <td>
                                              <Select
                                                    defaultValue={this.state.defaultValue && this.state.defaultValue ? this.state.defaultValue : defaultValues}
                                                    isMulti
                                                    isSearchable
                                                    name={"values"}
                                                    options={select_options}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    //inputValue={inputValue}
                                                    onChange={this.onChange}
                                                    //onInputChange={this.onInputChange}
                                                    isClearable={false}
                                                  />
                                            </td>
                                          </tr>
                                      );
                                  }}
                                </Query>
                                );
                              }}
                            </Query>
                          
                        <tr>
                          <td>
                          <Button bsStyle="info" pullRight fill onClick={() => {window.location.reload()}}>
                            Salvar
                          </Button>
                          </td>
                          <td></td>
                        </tr>
                    </tbody>
                  </Table>
                  

                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ProfileHasModule;
