import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";

import Button from "../../components/CustomButton/CustomButton.jsx";
import "react-confirm-alert/src/react-confirm-alert.css";
import HttpLinkServices from "../../services/HttpLinkServices";
import Paginator from "../../components/Paginator";
import Config from "../../config/config";

const trail_client = new ApolloClient({
  link: HttpLinkServices.lms,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}

const GET_TRAIL = gql`
  query getTrail(
    $search: String!
    $limit: Int!
    $page: Int!
    $order: String!
    $orderby: String!
  ) {
    trails_collections_dashboard(
      search: $search
      limit: $limit
      page: $page
      order: $order
      orderby: $orderby
    ) {
      trails {
        id
        title
      }
    }
  }
`;

const DELETE_TRAIL = gql`
  mutation deleteTrail($id: Int!) {
    deleteTrail(id: $id) {
      id
    }
  }
`;

export default function TrailList() {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [orderby, setOrderby] = useState("id");
  const [order, setOrder] = useState("DESC");
  const [totalUsers, setTotalUsers] = useState(0);

  function toggleOrder() {
    setOrder(order == "DESC" ? "ASC" : "DESC");
  }

  function handleSubmit(event) {
    event.preventDefault();

    setSearch(event.target.elements.search.value);
    setOrder(event.target.elements.order.value);
    setOrderby(event.target.elements.orderby.value);
    setPage(1);
  }

  function countTrail() {
    trail_client
      .query({
        query: gql`
          query countTrail($search: String!) {
            trail_count(search: $search) {
              count
            }
          }
        `,
        variables: {
          search: search,
        },
      })
      .then((result) => setTotalUsers(result.data.trail_count.count));
  }

  useEffect(() => {
    countTrail();
  }, [search]);

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <div className="btn-top-container">
              <NavLink
                to={"/Trail-new/"}
                className="btn btn-info btn-fill"
                activeClassName=""
              >
                Nova Trilha
              </NavLink>
              <a className="btn btn-success" href={Config.baseUrl + "/api/v1/lms/reportTrilhas"} rel='noreferrer noopener'>
                Relatório de Trilhas
              </a>
            </div>

            <Card
              title={"Trilhas"}
              trail="Gerenciar trilhas"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th colSpan={9}>
                        <form onSubmit={handleSubmit}>
                          <Row>
                            <Col md={6} lg={3}>
                              <input
                                type="text"
                                name="search"
                                className="form-control"
                              />
                            </Col>
                            <Col md={2}>
                              <Button bsStyle="info" fill type="submit">
                                Buscar
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} lg={1}>
                              <select name="orderby" className="form-control">
                                <option value="id">ID</option>
                                <option value="title">Título</option>
                              </select>
                            </Col>
                            <Col md={6} lg={2}>
                              <select name="order" className="form-control">
                                <option value="DESC">Decrescente</option>
                                <option value="ASC" selected={order == "ASC"}>
                                  Crescente
                                </option>
                              </select>
                            </Col>
                            <Col md={2}>
                              <Button
                                bsStyle="info"
                                fill
                                type="button"
                                onClick={toggleOrder}
                              >
                                Ordenar
                              </Button>
                            </Col>
                          </Row>
                        </form>
                      </th>
                    </tr>
                  </thead>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Título</th>
                      <th style={{ textAlign: "right" }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Query
                      client={trail_client}
                      query={GET_TRAIL}
                      variables={{
                        search,
                        limit,
                        page,
                        order,
                        orderby,
                      }}
                      fetchPolicy="no-cache"
                    >
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <tr>
                              <td>Carregando...</td>
                            </tr>
                          );
                        if (error)
                          return (
                            <tr>
                              <td>Erro :(</td>
                            </tr>
                          );

                        if (!data.trails_collections_dashboard) {
                          return (
                            <tr>
                              <td>(Vazio)</td>
                            </tr>
                          );
                        }

                        return data.trails_collections_dashboard.trails.map(
                          (item) => (
                            <tr key={item.id}>
                              <td className="tablelist-id">{item.id}</td>
                              <td className="tablelist-desc">{item.title}</td>
                              <td className="buttons-list">
                                  <a title="Gerar relatório" href={getUrl("Trilha-relatorio", item.id)}>
                                    <i className="pe-7s-file"></i>
                                  </a>
                                  <a title="Conteúdo da trilha" href={getUrl("Trail-has-course", item.id)}>
                                    <i className="pe-7s-albums"></i>
                                  </a>
                                  <a title="Editar trilha" href={getUrl("Trail-edit", item.id)}>
                                    <i className="pe-7s-note"></i>
                                  </a>
                                  <a title="Segmentar trilha" href={getUrl(
                                      "segmentation",
                                      "Trail",
                                      item.id
                                    )}>
                                    <i className="pe-7s-network"></i>
                                  </a>
                              </td>
                            </tr>
                          )
                        );
                      }}
                    </Query>
                  </tbody>
                </Table>
              }
            />
          </Col>
          <Col sm={12}>
            <Paginator
              currentPage={page}
              pages={Math.ceil(totalUsers / limit)}
              onChangePage={(page) => setPage(page)}
              siblingCount={2}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
