import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { Query } from "react-apollo";
import { RedirectURL } from "../../variables/RedirectURL";
import HttpLinkServices from "../../services/HttpLinkServices";
import Button from "../../components/CustomButton/CustomButton.jsx";
import Card from "../../components/Card/Card.jsx";
import Config from "../../config/config";
import gql from "graphql-tag";
import "react-confirm-alert/src/react-confirm-alert.css";
import { NavLink } from "react-router-dom";

const user_client = new ApolloClient({
  link: HttpLinkServices.account,
  cache: new InMemoryCache(),
});

const urlToPath = RedirectURL(window.location.host);

function getUrl($param, $param_1 = null, $param_2 = null) {
  var $url = "/#/" + $param;
  if ($param_1 != null) {
    $url += "/" + $param_1;
  }
  if ($param_2 != null) {
    $url += "/" + $param_2;
  }
  return $url;
}

const GET_AUTHENTICATED_USER = gql`
  {
    get_authenticated_user {
      id
      name
      profile {
        id
        is_admin
        is_superadmin
      }
    }
  }
`;

const GET_PROFILE = gql`
  query getProfile($profile_id: Int!) {
    profile(id: $profile_id) {
      title
      __typename
    }
  }
`;

const GET_USERS = gql`
  query usersByProfile($id: Int!) {
    get_users_by_profile(id: $id) {
      users {
        name
        id
        mail
        uid
      }
    }
  }
`;

class UserListProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      order: "DESC",
      orderby: "id",
      title: "Usuários no perfil",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { id: profileId } = this.props.match.params;
    try {
      const { data } = await user_client.query({
        query: GET_PROFILE,
        variables: { profile_id: parseInt(profileId) },
      });
      const title = data && data.profile && data.profile.title ? `Usuários ${data.profile.title}` : "Usuários no perfil";
      this.setState({ title });
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      search: event.target.elements.search.value,
      order: event.target.elements.order.value,
      orderby: event.target.elements.orderby.value,
    });
  }

  render() {
    const { search, order, orderby, title } = this.state;
    const { id: profileId } = this.props.match.params;

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                  to="javascript:void(0)"
                  onClick={() => window.history.back()}
                  className="btn btn-info btn-fill btn-top-table"
                  activeClassName=""
                >
                  VOLTAR
                </NavLink>
                <a
                  className="btn btn-success"
                  href={Config.baseUrl + `/api/v1/account/reportUsersProfile?profileId=${profileId}`}
                >
                  Exportar lista de usuários
                </a>
              </div>
              <Query
                client={user_client}
                query={GET_PROFILE}
                variables={{ profile_id: parseInt(profileId) }}
                fetchPolicy="cache-and-network"
              >
                {({ loading, error, data }) => {
                  if (loading) return <p>Carregando...</p>;
                  if (error) return <p>Erro :(</p>;

                  const profile = data && data.profile;
                  const title = data && data.profile && data.profile.title ? `Usuários ${data.profile.title}` : "Usuários no perfil";

                  return (
                    <Card
                      name={"Perfis Admin"}
                      user="Gerenciar usuários"
                      title={title}
                      ctTableFullWidth
                      ctTableResponsive
                      content={
                        <>
                          <Table striped hover>
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>UID</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <Query
                                client={user_client}
                                query={GET_USERS}
                                variables={{ id: parseInt(profileId) }}
                                fetchPolicy="no-cache"
                              >
                                {({ loading, error, data }) => {
                                  if (loading)
                                    return (
                                      <tr>
                                        <td>Carregando...</td>
                                      </tr>
                                    );
                                  if (error)
                                    return (
                                      <tr>
                                        <td>Erro :(</td>
                                      </tr>
                                    );

                                  if (!data.get_users_by_profile.users) {
                                    return (
                                      <tr>
                                        <td>(Vazio)</td>
                                      </tr>
                                    );
                                  }

                                  const users = data.get_users_by_profile.users;

                                  return users.map((user) => (
                                    <tr key={user.id}>
                                      <td>{user.id}</td>
                                      <td>{user.name}</td>
                                      <td>{user.mail}</td>
                                      <td>{user.uid}</td>
                                      <td>
                                        <a
                                          className="btn btn-info"
                                          href={getUrl("User-edit", user.id)}
                                        >
                                          Editar
                                        </a>
                                      </td>
                                    </tr>
                                  ));
                                }}
                              </Query>
                            </tbody>
                          </Table>
                        </>
                      }
                    />
                  );
                }}
              </Query>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserListProfile;