import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { NavLink } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import Button from "../../components/CustomButton/CustomButton.jsx";
import swal from "sweetalert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import HttpLinkServices from "../../services/HttpLinkServices"; //

const category_client = new ApolloClient({
  link: HttpLinkServices.dam,
  cache: new InMemoryCache(),
});

function getUrl($param, $param_1 = null,$param_2 = null) {
  var $url = "/#/"+$param;
  if($param_1 != null) {
    $url += "/"+$param_1;
  }
  if($param_2 != null) {
    $url += "/"+$param_2;
  }
  return $url;
}


const GET_CATEGORIES = gql`
query getCagegories($parent_id:Int!){
    category_collections(parent:$parent_id){
      categories{
        id,title
      }
    }
  }
`;



const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: Int!){
    deleteCategory(id:$id) {
      id
    }
  }
`;


class CategoryList extends Component {


  render() {
    
    return (

      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <div className="btn-top-container">
                <NavLink
                    to={'/Category'}
                    className="btn btn-info btn-fill"
                    activeClassName=""
                  >
                  VOLTAR
                </NavLink>
                <NavLink
                    to={'/SubCategory-new/'+this.props.match.params.parent_id}
                    className="btn btn-success btn-fill"
                    activeClassName=""
                  >
                  Nova Subcategoria
                </NavLink>
              </div>

              <Card
                title={"Subcategorias"}
                category="Gerenciar subcategorias"
                ctTableFullWidth
                ctTableResponsive
                content={
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Título</th>
                        <th style={{ textAlign: "right" }}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>

                        <Query
                          client={category_client}
                          query={GET_CATEGORIES}
                          variables={{parent_id:this.props.match.params.parent_id}}
                          fetchPolicy='no-cache'
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <tr><td>Carregando...</td></tr>;
                            if (error) return <tr><td>Erro :(</td></tr>;
                              
                              if(!data.category_collections) {
                                return (<tr><td>(Vazio)</td></tr>);
                              } 

                            return data.category_collections.categories.map((item) => (
                              
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.title}</td>
                                <td className="buttons-list">
                                  <a title="Editar categoria" href={getUrl('Category-edit',item.id)}>
                                    <i className="pe-7s-note"></i>
                                  </a>
                                  <a title="Conteúdos vinculados" href={getUrl('Category-composition',item.id)}>
                                    <i className="pe-7s-albums"></i>
                                  </a>
                                  <a title="Segmentar categoria" href={getUrl('segmentation', "Category",item.id)}>
                                    <i className="pe-7s-network"></i>
                                  </a>
                                  <a title="Excluir categoria" onClick={() => {
                                  swal({
                                    title: `Atenção!`,
                                    text: `Tem certeza que deseja excluir a categoria "${item.title}"?\n\nNão será possível recuperar o registro após a exclusão`,
                                    icon: "warning",
                                    buttons: ["Cancelar", "Excluir"],
                                    dangerMode: true,
                                    className: "custom-swal",
                                  }).then((confirm) => {
                                    if (confirm) {
                                      category_client.mutate({
                                        mutation: DELETE_CATEGORY,
                                        variables: {
                                          id: item.id
                                        }
                                      }).then(() => {
                                        swal("Exclusão concluída com sucesso!", {
                                          icon: "success",
                                        }).then(() => {
                                          window.location.reload();
                                        });
                                      });
                                    }
                                  });
                                }}>
                                    <i className="pe-7s-trash danger"></i>
                                  </a>
                                </td>
                              </tr>
                            ));
                          }}
                        </Query>
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default CategoryList;
